import { get, matches } from "@cp/utils/objectUtils";
import {
  vuetifyDataTableOptionsToApiOptions,
  apiOptionsToVuetifyDataTableOptions,
} from "@cp/store/mixins/Table.js";
import { findByObj } from "@cp/utils/arrayUtils";

export default {
  computed: {
    table() {
      return this.instance;
    },
    tableProps() {
      const items = get(this.$store.state, this.instance.p.s.stateKey);
      const loading = get(this.$store.state, this.instance.p.s.loading);
      const tableOptions = get(
        this.$store.state,
        this.instance.p.s.tableOptions
      );
      const options = apiOptionsToVuetifyDataTableOptions(tableOptions);
      return {
        options,
        headers: this.headers || [],
        loading,
        items,
        serverItemsLength: this.$store.getters[
          this.instance.p.g.serverItemsLength
        ],
        hideDefaultHeader: !loading && !items.length,
        hideDefaultFooter: !loading && !items.length,
        ...this.instance.tableProps,
      };
    },
    paginationProps() {
      const pageCount = this.$store.getters[this.instance.p.g.pageCount];
      return {
        value: this.$store.getters[this.instance.p.g.currentPage],
        length: pageCount,
        disabled: pageCount < 2,
        totalVisible: 14,
      };
    },
    paginationListeners() {
      const $vm = this;
      return {
        input(value) {
          $vm.$store.dispatch($vm.instance.p.a.changePage, value);
        },
      };
    },
    noData() {
      return this.$store.getters[this.instance.p.g.noData];
    },
    filterParams() {
      return get(this.$store.state, this.instance.p.s.filterParams);
    },

    dateRange() {
      return get(
        this.$store.state,
        this.instance.p.s.filterParams + ".date_range"
      );
    },
    dateRanges() {
      const drFilter = findByObj(this.instance.filterOptions, {
        key: "date_range",
      });
      if (!drFilter) return [];
      return get(drFilter, "props.items", []);
    },
    selectedDateRange() {
      return (
        this.dateRanges.find(x => x.value === this.dateRange) || {
          label: "None",
        }
      );
    },
    selectedDateRangeIndex: {
      get() {
        const found = this.dateRanges.findIndex(
          x => x.value === this.dateRange
        );
        return found !== -1 ? found : 0;
      },
      set(index) {
        const date_range = this.dateRanges[index].value;
        this.$store.dispatch(this.instance.p.a.updateFilter, { date_range });
      },
    },

    statCards() {
      const cards = get(this.$store.state, this.instance.p.s.statCards, []);
      return cards;
    },
  },
  methods: {
    updateTableOptions(options) {
      /* IF this is a filtered table, AND this udpate:options is from page-load
       * we don't need to do anything,
       * because filterAddon.onRouteEnter will do it for us faster
       */
      const newOptions = vuetifyDataTableOptionsToApiOptions(options);
      const tableOptions = get(
        this.$store.state,
        this.instance.p.s.tableOptions
      );
      // trying my darned best to prevent double-hit on page load.
      // the <v-data-dable> emits @update:options on render. GAH
      if (
        // tableHasFetched should only be false on page load, before any UI has hit
        !get(this.$store.state, this.instance.p.s.tableHasFetched) &&
        // testing if <v-data-table>@update:options(options) matches current tableOptions
        matches(newOptions, tableOptions)
      )
        return;
      // DO_V_DATA_TABLE_OPTIONS_MATCH?
      // console.log($vm.tableProps.options);
      // console.log(options);
      // console.log(matches($vm.tableProps.options, options));
      this.$store.dispatch(this.instance.p.a.setOption, newOptions);
    },
    changePage(value) {
      this.$store.dispatch(this.instance.p.a.changePage, value);
    },
  },
};
