<template>
  <div>
    <div class="pa-2 d-flex justify-end">
      <cp-filters-menu :module="table" class="search--center" hide-filters />
    </div>

    <div class="my-10 d-flex justify-center" v-if="showAllCaughtUp">
      <v-alert color="success" text class="mx-auto my-0">
        <v-icon color="success" xLarge class="mr-4">mdi-check-all</v-icon>
        You're all caught up!
      </v-alert>
    </div>

    <template v-else>
      <v-data-table
        v-bind="tableProps"
        v-on="tableListeners"
        class="clickable-rows"
        hide-default-footer
        @click:row="item => openModal({ id: item.id })"
      >
        <template #header.user_task_progress_status_id="{header}">
          <cp-table-header-subqueryable
            :header="header"
            :table="table"
            submodule-ref-key="currentTasksTaskProgressStatuses"
            no-search-box
          />
        </template>

        <template #header.on_behalf_of="{header}">
          <cp-table-header-subqueryable
            :header="header"
            :table="table"
            submodule-ref-key="currentTasksEmployees"
          />
        </template>

        <template #item.user_task_progress_status_id="{item}">
          <my-task-status-cell v-bind="{ item }" />
        </template>

        <template #item.on_behalf_of="{value}">
          <cp-profile-chip :profile="value" />
        </template>
      </v-data-table>

      <v-pagination
        class="mt-2 text-center"
        v-bind="paginationProps"
        v-on="paginationListeners"
      />
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import pluralize from "pluralize";

import { onCpEvent } from "@cp/mixins";
import {
  myCurrentTasksTable,
  progress_statuses,
  compliance_statuses,
} from "@/store/modules/tasks";
import { taskStatusForm } from "@/store/modules/taskStatusForm";
import { parse, toMDY } from "@cp/utils/dateUtils";
import { isEmpty, filterObject } from "@cp/utils/objectUtils";
import { capitalize } from "@cp/utils/stringUtils";

export default {
  mixins: [
    myCurrentTasksTable.mixin,
    taskStatusForm.modalMixin,
    onCpEvent(taskStatusForm.keys.modalClosedEvent, function({ success } = {}) {
      if (success) this.refreshTable();
    }),
  ],
  data() {
    return { progress_statuses, compliance_statuses };
  },
  computed: {
    ...mapState("auth", ["hasToken"]),
    title() {
      const service = this.$route.params.service;
      if (service) return `My ${capitalize(service)} Tasks`;
      return "My Tasks";
    },
    showAllCaughtUp() {
      return filterObject(this.tableFilterValues, x => x.length) && this.noData;
    },
  },
  methods: {
    toMDY,
    isEmpty,
    daysLate(due_by_date) {
      const now = parse();
      const due = parse(due_by_date);
      const daysLate = now.diff(due, "days");
      return daysLate > 0 ? `${pluralize("day", daysLate, 1)} late` : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.search-field {
  max-width: 350px;
  margin: 10px auto;
}

::v-deep .status-cell {
  &[data-status="1"] {
    box-shadow: inset 8px 0 0 -4px #008a09;
  }
  &[data-status="2"] {
    box-shadow: inset 8px 0 0 -4px rgba(0, 0, 0, 0.54);
  }
  &[data-status="3"] {
    box-shadow: inset 8px 0 0 -4px #fbaf57;
  }
  &[data-status="4"] {
    box-shadow: inset 8px 0 0 -4px #e40000;
  }
  &[data-status="5"] {
    box-shadow: inset 8px 0 0 -4px #8b5dcb;
  }
  &[data-status="6"] {
    box-shadow: inset 8px 0 0 -4px #999;
  }
}
</style>
