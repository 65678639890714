<template>
  <div>
    <cp-h1>
      Employee Detail
    </cp-h1>

    <card>
      <v-row class="summaryRow align-center">
        <v-col class="flex-grow-0">
          <div style="width: 100px">
            <cp-profile-avatar :profile="data" />
          </div>
        </v-col>
        <v-col>
          <h6 class="text-h6 font-weight-bold d-flex align-center">
            <div class="mr-5">
              {{ data.name }}
            </div>

            <v-chip v-if="data.status === 'Active'" color="success" small>
              Active
            </v-chip>
            <v-chip v-else color="grey" small>Archived</v-chip>
          </h6>
          <table>
            <tbody>
              <tr>
                <td>Position</td>
                <td colspan="2">{{ data.position }}</td>
              </tr>
              <tr>
                <td>Office</td>
                <td colspan="2">{{ data.location }}</td>
              </tr>
              <tr>
                <td>Hire Date</td>
                <td>{{ dates.start }}</td>
                <td>{{ dates.startFrom }}</td>
              </tr>
              <tr v-if="parent.archived_at">
                <td>Archived Date</td>
                <td>{{ dates.archived }}</td>
                <td>{{ dates.archivedFrom }}</td>
              </tr>
              <tr>
                <td>Tenure</td>
                <td colspan="2">{{ data.tenure }}</td>
              </tr>
            </tbody>
          </table>
        </v-col>
        <v-col>
          <v-menu
            max-width="300"
            v-if="
              data.direct_managers.length ||
                data.office_managers.length ||
                data.regional_managers.length
            "
          >
            <template #activator="{on}">
              <v-btn v-on="on" color="primary" text small>
                <v-icon left>mdi-email</v-icon>
                Contact a manager
              </v-btn>
            </template>

            <v-list dense>
              <template v-if="data.direct_managers.length">
                <v-subheader>
                  Direct
                  {{ pluralize("Manager", data.direct_managers.length) }}
                </v-subheader>
                <v-list-item
                  v-for="manager in data.direct_managers"
                  :key="`m-${manager.id}`"
                  :href="manager.cell_phone ? null : `mailto:${manager.email}`"
                  target="_blank"
                >
                  <v-list-item-content>
                    {{ manager.full_name }}
                  </v-list-item-content>
                  <v-list-item-icon v-if="manager.email">
                    <a :href="`mailto:${manager.email}`" target="_blank">
                      <v-icon>mdi-email</v-icon>
                    </a>
                  </v-list-item-icon>
                  <v-list-item-icon v-if="manager.cell_phone">
                    <a :href="`tel:${manager.cell_phone}`">
                      <v-icon>mdi-phone</v-icon>
                    </a>
                  </v-list-item-icon>
                </v-list-item>
              </template>

              <template v-if="data.office_managers.length">
                <v-subheader>
                  Office
                  {{ pluralize("Manager", data.office_managers.length) }}
                </v-subheader>
                <v-list-item
                  v-for="manager in data.office_managers"
                  :key="`p-${manager.id}`"
                  :href="manager.cell_phone ? null : `mailto:${manager.email}`"
                  target="_blank"
                >
                  <v-list-item-content>
                    {{ manager.full_name }}
                  </v-list-item-content>
                  <v-list-item-icon v-if="manager.email">
                    <a :href="`mailto:${manager.email}`" target="_blank">
                      <v-icon>mdi-email</v-icon>
                    </a>
                  </v-list-item-icon>
                  <v-list-item-icon v-if="manager.cell_phone">
                    <a :href="`tel:${manager.cell_phone}`">
                      <v-icon>mdi-phone</v-icon>
                    </a>
                  </v-list-item-icon>
                </v-list-item>
              </template>

              <template v-if="data.regional_managers.length">
                <v-subheader>
                  Regional
                  {{ pluralize("Manager", data.regional_managers.length) }}
                </v-subheader>
                <v-list-item
                  v-for="manager in data.regional_managers"
                  :key="`r-${manager.id}`"
                  :href="manager.cell_phone ? null : `mailto:${manager.email}`"
                  target="_blank"
                >
                  <v-list-item-content>
                    {{ manager.full_name }}
                  </v-list-item-content>
                  <v-list-item-icon v-if="manager.email">
                    <a :href="`mailto:${manager.email}`" target="_blank">
                      <v-icon>mdi-email</v-icon>
                    </a>
                  </v-list-item-icon>
                  <v-list-item-icon v-if="manager.cell_phone">
                    <a :href="`tel:${manager.cell_phone}`">
                      <v-icon>mdi-phone</v-icon>
                    </a>
                  </v-list-item-icon>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>

          <div class="text-subtitle ml-2">Contact employee:</div>

          <div v-if="parent.email" class="text-truncate">
            <v-btn
              :href="`mailto:${parent.email}`"
              class="text-truncate"
              color="primary"
              text
              small
            >
              <v-icon left>mdi-email</v-icon>
              <span class="text-truncate">{{ parent.email }}</span>
            </v-btn>
          </div>
          <div v-if="parent.cell" class="text-truncate">
            <v-btn :href="`tel:${parent.cell}`" color="primary" text small>
              <v-icon left>mdi-phone</v-icon>
              <span class="text-truncate">{{ parent.cell }}</span>
            </v-btn>
          </div>
        </v-col>
        <v-col>
          <div class="text-right">
            <ul class="pr-0">
              <li>
                <v-btn color="primary" text @click="openEditModal">
                  <v-icon left>mdi-account-edit</v-icon>
                  Update Information
                </v-btn>
              </li>
              <li>
                <v-btn color="primary" text>
                  <v-icon left>mdi-email</v-icon>
                  Send Welcome Email
                </v-btn>
              </li>
              <li v-if="clientHasService('introduce')">
                <v-btn color="primary" text @click="openGiveNoticeModal">
                  <v-icon left>mdi-alert</v-icon>
                  Give Notice
                </v-btn>
              </li>
            </ul>
          </div>
        </v-col>
      </v-row>
    </card>

    <employee-give-notice-form :item="parent" />
  </div>
</template>

<script>
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

import { toMDY } from "@cp/utils/dateUtils";
import { get } from "@cp/utils/objectUtils";

import widgetMixin from "@/widgets/mixin";
import {
  marigoldEmployee,
  giveNoticeForm,
  planAvailability,
} from "@/store/modules/employee";

export default {
  mixins: [widgetMixin],
  computed: {
    title() {
      return `Employee Detail - ${this.data.name}`;
    },
    dates() {
      const start = get(this.data, "start_date");
      const archived = get(this.parent, "archived_at");
      return {
        start: toMDY(start),
        startFrom: dayjs(start).fromNow(),
        archived: archived && toMDY(archived),
        archivedFrom: dayjs(archived).fromNow(),
      };
    },
  },
  methods: {
    openEditModal() {
      this.$store.dispatch(marigoldEmployee.p.a.modalOpen, {
        id: this.$route.params.id,
      });
    },
    openGiveNoticeModal() {
      // check for offboarding plans, if none, will display notice
      this.$store.dispatch(planAvailability.p.a.fetch, {
        id: this.$route.params.id,
      });
      this.$store.dispatch(giveNoticeForm.p.a.modalOpen, {
        id: this.$route.params.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item {
  .v-icon {
    color: $primary;
  }

  .v-list-item__content {
    font-weight: normal;
  }

  &:hover {
    text-decoration: none;
  }
}

.summaryRow table {
  white-space: nowrap;
  max-width: 100%;

  td:first-child,
  td:nth-child(3) {
    font-weight: 200;
    font-size: 0.85rem;
    color: $gray-4;
  }

  td:first-child {
    padding-right: 10px;
  }

  td:nth-child(2) {
    font-weight: 500;
  }

  td:nth-child(3) {
    font-weight: 200;
    font-size: 0.85rem;
    color: $gray-4;
    padding-left: 10px;
  }
}
</style>
