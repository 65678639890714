<template>
  <v-card>
    <cp-h1>
      Responses to question:
      <template #subtitle>
        {{ parent.body }}
      </template>

      <template #right>
        <cp-filters-menu :module="table" noun="Comment" />
      </template>
    </cp-h1>

    <template v-if="parent.question_type.slug === 'text-entry'">
      <v-data-table
        v-bind="tableProps"
        @update:options="updateTableOptions"
        hide-default-footer
      >
        <template #item.created_at="{value}">
          {{ toMDY(value) }}
        </template>

        <template #item.comment="{value}">
          <v-alert
            icon="mdi-message-outline"
            color="blue-grey"
            class="my-1"
            text
          >
            {{ value }}
          </v-alert>
        </template>

        <template #item.resident="{item}">
          <span v-if="item.first_name === 'Confidential'">Confidential</span>
          <router-link
            v-else
            :to="{ name: 'resident', params: { id: item.residents_id } }"
          >
            {{ item.first_name }} {{ item.last_name }}
          </router-link>
        </template>
      </v-data-table>

      <v-pagination
        v-if="!noData"
        class="mt-2 text-center"
        v-bind="paginationProps"
        @input="changePage"
      />
    </template>

    <div
      v-if="
        ['multiple-select', 'multiple-choice'].includes(
          parent.question_type.slug
        )
      "
      class="pa-4"
    >
      <div class="mc-responses">
        <div
          v-if="!total || total <= 0"
          class="font-weight-bold text-center mt-3"
        >
          No Responses
        </div>

        <template v-else>
          <h5 class="mc-response-header">
            Total Responses: <span class="font-weight-bold">{{ total }}</span>
          </h5>
          <div
            class="mc-response-wrapper"
            v-for="(response, index) in responses"
            :key="index"
          >
            <h5 class="mc-response-text">
              <span class="font-weight-bold mr-2">{{ response.label }}</span>
              {{ response.value }}
            </h5>
            <div
              class="mc-response-bar"
              :style="responseBarStyle(response.value)"
            ></div>
          </div>
        </template>
      </div>
    </div>
  </v-card>
</template>

<script>
import { get } from "@cp/utils/objectUtils";

import { widgetMixin, tableMixin } from "@/widgets/mixins";

export default {
  mixins: [widgetMixin, tableMixin],
  data() {
    return {
      headers: [
        { text: "Survey", value: "survey_name" },
        { text: "Resident", value: "resident" },
        {
          text: "Date",
          value: "created_at",
          cellClass: "text-no-wrap",
        },
        { text: "Comment", value: "comment" },
      ],
    };
  },
  computed: {
    responses() {
      return get(this.meta, ["summary_object", "multiple_select"], []);
    },

    total() {
      return this.responses
        .map(({ value = 0 } = {}) => value)
        .reduce((sum, val) => sum + val, 0);
    },

    max() {
      return this.responses[0].value;
    },
  },
  methods: {
    responseBarStyle(value) {
      let percentage = (value / this.max) * 100;
      let amount = 50 * (percentage / 100);
      let final = 240 - Math.round(amount);
      return {
        width: `${(value / this.max) * 100}%`,
        backgroundColor: `rgb(${final}, ${final}, ${final * 1.45})`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.mc-responses {
  text-align: left;
  margin-bottom: 12px;

  .mc-response-header {
    position: relative;
    left: 6px;
  }

  .mc-response-wrapper {
    position: relative;
    height: 28px;
    margin-top: 2px;
    margin-bottom: 2px;

    .mc-response-text {
      display: flex;
      align-items: center;
      position: relative;
      z-index: 2;
      padding: 0 6px;
      height: 28px;
    }

    .mc-response-bar {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      height: 100%;
    }
  }
}

.text-h6 {
  background: rgba($gray-3, 0.25);
  margin-bottom: 14px;
  padding: 4px 16px;
}

.commentsInMonth {
  padding: 0 16px;
}

::v-deep .v-alert--text {
  padding-top: 4px;
  padding-bottom: 0;
}

.commentQuestion {
  color: $gray-4;
  margin-bottom: 8px;
}

.commentDate {
  color: $gray-4;
  text-align: right;

  margin-top: 8px;
}
</style>
