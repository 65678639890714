<template>
  <div>
    <cp-h1>
      <docs-nav />

      <span class="mx-2 text-caption grey--text text--darken-1">/</span>

      <div class="d-flex align-center">
        <v-menu>
          <template #activator="{on, attrs}">
            <v-btn v-bind="attrs" v-on="on" color="white" small>
              Change Api
              <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-row>
              <v-col v-for="(ids, slug) in serviceIds" :key="slug">
                <v-list>
                  <v-list-item
                    v-for="(id, i) in ids"
                    :key="`${slug}-${i}`"
                    :to="{ name: 'api_docs', params: { id } }"
                  >
                    {{ snakeToTitleCase(id) }}
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
            <v-list> </v-list>
          </v-card>
        </v-menu>
      </div>
    </cp-h1>

    <calculator v-if="currentCalculator" :calculator="currentCalculator" />
  </div>
</template>

<script>
import { calculators, serviceIds } from "@/store/modules/api.js";
import { snakeToTitleCase } from "@cp/utils/stringUtils";

export default {
  name: "ApiToolPage",
  data() {
    return {
      serviceIds,
    };
  },
  computed: {
    currentCalculator() {
      if (!this.$route.params.id) return false;
      return calculators.find(x => x.keys.stateKey === this.$route.params.id);
    },
  },
  methods: { snakeToTitleCase },
  mounted() {
    if (!this.$route.params.id) {
      const id = calculators[0].keys.stateKey;
      this.$router.push({ name: "api_docs", params: { id } });
    }
  },
};
</script>

<style scoped>
::v-deep .v-btn {
  text-transform: none;
}
</style>
