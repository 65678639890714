var render = function render(){var _vm=this,_c=_vm._self._c;return _c('cp-toolbar-module-modal',_vm._g({attrs:{"modalConf":_vm.modalConf,"width":"680"},on:{"closed":_vm.reset,"opened":_vm.resetDropzoneOptions},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.editMode ? `Edit ${_vm.item.first_name} ${_vm.item.last_name}'s profile` : "Create new employee"))]},proxy:true}])},_vm.$listeners),[(_vm.showCreatingTimedOut)?_c('div',{staticClass:"pa-10 text-center"},[_c('v-icon',[_vm._v("mdi-timer-sand")]),_c('span',{staticClass:"ml-4"},[_vm._v(" It is taking longer than usual to create the employee. Please check back later. ")])],1):(_vm.showCreatingSpinner)?_c('div',{staticClass:"pa-10 text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":""}}),_c('span',{staticClass:"ml-4"},[_vm._v(" Creating employee. Please wait. ")])],1):_c('div',[(_vm.editMode)?_c('div',{staticClass:"d-flex align-center justify-center mb-4"},[_c('div',{staticClass:"profileImageArea"},[_c('cp-profile-avatar',{staticStyle:{"width":"150px"},attrs:{"profile":_vm.item}})],1)]):_c('div',{staticClass:"mx-auto mb-6",staticStyle:{"width":"150px"}},[_c('cp-profile-avatar',{attrs:{"profile":_vm.item}})],1),_c('v-row',{staticClass:"dense-vertical"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',_vm._b({attrs:{"outlined":"","dense":""},on:{"input":_vm.fields.first_name.input}},'v-text-field',_vm.fields.first_name.attrs,false))],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',_vm._b({attrs:{"outlined":"","dense":""},on:{"input":_vm.fields.last_name.input}},'v-text-field',_vm.fields.last_name.attrs,false))],1),_c('v-col',{attrs:{"cols":"6"}},[_c('cp-search-autocomplete',_vm._b({attrs:{"form":_vm.form,"searchModule":_vm.locationsSearch,"name":"location_id","initialValue":_vm.item.location_id
              ? {
                  value: _vm.item.location_id,
                  label: _vm.item.location,
                }
              : null,"outlined":"","dense":""},on:{"input":_vm.fields.location_id.input}},'cp-search-autocomplete',_vm.fields.location_id.attrs,false))],1),_c('v-col',{attrs:{"cols":"6"}},[_c('cp-search-autocomplete',_vm._b({attrs:{"form":_vm.form,"searchModule":_vm.positionsSearch,"name":"position_id","initialValue":_vm.item.position_id
              ? {
                  value: _vm.item.position_id,
                  label: _vm.item.position,
                }
              : null,"outlined":"","dense":""},on:{"input":_vm.fields.position_id.input}},'cp-search-autocomplete',_vm.fields.position_id.attrs,false))],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',_vm._b({attrs:{"outlined":"","dense":""},on:{"input":_vm.fields.employee_id.input}},'v-text-field',_vm.fields.employee_id.attrs,false))],1),_c('v-col',{attrs:{"cols":"6"}},[_c('date-picker',_vm._b({attrs:{"outlined":"","dense":""},on:{"input":_vm.fields.start_date.input}},'date-picker',_vm.fields.start_date.attrs,false))],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',_vm._b({attrs:{"outlined":"","dense":""},on:{"input":_vm.fields.cell_phone.input}},'v-text-field',_vm.fields.cell_phone.attrs,false))],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',_vm._b({attrs:{"outlined":"","dense":""},on:{"input":_vm.fields.email.input}},'v-text-field',_vm.fields.email.attrs,false))],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',_vm._b({attrs:{"outlined":"","dense":""},on:{"input":_vm.fields.locale.input}},'v-select',_vm.fields.locale.attrs,false))],1)],1),_c('cp-unhandled-errors',{attrs:{"formConf":_vm.formConf}}),_c('cp-form-modal-success-or-buttons',{attrs:{"modalConf":_vm.modalConf,"formConf":_vm.formConf,"buttons":['cancel', _vm.editMode ? 'update' : 'create'],"noun":"Employee","success-message":`Employee ${_vm.editMode ? 'updated' : 'created'}`},on:{"submit":_vm.submit}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }