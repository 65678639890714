import { get } from "@cp/utils/objectUtils";

export default {
  mounted() {
    if (this.$route) {
      this.$watch("loading", function() {
        const widget = this.$refs.widget || this.$refs.widgets[0];
        document.title = get(widget.$refs, "component.title");
      });
    }
  },
};
