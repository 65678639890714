<template>
  <div>
    <cp-h1 css-class="align-center">
      {{ title }}

      <template #right>
        <div
          class="d-flex justify-space-between align-center"
          style="width: 100%;"
        >
          <v-chip v-if="parent.status_id !== 1" color="grey" small>
            Archived
          </v-chip>

          <v-spacer />

          <!-- <v-menu
            max-width="300"
            v-if="managers.pm.length || managers.mm.length"
          >
            <template #activator="{on}">
              <v-btn v-on="on" color="primary" outlined>
                <v-icon left>mdi-email</v-icon>
                Contact
              </v-btn>
            </template>

            <v-list>
              <v-subheader v-if="managers.pm.length">
                {{ pluralize("Manager", managers.pm.length) }}
              </v-subheader>
              <v-list-item
                v-for="{ value, email, text } in managers.pm"
                :key="value"
                :href="`mailto:${email}`"
              >
                <v-list-item-icon><v-icon>mdi-email</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <div class="text-truncate">
                    {{ text }}
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-subheader v-if="managers.mm.length">
                Maintenance
                {{ pluralize("Manager", managers.mm.length) }}
              </v-subheader>
              <v-list-item
                v-for="{ value, email, text } in managers.mm"
                :key="value"
                :href="`mailto:${email}`"
              >
                <v-list-item-icon><v-icon>mdi-email</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <div class="text-truncate">
                    {{ text }}
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu> -->
        </div>
      </template>
    </cp-h1>

    <v-card>
      <v-card-text class="pa-0">
        <v-row class="align-stretch" no-gutters>
          <v-col class="flex-grow-1 pa-4">
            <table class="summaryTable">
              <tbody>
                <tr>
                  <td>Region</td>
                  <td colspan="2">{{ parent.region.name }}</td>
                </tr>
                <tr>
                  <td>Location</td>
                  <td colspan="2">{{ parent.city }}, {{ parent.state }}</td>
                </tr>
                <tr>
                  <td>{{ pluralize("Manager", managers.pm.length) }}</td>
                  <td colspan="2">
                    <span v-if="managers.pm.length">
                      {{ managers.pm.map(x => x.label).join(", ") }}
                    </span>
                    <span v-else>N/A</span>
                  </td>
                </tr>
                <tr v-if="managers.mm.length">
                  <td>
                    Maintenance
                    {{ pluralize("Manager", managers.mm.length) }}
                  </td>
                  <td colspan="2">
                    <span v-if="managers.mm.length">
                      {{ managers.mm.map(x => x.text).join(", ") }}
                    </span>
                    <span v-else>N/A</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-col>
          <v-col
            class="Stat text-center"
            v-for="stat in stats"
            :key="stat.id"
            :id="stat.id"
            :class="{ clickableStat: stat.modalProps || stat.to }"
            @click="stat.to && $router.push(stat.to)"
          >
            <div class="value">
              {{ nonEmpty(stat.value) ? stat.value : "N/A"
              }}<span v-if="stat.append">{{ stat.append }}</span>
            </div>
            <div class="label">
              <cp-explain v-if="stat.explainText">
                <template #activator="{on}">
                  <span v-on="on">
                    <v-icon :size="16">mdi-information-outline</v-icon>
                    <span>
                      {{ stat.label }}
                    </span>
                  </span>
                </template>
                {{ stat.explainText }}
              </cp-explain>
              <span v-else>
                <v-icon v-if="stat.icon">mdi-{{ stat.icon }}</v-icon>
                {{ stat.label }}
              </span>
            </div>
            <widget-modal
              v-if="stat.modalProps"
              v-bind="stat.modalProps"
              :activator="`#${stat.id}`"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import widgetMixin from "@/widgets/mixin";

export default {
  mixins: [widgetMixin],
  computed: {
    url() {
      return `properties/${this.$route.params.id}/resident_questions`;
    },
    stats() {
      const linkedIds = this.parent.linked_locations.map(x => x.id);
      const id = this.parent.id;
      const allIds = [id, ...linkedIds];
      const stats = [
        {
          id: "location-employees",
          label: this.pluralize("Employees", this.data.ingage.employees),
          icon: "account",
          value: this.data.ingage.employees,
          to: {
            name: "team",
            query: { locations: allIds },
          },
        },
      ];

      if (this.data.intouch.residents || this.parent.unit_count)
        stats.push(
          {
            id: "location-residents",
            label: "Residents",
            icon: "home-account",
            value: this.data.intouch.residents || "N/A",
            to: {
              name: "residents",
              query: { properties: allIds },
            },
          },
          {
            id: "location-units",
            label: "Units",
            icon: "home-city",
            value: this.data.unit_count || "0",
          }
        );

      return stats;
    },
    managers() {
      // sometimes michael sends null.. grrr
      return {
        pm: this.get(this.data, "managers", []) || [],
        mm: this.get(this.parent, "maintenance_managers", []) || [],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item {
  .v-icon {
    color: $primary;
  }

  .v-list-item__content {
    font-weight: normal;
  }

  &:hover {
    text-decoration: none;
  }
}
table.summaryTable {
  white-space: nowrap;
  max-width: 100%;

  td:first-child {
    text-align: right;
  }

  td:first-child,
  td:nth-child(3) {
    font-weight: 200;
    font-size: 0.85rem;
    color: $gray-4;
  }

  td:first-child {
    padding-right: 10px;
  }

  td:nth-child(2) {
    font-weight: 500;
  }

  td:nth-child(3) {
    font-weight: 200;
    font-size: 0.85rem;
    color: $gray-4;
    padding-left: 10px;
  }
}

.value {
  font-size: 2.5rem;
  line-height: 3.5rem;
  font-weight: lighter;
}
.label {
  white-space: nowrap;

  span {
    vertical-align: middle;
  }
}
.Stat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  color: rgba(0, 0, 0, 0.6);
  &.clickableStat {
    cursor: pointer;
    &:hover {
      background: rgba($primary, 0.2);
    }
  }
}
</style>
