<template>
  <div v-if="!loading && statCards.length">
    <div class="d-none d-md-flex Stats">
      <card
        v-for="(card, i) in statCards"
        :key="i"
        :title="card.title"
        :icon="card.icon"
        :iconColor="card.iconColor"
        class="StatsCard flex-grow-1"
        :set="(cardStats = card.stats || [])"
      >
        <div v-if="showPrimary" class="primaryBlock">
          <div class="primaryValue">
            {{ cardStats[0].value }}
          </div>
          <div class="primaryLabel">
            <v-icon
              v-if="cardStats[0].icon"
              small
              :color="cardStats[0].iconColor"
            >
              mdi-{{ cardStats[0].icon }}
            </v-icon>
            {{ cardStats[0].title }}
          </div>
        </div>
        <div v-if="cardStats.length > 1">
          <table>
            <tbody>
              <template
                v-for="({ title, value, click }, i) of showPrimary
                  ? cardStats.slice(1)
                  : cardStats"
              >
                <tr
                  :key="`${title}-${i}`"
                  @click="onStatClick(click)"
                  :class="{ clickable: click }"
                >
                  <td class="stat-label text-right">{{ title }}:</td>
                  <td class="text-right">{{ value }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </card>
    </div>
    <v-sheet
      class="responsiveStats px-1 py-1 d-md-none"
      :set="(card = statCards[responsiveStats])"
    >
      <v-menu>
        <template #activator="{on, attrs}">
          <v-btn v-bind="attrs" v-on="on" color="primary" text class="px-1">
            <v-icon v-if="card.icon">mdi-{{ card.icon }}</v-icon>
            <cp-icon
              v-else-if="card.cpIcon"
              :icon="card.cpIcon"
              color="currentColor"
            />
            <span class="d-none d-md-inline-block ml-2">{{ card.title }}</span>
            <v-icon right class="ml-1">mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-for="(card, i) in statCards"
            :key="i"
            @click="responsiveStats = i"
            class="stats-item"
          >
            <v-list-item-icon class="mr-2">
              <v-icon v-if="card.icon">mdi-{{ card.icon }}</v-icon>
              <cp-icon
                v-else-if="card.cpIcon"
                :icon="card.cpIcon"
                color="currentColor"
              />
            </v-list-item-icon>
            <v-list-item-content>
              {{ card.title }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <div class="responsiveStatsLiner">
        <Scrollbar
          class="responsiveStatsSideScroll"
          :settings="{ suppressScrollY: true }"
        >
          <span
            v-for="({ title, value }, i) in statCards[responsiveStats].stats"
            :key="`${title}-${i}`"
            class="px-2"
          >
            <span class="responsiveLabel mr-1">{{ title }}</span>
            <span class="responsiveValue">{{ value }}</span>
          </span>
        </Scrollbar>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import Scrollbar from "vue-custom-scrollbar";

import { get, exclude, isObject } from "@cp/utils/objectUtils";

export default {
  components: { Scrollbar },
  props: {
    table: { type: Object, required: true },
    statCards: { type: Array, default: () => [] },
  },
  data() {
    return {
      responsiveStats: 0,
    };
  },
  computed: {
    meta() {
      return get(this.$store.state, this.table.p.s.meta);
    },
    loading() {
      return get(this.$store.state, this.table.p.s.loading);
    },
    showPrimary() {
      return this.$vuetify.breakpoint.width > 1248;
    },
  },
  methods: {
    onStatClick(clickDef = {}) {
      if (!isObject(clickDef)) return;
      const { filter, to } = clickDef;
      if (filter) {
        const path = [...this.table.modulePath, this.table.k.updateFilter];
        this.$store.dispatch(path.join("/"), filter);
      } else if (to) this.$router.push(to);
    },
  },
};
</script>

<style lang="scss" scoped>
.Stats {
  gap: 12px;
}

.StatsCard {
  .primaryBlock {
    display: flex;
    flex-direction: column;
    min-height: 0;
    max-height: 100%;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .primaryValue {
    font-size: 2.5rem;
    line-height: 3.5rem;
    font-weight: lighter;
  }
  .primaryLabel {
    line-height: 1em;
    overflow: hidden;
    min-height: 0;
  }
  &::v-deep {
    .v-card__title {
      flex-wrap: nowrap;
      white-space: nowrap;
      .heading-6 {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .v-card__text {
      min-height: 100px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      @media (max-width: 1024px) {
        padding-left: 4px;
        padding-right: 4px;
      }
    }
  }

  table {
    margin-left: 10px;
    td:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
      @media (min-width: 1248px) {
        max-width: 250px;
      }
    }
  }

  .stat-label {
    white-space: nowrap;
    padding-right: 6px;

    @media (max-width: 1024px) {
      padding-right: 4px;
    }
  }
}
.responsiveStats {
  white-space: nowrap;
  display: flex;
  justify-content: stretch;
  align-items: center;

  .responsiveStatsLiner {
    flex-grow: 1;
    overflow: hidden;
  }

  .responsiveLabel {
    color: $gray-4;
    font-weight: lighter;
  }
}

.responsiveStatsSideScroll {
  position: relative;
  margin: 0;
  width: 100%;
  padding: 10px 3px;
}

.stats-item .v-list-item__icon {
  color: #6b6b70;
  svg {
    color: inherit;
  }
}

.clickable {
  cursor: pointer;
  color: $primary;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    background: rgba(0, 0, 0, 0.05);
  }
}
</style>
