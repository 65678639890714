<template>
  <div>
    <v-row class="dense-vertical">
      <v-col cols="6">
        <v-text-field
          v-bind="fields.first_name.attrs"
          @input="fields.first_name.input"
          outlined
          dense
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-bind="fields.last_name.attrs"
          @input="fields.last_name.input"
          outlined
          dense
        />
      </v-col>
      <v-col cols="6">
        <cp-search-autocomplete
          :form="form"
          :searchModule="locationsSearch"
          name="location_id"
          v-bind="fields.location_id.attrs"
          @input="fields.location_id.input"
          :initialValue="
            item.location_id
              ? {
                  value: item.location_id,
                  label: item.location,
                }
              : null
          "
          outlined
          dense
        />
      </v-col>
      <v-col cols="6">
        <cp-search-autocomplete
          :form="form"
          :searchModule="positionsSearch"
          name="position_id"
          v-bind="fields.position_id.attrs"
          @input="fields.position_id.input"
          :initialValue="
            item.position_id
              ? {
                  value: item.position_id,
                  label: item.position,
                }
              : null
          "
          outlined
          dense
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-bind="fields.employee_id.attrs"
          @input="fields.employee_id.input"
          outlined
          dense
        />
      </v-col>
      <v-col cols="6">
        <date-picker
          v-bind="fields.start_date.attrs"
          @input="fields.start_date.input"
          outlined
          dense
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-bind="fields.cell_phone.attrs"
          @input="fields.cell_phone.input"
          outlined
          dense
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-bind="fields.email.attrs"
          @input="fields.email.input"
          outlined
          dense
        />
      </v-col>
      <v-col cols="6">
        <v-select
          v-bind="fields.locale.attrs"
          @input="fields.locale.input"
          outlined
          dense
        />
      </v-col>
    </v-row>

    <cp-unhandled-errors :formConf="formConf" />

    <cp-form-modal-success-or-buttons
      v-if="!success"
      :formConf="formConf"
      :modalConf="{ close: () => $emit('close') }"
      :buttons="buttons"
      :buttonConf="{
        confirm: {
          action: 'submit',
          text: 'Confirm',
          icon: 'mdi-account-check-outline',
          props: { color: 'primary' },
        },
        cantComplete: {
          action: 'cantComplete',
          text: 'Can\'t Complete',
          icon: 'mdi-account-cancel',
          props: { color: 'white' },
        },
      }"
      @submit="submit"
      @cantComplete="$emit('update:cant-complete', true)"
      noun="Employee Data"
      success-message="Employee Data Confirmed!"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

import {
  marigoldEmployee,
  locationsSearch,
  positionsSearch,
} from "@/store/modules/employee";

export default {
  mixins: [marigoldEmployee.mixin],
  props: {
    cantComplete: { type: Boolean, default: false },
  },
  data() {
    return {
      locationsSearch,
      positionsSearch,
    };
  },
  computed: {
    ...mapState("employee", ["itemTouched"]),
    buttons() {
      const hasTouched = this.itemTouched.length;
      return ["cantComplete", hasTouched ? "update" : "confirm"];
    },
  },
  methods: {
    async submit() {
      const config = { method: "POST", id: this.item.id };
      const response = await this.itemSubmit(config);
      if (this.success) {
        this.$emit("success");
      }
    },
  },
};
</script>
