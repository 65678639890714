<template>
  <div class="expandable-table__wrapper">
    <cp-h1>
      Resident Satisfaction

      <template v-if="devMode" #subtitle>
        <dev-mode-widget-links v-bind="{ instance }" />
      </template>

      <template #right>
        <cp-filters-menu :module="table" noun="Report" />
      </template>
    </cp-h1>

    <div class="card-row">
      <div class="card-row-fields">
        <div class="card-row-fields__container">
          <table class="card-row-fields__table">
            <tbody>
              <tr>
                <td class="text-caption">Viewing By:</td>
                <td>
                  <v-menu>
                    <template #activator="{on}">
                      <v-btn color="primary" class="mr-2" v-on="on" text small>
                        <v-icon left>mdi-chevron-down</v-icon>
                        {{ selectedScope.label }}
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item-group
                        v-model="selectedScopeIndex"
                        color="primary"
                      >
                        <v-list-item v-for="s in scopes" :key="s.value">
                          {{ s.label }}
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
              <tr>
                <td class="pr-3 text-caption">
                  Date Range:
                </td>
                <td>
                  <v-menu>
                    <template #activator="{on}">
                      <v-btn v-on="on" color="primary" small text>
                        <v-icon left>mdi-chevron-down</v-icon>
                        {{ selectedDateRange.label }}
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item-group
                        v-model="selectedDateRangeIndex"
                        color="primary"
                      >
                        <v-list-item v-for="dr in dateRanges" :key="dr.value">
                          <v-list-item-content>{{
                            dr.label
                          }}</v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-row-cards">
        <stat-cards v-bind="{ statCards, table }" />
      </div>
    </div>

    <v-card class="mt-3">
      <expandable-table
        v-bind="tableProps"
        :instance="instance"
        :scope="scope"
        :scopeChangeLoading="scopeChangeLoading"
        :scopeHeaders="scopeHeaders"
        @update:options="updateTableOptions"
        item-key="m_id"
        hide-default-footer
      >
        <template #header.name>
          {{ selectedScope.label }}
        </template>

        <template #item.question_response_summary="{item}">
          <question-response-summary v-bind="{ item, table }" />
        </template>
      </expandable-table>

      <v-pagination
        v-if="!noData && !scopeChangeLoading"
        class="mt-2 text-center"
        v-bind="paginationProps"
        @input="changePage"
      />
    </v-card>
  </div>
</template>

<script>
import { snakeToSentenceCase } from "@cp/utils/stringUtils";

import { widgetMixin, tableMixin, multiScopeMixin } from "@/widgets/mixins";
import { coaxRoundPrct, score } from "@/lib/formats";

const questionScopesAndFilters = [
  "questions",
  "question_types",
  "topics",
  "factors",
  "indicators",
  "responses",
];

const scopeHeaders = {
  default: [
    { text: "", value: "name" },
    {
      text: "Satisfaction",
      value: "agreement_importance",
      width: 140,
      align: "center",
    },
    {
      text: "SBX",
      value: "sb_index.agreement_importance",
      width: 100,
      sortable: false,
      format: score,
      showIf: ({ scope }) => ["questions", "surveys"].includes(scope),
    },
    {
      text: "Likely to renew",
      value: "likely_to_renew_percentage",
      width: 150,
      align: "end",
      format: coaxRoundPrct,
    },
    {
      text: "Sent",
      value: "surveys_sent",
      width: 90,
      align: "end",
      hideIfScopeOrFilters: questionScopesAndFilters,
    },
    {
      text: "Started",
      value: "surveys_started",
      width: 100,
      align: "end",
      hideIfScopeOrFilters: questionScopesAndFilters,
    },
    {
      text: "Completed",
      value: "surveys_completed",
      width: 125,
      align: "end",
      hideIfScopeOrFilters: questionScopesAndFilters,
    },
    {
      text: "Response Rate",
      value: "response_rate",
      width: 150,
      align: "end",
      format: coaxRoundPrct,
      hideIfScopeOrFilters: questionScopesAndFilters,
    },
  ],
  surveys: [
    { text: "", value: "name" },
    {
      text: "SBX",
      value: "sb_index.agreement_importance",
      width: 100,
      sortable: false,
      format: score,
    },
    {
      text: "Satisfaction",
      value: "agreement_importance",
      width: 140,
      align: "center",
    },
    {
      text: "Likely to renew",
      value: "likely_to_renew_percentage",
      width: 150,
      align: "end",
      format: coaxRoundPrct,
    },
    {
      text: "Sent",
      value: "surveys_sent",
      width: 90,
      align: "end",
      hideIfScopeOrFilters: questionScopesAndFilters,
    },
    {
      text: "Started",
      value: "surveys_started",
      width: 100,
      align: "end",
      hideIfScopeOrFilters: questionScopesAndFilters,
    },
    {
      text: "Completed",
      value: "surveys_completed",
      width: 125,
      align: "end",
      hideIfScopeOrFilters: questionScopesAndFilters,
    },
    {
      text: "Response Rate",
      value: "response_rate",
      width: 150,
      align: "end",
      format: coaxRoundPrct,
      hideIfScopeOrFilters: questionScopesAndFilters,
    },
  ],
  questions: [
    { text: "", value: "name" },
    {
      text: "SBX",
      value: "sb_index.agreement_importance",
      width: 100,
      sortable: false,
      format: score,
    },
    {
      text: "Satisfaction",
      value: "agreement_importance",
      width: 140,
      align: "center",
    },
    { text: "Type", value: "question_type_slug" },
    { text: "Responses", value: "response_count" },
    {
      text: "Summary",
      value: "question_response_summary",
      sortable: false,
      align: "center",
    },
  ],
};

export default {
  mixins: [widgetMixin, tableMixin, multiScopeMixin],
  computed: {
    scopeHeaders() {
      switch (this.reportType) {
        case "timeline":
          return {
            default: [
              { text: "", value: "name" },
              { text: "SBX", value: "sb_index.agreement_importance" },
              ...this.timelineHeaders,
            ],
          };
        default:
          return scopeHeaders;
      }
    },
  },
  methods: { snakeToSentenceCase },
};
</script>
