import { parse } from "@cp/utils/dateUtils";

// only show q{x}
export function toQ(str) {
  const date = parse(str);
  return `Q${date.quarter()}`;
}

// Always append year
export function toYQ(str) {
  const date = parse(str);
  return `${date.year()} Q${date.quarter()}`;
}

// Only append year if q1
export function toyQ(str) {
  const date = parse(str);
  const quarter = date.quarter();
  if (quarter === 1) return `${date.year()} Q${date.quarter()}`;
  else return `Q${date.quarter()}`;
}

// if first item, show year, otherwise only show year for q1
export function mapTDateToQLabel({ t_date: str }, i) {
  if (i === 0) return toYQ(str);
  else return toyQ(str);
}

// if first item, show year, otherwise only show year for q1
export function mapStrToQLabel(str, i) {
  if (i === 0) return toYQ(str);
  else return toyQ(str);
}

export function mapStrToMyLabel(str, i) {
  if (i === 0) return parse(str).format("MMM YYYY");
  const date = parse(str);
  const month = date.month();
  if ([0, 11].includes(month)) return date.format("MMM YYYY");
  return date.format("MMM");
}

export function mapStrToYLabel(str, i) {
  return parse(str).format("YYYY");
}
