<template>
  <v-card
    :style="{ borderLeft: `12px ${status.color} solid` }"
    v-bind="card.props"
    v-on="card.listeners"
    class="cardLink"
    target="_blank"
    hover
  >
    <v-card-text>
      <div class="d-flex align-start">
        <cp-icon :icon="icon" color="#767B87" />
        <div class="ml-2">
          <div class="text-subtitle-2 mb-1" style="line-height:1em">
            {{ capitalize(assignment.type) }}
          </div>
          <div class="text-link">{{ assignment.name }}</div>
          <v-chip v-bind="status.chipProps" small class="text-bold">
            {{ status.title }}
          </v-chip>
          <span class="text-caption ml-3 mr-1"> {{ adverb }} on: </span>
          <strong>
            {{ dates.date }}
          </strong>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { icons } from "@cp/constants/apps";
import { parse, MD } from "@cp/utils/dateUtils";
import { capitalize } from "@cp/utils/stringUtils";

import {
  progressStatusesById,
  complianceStatusesById,
} from "@/store/modules/tasks";

const now = parse();

export default {
  name: "AssignmentCard",
  props: {
    assignment: { type: Object, required: true },
  },
  computed: {
    isTask() {
      return this.assignment.service === "introduce";
    },
    status() {
      if (!this.isTask) return {};
      if (this.assignment.user_task_progress_status_id === 4)
        return complianceStatusesById[
          this.assignment.user_task_compliance_status_id
        ];
      else
        return progressStatusesById[
          this.assignment.user_task_progress_status_id
        ];
    },
    dates() {
      const date = parse(this.assignment.date);
      const isOverdue = now.startOf("day").isAfter(date);
      return {
        date: date.format(MD),
        isOverdue,
      };
    },
    adverb() {
      return this.isTask ? "Due" : "Closes";
    },
    icon() {
      return icons[this.assignment.service];
    },
    card() {
      let listeners = {};
      let props = {};
      if (this.assignment.href) {
        props.href = this.assignment.href;
        props.target = "_blank";
      }
      if (this.isTask) {
        listeners.click = this.clickOnTask;
      }
      return { props, listeners };
      ``;
    },
  },
  methods: {
    capitalize,
    ...mapActions("taskStatusForm", ["openItemFormModal"]),
    clickOnTask() {
      this.openItemFormModal({ id: this.assignment.id });
    },
  },
};
</script>
