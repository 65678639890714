import Vue from "vue";

const BigwigComponents = require.context(
  "@/components",
  true,
  /\.(js|vue)$/i
);

BigwigComponents.keys().map(path => {
  let componentName = path.substring(path.lastIndexOf("/") + 1).split(".")[0];
  return Vue.component(componentName, BigwigComponents(path).default);
});
