<template>
  <cp-toolbar-module-modal :modalConf="modalConf" width="400">
    <template #title>Give Notice</template>

    <v-form>
      <div class="text-h6 mb-7">
        {{ item.first_name }} {{ item.last_name }} is giving notice of her last
        day.
      </div>

      <v-select
        v-bind="fields.termination_categories_id.attrs"
        @change="fields.termination_categories_id.input"
        outlined
      />

      <v-textarea
        v-bind="fields.reason.attrs"
        @input="fields.reason.input"
        outlined
      />

      <date-picker
        v-bind="fields.last_day.attrs"
        @input="fields.last_day.input"
        outlined
      />

      <v-alert
        v-if="!loadingPlans && !offboardingPlans.length"
        type="error"
        text
      >
        No offboarding plans could be found for this employee. Their account
        will be deactivated on their last day.
      </v-alert>

      <cp-unhandled-errors :formConf="formConf" />

      <cp-form-modal-success-or-buttons
        :modalConf="modalConf"
        :formConf="formConf"
        @submit="submit"
        success-message="Notice Recorded"
        v-bind="buttonProps"
      />
    </v-form>
  </cp-toolbar-module-modal>
</template>

<script>
import { giveNoticeForm, planAvailability } from "@/store/modules/employee";
import { wait } from "@cp/utils/promiseUtils";
import { get } from "@cp/utils/objectUtils";

const buttonProps = {
  buttonConf: {
    submit: {
      action: "submit",
      text: "Give Notice",
      icon: "mdi-hand-wave",
      props: { color: "error" },
    },
  },
  buttons: ["cancel", "submit"],
};

export default {
  mixins: [giveNoticeForm.modalMixin, giveNoticeForm.mixin],
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return { buttonProps };
  },
  computed: {
    loadingPlans() {
      return get(this.$store.state, planAvailability.p.s.loading);
    },
    offboardingPlans() {
      return get(
        this.$store.state,
        planAvailability.p.s.stateKey + ".offboarding_plans"
      );
    },
  },
  methods: {
    async submit() {
      await this.giveNoticeSubmit({
        method: "POST",
        id: this.item.id.split(",")[0],
      });
      if (this.success) {
        await wait(1500);
        this.closeModal({ success: true });
      }
    },
  },
};
</script>
