<template>
  <cp-card dense>
    <template #title>
      <div class="bun-service-sum-card__title">
        <cp-icon small :icon="service.id" color="#666666" />
        {{ service.entityTitle }}:
        <span class="font-weight-bold">{{ metrics.count }}</span>
      </div>
    </template>

    <cp-intel-doughnut
      :value="Math.round(metrics.primary)"
      :color="service.primaryMetricColor(metrics.primary)"
      :label="service.primaryMetricTitle"
    />

    <v-divider class="my-2" />

    <div class="bun-service-sum-card__likelihood">
      <v-icon v-if="secondaryPrepend.icon" :color="secondaryPrepend.color">{{
        secondaryPrepend.icon
      }}</v-icon>
      <span v-else>{{ secondaryPrepend }}</span>
      <strong>
        {{ Math.round(metrics.secondary) }}
      </strong>
      <span>{{ service.secondaryMetricAppend }}</span>
    </div>

    <pie-chart-line
      class="bun-service-sum-card__bar"
      :values="secondaryBreakdownChartValues"
    />

    <div class="text-center">
      {{ service.secondaryMetricTitle }}
    </div>
  </cp-card>
</template>

<script>
import { mapState } from "vuex";

import { get } from "@cp/utils/objectUtils";
import services from "@/store/modules/staticData/services";

export default {
  props: {
    serviceId: { type: String, required: true },
  },

  computed: {
    ...mapState("reports", ["item"]),
    service() {
      return services[this.serviceId];
    },
    metrics() {
      return get(this.item, ["metrics", this.serviceId]);
    },
    secondaryPrepend() {
      return this.service.secondaryMetricPrepend(this.metrics.secondary);
    },
    secondaryBreakdownChartValues() {
      return this.service.breakdownToPieChartValues(
        this.metrics.secondaryBreakdown
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.bun-service-sum-card {
  width: 264px;
  display: inline-block;
  margin-top: 6px;
  margin-right: 12px;
  margin-left: 6px;
  margin-bottom: 6px;
  cursor: pointer;

  &:deep > .cp-card {
    transition: background-color 0.25s ease-in-out;

    &:hover {
      background-color: rgba(#0070a8, 0.1);

      .bun-doughnut__track {
        background-color: #ffffff;

        &:after {
          background-color: rgba(#0070a8, 0.1);
        }
      }
    }
  }
}

.bun-service-sum-card__bar {
  padding: 0 16px;
}

.bun-service-sum-card__likelihood {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;

  .v-icon {
    margin-top: -2px;
  }
}
</style>
