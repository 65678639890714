<template>
  <v-tooltip right transition="slide-x-reverse-transition" max-width="250">
    <template #activator="{ on }">
      <v-icon :color="item.color" v-on="on">
        {{ item.icon }}
      </v-icon>
    </template>
    {{ item.tooltip }}
  </v-tooltip>
</template>

<script>
import liklihoodToEmoticon from "@/lib/liklihoodToEmoticon";

export default {
  props: {
    likelihood: { type: [Number, String], default: null },
  },

  computed: {
    item() {
      return liklihoodToEmoticon(this.likelihood);
    },
  },
};
</script>
