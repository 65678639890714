<template>
  <v-row justify="end" class="AppActionCards">
    <v-col v-for="action in appActions" :key="action.app.id" xs="12" sm="auto">
      <v-card>
        <v-card-text class="d-flex align-center text-gray py-3 pr-3 pl-4">
          <cp-icon :icon="action.app.icon" />
          <div class="appName mx-2">{{ action.app.label }}</div>
          <v-btn
            v-for="(button, i) in action.buttons"
            :key="i"
            small
            class="ml-2"
            v-bind="deepMerge({ color: 'primary' }, button.props)"
            v-on="button.listeners ? button.listeners : {}"
          >
            <template v-if="button.count">
              <v-avatar
                v-if="button.count < 10"
                class="black--text mr-2"
                size="20px"
                color="white"
              >
                {{ button.count }}
              </v-avatar>
              <v-chip v-else color="white" class="mr-2" x-small>
                {{ button.count }}
              </v-chip>
            </template>
            {{ button.text }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { deepMerge } from "@cp/utils/objectUtils";

export default {
  name: "AppActionCards",
  computed: {
    ...mapGetters("dashboard", ["appActions"]),
  },
  methods: { deepMerge },
};
</script>

<style lang="scss" scoped>
.appName {
  font-weight: 500;
  font-size: 18px;
}
svg {
  fill: currentColor;
}
.AppActionCards {
  .v-card .v-card__text {
    color: newColor(gray-6);
    font-weight: 500;
    font-size: 18px;
    svg {
      fill: newColor(gray-6);
    }
  }

  .v-chip {
    font-size: 12px;
    padding: 10px 5px;
    border-radius: 20px;
  }
}
</style>
