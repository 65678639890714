var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"dense-vertical"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',_vm._b({attrs:{"outlined":"","dense":""},on:{"input":_vm.fields.first_name.input}},'v-text-field',_vm.fields.first_name.attrs,false))],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',_vm._b({attrs:{"outlined":"","dense":""},on:{"input":_vm.fields.last_name.input}},'v-text-field',_vm.fields.last_name.attrs,false))],1),_c('v-col',{attrs:{"cols":"6"}},[_c('cp-search-autocomplete',_vm._b({attrs:{"form":_vm.form,"searchModule":_vm.locationsSearch,"name":"location_id","initialValue":_vm.item.location_id
            ? {
                value: _vm.item.location_id,
                label: _vm.item.location,
              }
            : null,"outlined":"","dense":""},on:{"input":_vm.fields.location_id.input}},'cp-search-autocomplete',_vm.fields.location_id.attrs,false))],1),_c('v-col',{attrs:{"cols":"6"}},[_c('cp-search-autocomplete',_vm._b({attrs:{"form":_vm.form,"searchModule":_vm.positionsSearch,"name":"position_id","initialValue":_vm.item.position_id
            ? {
                value: _vm.item.position_id,
                label: _vm.item.position,
              }
            : null,"outlined":"","dense":""},on:{"input":_vm.fields.position_id.input}},'cp-search-autocomplete',_vm.fields.position_id.attrs,false))],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',_vm._b({attrs:{"outlined":"","dense":""},on:{"input":_vm.fields.employee_id.input}},'v-text-field',_vm.fields.employee_id.attrs,false))],1),_c('v-col',{attrs:{"cols":"6"}},[_c('date-picker',_vm._b({attrs:{"outlined":"","dense":""},on:{"input":_vm.fields.start_date.input}},'date-picker',_vm.fields.start_date.attrs,false))],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',_vm._b({attrs:{"outlined":"","dense":""},on:{"input":_vm.fields.cell_phone.input}},'v-text-field',_vm.fields.cell_phone.attrs,false))],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',_vm._b({attrs:{"outlined":"","dense":""},on:{"input":_vm.fields.email.input}},'v-text-field',_vm.fields.email.attrs,false))],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',_vm._b({attrs:{"outlined":"","dense":""},on:{"input":_vm.fields.locale.input}},'v-select',_vm.fields.locale.attrs,false))],1)],1),_c('cp-unhandled-errors',{attrs:{"formConf":_vm.formConf}}),(!_vm.success)?_c('cp-form-modal-success-or-buttons',{attrs:{"formConf":_vm.formConf,"modalConf":{ close: () => _vm.$emit('close') },"buttons":_vm.buttons,"buttonConf":{
      confirm: {
        action: 'submit',
        text: 'Confirm',
        icon: 'mdi-account-check-outline',
        props: { color: 'primary' },
      },
      cantComplete: {
        action: 'cantComplete',
        text: 'Can\'t Complete',
        icon: 'mdi-account-cancel',
        props: { color: 'white' },
      },
    },"noun":"Employee Data","success-message":"Employee Data Confirmed!"},on:{"submit":_vm.submit,"cantComplete":function($event){return _vm.$emit('update:cant-complete', true)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }