<template>
  <v-card>
    <cp-h1>
      {{ tableProps.items[0].body }}

      <template #right>
        <cp-filters-menu :module="table" noun="Comment" />
      </template>
    </cp-h1>
    <v-data-table
      v-bind="tableProps"
      @update:options="updateTableOptions"
      hide-default-footer
    >
      <template #item.created_at="{value}">
        {{ toMDY(value) }}
      </template>

      <template #item.comment="{value}">
        <v-alert icon="mdi-message-outline" color="blue-grey" class="my-1" text>
          {{ value }}
        </v-alert>
      </template>
    </v-data-table>

    <v-pagination
      v-if="!noData"
      class="mt-2 text-center"
      v-bind="paginationProps"
      @input="changePage"
    />
  </v-card>
</template>

<script>
import { widgetMixin, tableMixin } from "@/widgets/mixins";

export default {
  mixins: [widgetMixin, tableMixin],
  data() {
    return {
      headers: [
        { text: "Survey", value: "survey_name" },
        {
          text: "Date",
          value: "created_at",
          cellClass: "text-no-wrap",
        },
        { text: "Comment", value: "comment" },
      ],
    };
  },
  computed: {
    title() {
      return `${this.parent.name} - Resident Comments`;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-h6 {
  background: rgba($gray-3, 0.25);
  margin-bottom: 14px;
  padding: 4px 16px;
}

.commentsInMonth {
  padding: 0 16px;
}

::v-deep .v-alert--text {
  padding-top: 4px;
  padding-bottom: 0;
}

.commentQuestion {
  color: $gray-4;
  margin-bottom: 8px;
}

.commentDate {
  color: $gray-4;
  text-align: right;

  margin-top: 8px;
}
</style>
