<template>
  <div>
    <div class="py-2">
      <cp-filters-menu :module="table" class="search--center" hide-filters />
    </div>

    <v-data-table v-bind="tableProps" v-on="tableListeners" hide-default-footer>
      <template #header.on_behalf_of="{header}">
        <cp-table-header-subqueryable
          :header="header"
          :table="table"
          submodule-ref-key="escalationsEmployees"
        />
      </template>

      <template #header.assignees="{header}">
        <cp-table-header-subqueryable
          :header="header"
          :table="table"
          submodule-ref-key="escalationsAssignees"
        />
      </template>

      <template #item.due_by_date="{value}">
        <v-icon color="error" class="mr-2">mdi-alert-box-outline</v-icon>
        {{ value }}
        <span class="red--text">
          {{ daysLate(value) }}
        </span>
      </template>

      <template #item.assignees="{value}">
        <v-chip v-if="value.length === 0" color="error">
          <v-icon class="mr-2" small>mdi-alert</v-icon>
          Nobody assigned!
        </v-chip>
        <template v-else v-for="assignee in value">
          <cp-profile-chip
            :key="`profile-${assignee.id}`"
            :profile="assignee"
            class="ma-1"
          />
          <span :key="`space-${assignee.id}`"> </span>
        </template>
      </template>

      <template #item.on_behalf_of="{value}">
        <v-chip v-if="isEmpty(value)" color="error">
          <v-icon class="mr-2" small>mdi-alert</v-icon>
          Error: not for anyone
        </v-chip>
        <cp-profile-chip v-else :profile="value" />
      </template>
    </v-data-table>

    <v-pagination
      v-if="!noData"
      class="mt-2 text-center"
      v-bind="paginationProps"
      v-on="paginationListeners"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import pluralize from "pluralize";

import { onCpEvent } from "@cp/mixins";
import { taskItemFormModal } from "@/store/modules/tasks";
import { escalationsTable } from "@/store/modules/escalations";
import { parse, toMDY } from "@cp/utils/dateUtils";
import { isEmpty } from "@cp/utils/objectUtils";

export default {
  mixins: [
    escalationsTable.mixin,
    onCpEvent(taskItemFormModal.keys.modalClosedEvent, function({
      success,
    } = {}) {
      if (success) this.refreshTable();
    }),
  ],
  computed: {
    ...mapState("auth", ["hasToken"]),
    title() {
      const service = this.$route.params.service;
      if (service) return `My ${capitalize(service)} Tasks`;
      return "My Tasks";
    },
  },
  methods: {
    toMDY,
    isEmpty,
    daysLate(due_by_date) {
      const now = parse();
      const due = parse(due_by_date);
      const daysLate = now.diff(due, "days");
      return daysLate > 0 ? `${pluralize("day", daysLate, 1)} late` : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.search-field {
  max-width: 350px;
  margin: 10px auto;
}

.v-data-table::v-deep tbody tr > td:first-child {
  box-shadow: inset 8px 0 0 -4px $error;
}

::v-deep .header-assigned-to {
  max-width: 40%;
}
</style>
