import { mergeMixins } from "@cp/store/mixins";
import { zipObject } from "@cp/utils/arrayUtils";
import { Calculator } from "@/store/mixins/Calculator";

const haystackUrl = `${process.env.VUE_APP_HAYSTACK_API_PATH}/en/v1`;
const marigoldUrl = `${process.env.VUE_APP_MARIGOLD_API_PATH}/en/v1`;
const fiverUrl = `${process.env.VUE_APP_FIVER_API_PATH}/en/v1`;

const serviceApis = [
  {
    slug: "marigold",
    endpoints: [
      `${marigoldUrl}/users/`,
      `${marigoldUrl}/locations/`,
      `${marigoldUrl}/positions/`,
      `${marigoldUrl}/geographical_groups/`,
      `${marigoldUrl}/transformations/`,
      `${marigoldUrl}/import_history/`,
      `${marigoldUrl}/units/`,
      `${marigoldUrl}/leases/`,
      `${marigoldUrl}/residents/`,
      `${marigoldUrl}/audits/`,
    ],
  },
  {
    slug: "haystack",
    endpoints: [
      `${haystackUrl}/team`,
      `${haystackUrl}/portfolio`,
      `${haystackUrl}/residents`,
      `${haystackUrl}/search`,
      `${haystackUrl}/tasks/current`,
      `${haystackUrl}/tasks/historical`,
      `${haystackUrl}/escalations/tasks`,
      `${haystackUrl}/escalations/assignees`,
    ],
  },
  {
    slug: "fiver",
    endpoints: [
      `${fiverUrl}/plans/autocomplete`,
      `${fiverUrl}/tasks/autocomplete`,
      `${fiverUrl}/tasks/tags`,
      `${fiverUrl}/enrollments/autocomplete`,
      `${fiverUrl}/user_tasks/autocomplete`,
      `${fiverUrl}/employees/autocomplete`,
      `${fiverUrl}/mentors/autocomplete`,
      `${fiverUrl}/task_assignments/autocomplete`,
      `${fiverUrl}/assignment_groups/autocomplete`,
      `${fiverUrl}/reports/api`,
    ],
  },
];

function moduleName({ slug, baseUrl }) {
  let pertinentPath = baseUrl.split("en/v1/")[1];
  // remove trailing / or /autocomplete
  pertinentPath = pertinentPath.replace(/(\/|\/autocomplete\/*)$/, "");
  const urlName = pertinentPath.replace(/\//, "_");
  return `${slug}_${urlName}`;
}

export const serviceIds = zipObject(
  serviceApis.map(x => x.slug),
  serviceApis.map(({ slug, endpoints }) =>
    endpoints.map(baseUrl => moduleName({ slug, baseUrl }))
  )
);

// flat list of all the endpoints for creating calculators
const calculatorConfigs = serviceApis.reduce((r, { slug, endpoints }) => {
  for (const baseUrl of endpoints) {
    r.push({ slug, baseUrl, name: moduleName({ slug, baseUrl }) });
  }
  return r;
}, []);

export const calculators = calculatorConfigs.map(
  ({ baseUrl, name }) =>
    new Calculator({
      module: "api",
      name,
      baseUrl,
    })
);

export default mergeMixins(...calculators);
