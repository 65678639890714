<template>
  <div>
    <cp-h1>
      Employee Comments

      <template v-if="devMode" #subtitle>
        <dev-mode-widget-links v-bind="{ instance }" />
      </template>

      <template #right>
        <cp-filters-menu :module="table" noun="Report" />
      </template>
    </cp-h1>

    <v-card>
      <v-data-table
        v-bind="tableProps"
        @update:options="updateTableOptions"
        hide-default-footer
      >
        <template #item.context="{item}">
          <div class="text-subtitle-2">{{ item.survey.name }}</div>
          <div class="text-caption">{{ item.question.body }}</div>
        </template>

        <template #item.text_entry="{value, item}">
          <v-alert
            icon="mdi-message-outline"
            color="blue-grey"
            class="my-1"
            text
          >
            <div class="commentQuestion text-caption mt-n3 mb-1">
              {{ item.position_category.name }}:
            </div>
            {{ value }}
          </v-alert>
        </template>
      </v-data-table>

      <v-pagination
        v-if="!noData"
        class="mt-2 text-center"
        v-bind="paginationProps"
        @input="changePage"
      />
    </v-card>
  </div>
</template>

<script>
import { widgetMixin, tableMixin } from "@/widgets/mixins";

export default {
  mixins: [widgetMixin, tableMixin],
  data() {
    return {
      headers: [
        { text: "Survey/Question", value: "context" },
        {
          text: "Date",
          value: "date",
          cellClass: "text-no-wrap",
        },
        { text: "Comment", value: "text_entry" },
      ],
    };
  },
  computed: {
    year() {
      return this.filterParams.year || this.parseDate().format("YYYY");
    },
    previousYear() {
      return parseInt(this.year) - 1;
    },
  },
};
</script>
