<template>
  <card icon="account-alert" title="Employee Priorities">
    TODO
  </card>
</template>

<script>
import widgetMixin from "@/widgets/mixin";

export default {
  mixins: [widgetMixin],
};
</script>
