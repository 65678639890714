import { ApiDataWithMeta, Table } from "@cp/store/mixins";

import addFromMeta from "@/store/addons/addFromMeta";

const baseUrl = process.env.VUE_APP_HAYSTACK_API_PATH;

export class Widget extends ApiDataWithMeta {
  constructor({ name = "widget", filters: filtersArg = [], ...config }) {
    super({
      ...config,
      baseUrl,
      name,
    });
    this.instantiate({ id: config.module });

    addFromMeta.call(this, config);
  }
}

export class BasicWidget extends Widget {
  constructor({ jsonApiResponse = false, ...config }) {
    super({
      ...config,
      jsonApiResponse,
    });
  }
}

export class TableWidget extends Table {
  constructor({
    name = "widget",
    // throw these away, so we can run addFromMeta after super()
    filters: filtersArgTHROWAWAY,
    exportOptions: exportOptionsArgTHROWAWAY,
    ...config
  }) {
    super({ ...config, name, baseUrl });
    this.instantiate({ id: config.module });
    addFromMeta.call(this, config);
  }
}

export class CalculatorWidget extends TableWidget {
  mapData({ data }) {
    // response.data.data.results :D :'D :'| :'( 👍
    return data.data.results;
  }
}
