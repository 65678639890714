<template>
  <card
    title="Survey Responses"
    icon="lead-pencil"
    :class="{ showTable: data.length > 1 }"
    :root-slot="!!data.length"
  >
    <template #actions>
      <alerts-explain :alerts="parent.recent_alerts" />
    </template>

    <v-alert
      v-if="data.length === 0"
      icon="mdi-pencil-off-outline"
      color="blue-grey"
      text
      class="mb-0"
    >
      This resident hasn't taken any surveys yet.
    </v-alert>
    <v-data-table v-else :items="data" :headers="headers">
      <template #item.likely_to_renew="{value}">
        <likelihood-emoticon :likelihood="value" />
      </template>

      <template #item.created_at="{value}">
        {{ toMDY(value) }}
      </template>

      <template #item.agreement_importance="{item}">
        {{
          item.agreement_importance
            ? Math.round(item.agreement_importance)
            : "N/A"
        }}
      </template>

      <template #item.actions="{item}">
        <v-btn v-if="item.is_confidential">
          <v-icon color="primary" left>mdi-shield-account-outline</v-icon>
          Confidential
        </v-btn>
        <v-btn
          v-else
          @click="openModal({ id: item.m_id })"
          color="primary"
          text
          small
          >View Details</v-btn
        >
      </template>
    </v-data-table>
  </card>
</template>

<script>
import { toMDY } from "@cp/utils/dateUtils";

import widgetMixin from "@/widgets/mixin";
import { residentSurveyResult } from "@/store/modules/residentResponses";

const headers = [
  {
    text: "",
    value: "likely_to_renew",
    sortable: false,
    width: 40,
  },
  { text: "Survey", value: "survey_name", sortable: false },
  { text: "Date", value: "created_at", sortable: false },
  { text: "Responses", value: "response_count", sortable: false },
  { text: "Satisfaction", value: "agreement_importance", sortable: false },
  {
    text: "",
    value: "actions",
    sortable: false,
    width: 116,
    cellClass: "text-right",
  },
];

const headersWithLocation = [
  ...headers.slice(0, 2),
  {
    text: "Location",
    value: "location_name",
    sortable: false,
  },
  ...headers.slice(3),
];

export default {
  mixins: [widgetMixin, residentSurveyResult.modalMixin],
  computed: {
    headers() {
      const locationUnits = this.data.map(x => x.location_name + x.unit_number);
      if (locationUnits.every(x => x === locationUnits[0])) return headers;
      // there are multiple locations/Units, add the column
      return headersWithLocation;
    },
  },
  methods: { toMDY },
};
</script>

<style lang="scss" scoped>
.showTable::v-deep {
  .v-card__title {
    margin-bottom: 0;
  }
  .v-card__text {
    padding: 0;
  }
}
</style>
