<template>
  <div class="bun-key-value-item">
    <h4>
      <span class="text--secondary mr-1">{{ label }}</span>
      <span v-if="!textOnNewLine" class="font-weight-bold">{{ text }}</span>
    </h4>
    <span v-if="textOnNewLine" class="font-weight-bold">{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: [String, Number], default: null },
    text: { type: [String, Number], default: null },
    textOnNewLine: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
.bun-key-value-item:not(:last-child) {
  margin-bottom: 8px;
}
</style>
