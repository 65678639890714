import { defaultColumnFormats } from "@/lib/formats";
import { get } from "@cp/utils/objectUtils";

export default {
  data() {
    return {
      showIndexesInTable: false,
      showIndexesInTimeline: false,
    };
  },

  computed: {
    tds() {
      const headers = [];
      const tds = [];

      const sbX = get(this.meta, "summary_object.sb_index", {});
      const sbT = get(this.meta, "summary_object.sbx_timeline", {});

      const cX = get(this.meta, "summary_object.client_index", {});
      const cT = get(this.meta, "summary_object.client_index_timeline", {});

      if (this.scopeHeaders) {
        const scopeHeaders = this.get(
          this.scopeHeaders,
          this.scope,
          this.scopeHeaders.default
        );
        headers.push(
          ...scopeHeaders.map(x => ({
            ...x,
            value: x.value.replace("item.", ""),
          }))
        );
      } else {
        headers.push(...(this.headers || []));
      }

      // remove first column, we are displaying the index names
      headers.splice(0, 1);

      for (const header of headers) {
        const props = {
          class: `text-${header.align || "start"}`,
        };
        let sb, company;
        if (/t_\d+$/.test(header.value)) {
          sb = get(sbT, header.path);
          company = get(cT, header.path);
        } else if (header.value === "metric") {
          sb = get(sbX, this.metric);
          company = get(cX, this.metric);
        } else {
          sb = get(sbX, header.value);
          company = get(cX, header.value);
        }

        if (header.format) {
          sb = sb ? header.format(sb) : "";
          company = company ? header.format(company) : "";
        } else if (
          header.value === "metric" &&
          defaultColumnFormats.hasOwnProperty(this.metric)
        ) {
          sb = defaultColumnFormats[this.metric](sb);
          company = defaultColumnFormats[this.metric](company);
        } else if (defaultColumnFormats.hasOwnProperty(header.value)) {
          sb = defaultColumnFormats[header.value](sb);
          company = defaultColumnFormats[header.value](company);
        }

        if (!sb) sb = "-";
        if (!company) company = "-";

        tds.push({
          props,
          sb,
          company,
        });
      }

      return tds;
    },

    indexesRowsProps() {
      return {
        showIndexesInTimeline: this.showIndexesInTimeline,
        reportType: this.reportType,
        tds: this.tds,
      };
    },
  },

  methods: {
    setShowIndexesInTimeline(value) {
      this.showIndexesInTimeline = value;
    },
    changeShowIndexesInTable(value) {
      this.showIndexesInTable = value;
      const showIndexesInTable = value ? 1 : 0;
      this.$CpEvent.$emit("updateRoute", {
        query: { showIndexes: showIndexesInTable },
      });
    },
  },

  mounted() {
    if (this.$route.query.showIndexes) {
      this.showIndexesInTable = !!this.$route.query.showIndexes;
    }
  },
};
