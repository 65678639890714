<template>
  <div>
    <div v-if="success">
      <v-card-text class="text-center">
        <CpSuccessIcon class="mr-4" xLarge />
        Your email settings have been updated.
      </v-card-text>
    </div>
    <div v-else>
      <v-card-text>
        <h1 class="text-center text-h6">Survey Email Opt-Out</h1>
        <v-form ref="form">
          <v-text-field
            v-bind="fields.email.attrs"
            @input="fields.email.input"
            label="Your Email:"
            :disabled="emailDisabled"
          />
          <p>Do you want to opt out of all future emails?</p>
          <v-btn-toggle
            v-bind="fields.opt_out.attrs"
            @change="fields.opt_out.input"
            mandatory
            dense
          >
            <v-btn value="yes" class="px-3">
              <v-icon class="mr-3">mdi-email-off-outline</v-icon>
              Yes
            </v-btn>
            <v-btn value="no" class="px-3">
              <v-icon class="mr-3">mdi-email-check-outline</v-icon>
              No
            </v-btn>
          </v-btn-toggle>

          <v-alert color="success" text class="mt-4">
            <span v-if="fields.opt_out.attrs.value === 'yes'">
              Yes, please remove my email address. I do not want to be asked for
              my feedback
              <div class="text-caption mt-3">
                Note that if you opt out, you will no longer be asked if you
                would like to provide confidential feedback to the company.
              </div>
            </span>
            <span v-else>
              No, I've changed my mind. You can continue to send me emails
              asking for my feedback
            </span>
          </v-alert>

          <cp-unhandled-errors :formConf="formConf" />

          <div align="center">
            <v-btn @click="submit" color="primary" :loading="loading"
              >Submit</v-btn
            >
          </div>
        </v-form>
      </v-card-text>
    </div>
  </div>
</template>

<script>
import { optOutForm } from "@/store/modules/optOut";

export default {
  mixins: [optOutForm.mixin],
  data() {
    return {
      emailDisabled: true,
    };
  },
  methods: {
    async submit() {
      this.optOutSubmit({ method: "PUT" });
    },
  },
  mounted() {
    let params = new URLSearchParams(window.location.search);
    const email = params.get("email");
    if (!email) {
      this.emailDisabled = false;
    } else {
      this.fields.email.input(email);
    }
  },
};
</script>
