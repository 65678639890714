var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('cp-h1',{scopedSlots:_vm._u([(_vm.devMode)?{key:"subtitle",fn:function(){return [_c('dev-mode-widget-links',_vm._b({},'dev-mode-widget-links',{ instance: _vm.instance },false))]},proxy:true}:null,{key:"right",fn:function(){return [_c('cp-filters-menu',{attrs:{"module":_vm.table,"noun":"Report"}})]},proxy:true}],null,true)},[_vm._v(" Employee Priority Report ")]),_c('div',{staticClass:"card-row"},[_c('div',{staticClass:"card-row-cards"},[_c('stat-cards',_vm._b({},'stat-cards',{ statCards: _vm.statCards, table: _vm.table },false))],1)]),_c('v-card',[_c('v-data-table',_vm._b({attrs:{"hide-default-footer":""},on:{"update:options":_vm.updateTableOptions},scopedSlots:_vm._u([{key:"header.previous_year_score",fn:function(){return [_vm._v(_vm._s(_vm.previousYear)+" score")]},proxy:true},{key:"header.score",fn:function(){return [_vm._v(_vm._s(_vm.year)+" score")]},proxy:true},{key:"item.previous_year_score",fn:function({item}){return [(
            item.previous_year_results &&
              item.previous_year_results.agreement_importance
          )?_c('span',[_vm._v(" "+_vm._s(Math.round( parseFloat(item.previous_year_results.agreement_importance) ) || "N/A")+" ")]):_c('span',[_vm._v("N/A")])]}},{key:"item.direction",fn:function({item}){return [(
            item.agreement_importance &&
              item.previous_year_results &&
              item.previous_year_results.agreement_importance
          )?_c('span',{attrs:{"set":(_vm.prev = Math.round(
              parseFloat(item.previous_year_results.agreement_importance)
            ))}},[_c('span',{attrs:{"set":(_vm.cur = Math.round(parseFloat(item.agreement_importance)))}},[(_vm.prev < _vm.cur)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-trending-up ")]):(_vm.prev === _vm.cur)?_c('v-icon',[_vm._v(" mdi-trending-neutral ")]):(_vm.prev > _vm.cur)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-trending-down ")]):_vm._e()],1)]):_vm._e()]}},{key:"item.score",fn:function({item}){return [_vm._v(" "+_vm._s(Math.round(parseFloat(item.agreement_importance)) || "N/A")+" ")]}}])},'v-data-table',_vm.tableProps,false)),(!_vm.noData)?_c('v-pagination',_vm._b({staticClass:"mt-2 text-center",on:{"input":_vm.changePage}},'v-pagination',_vm.paginationProps,false)):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }