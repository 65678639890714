import { render, staticRenderFns } from "./ServiceIssue.vue?vue&type=template&id=5bfb85ea&scoped=true&"
import script from "./ServiceIssue.vue?vue&type=script&lang=js&"
export * from "./ServiceIssue.vue?vue&type=script&lang=js&"
import style0 from "./ServiceIssue.vue?vue&type=style&index=0&id=5bfb85ea&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bfb85ea",
  null
  
)

export default component.exports