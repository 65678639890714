<template>
  <div v-if="tasks.length" class="text-center">
    <div class="text-caption">
      Progress
    </div>
    <!-- <v-progress-circular :rotate="-90" :size="40" :value="prct" color="success">
      <span class="text-caption">{{ Math.round(prct) || "0" }}%</span>
    </v-progress-circular> -->
    <cp-doughnut size="52px" :value="chartData">
      <template #inside>
        <span class="text-caption">{{ Math.round(prct) || "0" }}%</span>
      </template>
    </cp-doughnut>
  </div>
</template>

<script>
import { partition } from "@cp/utils/arrayUtils";

import { progressStatuses } from "@/store/modules/tasks";

export default {
  name: "TasksProgressBar",
  props: { tasks: { type: Array, default: () => [] } },
  computed: {
    tasksByResolution() {
      // returns [unresolved, resolved]
      return partition(
        this.tasks,
        task => task.user_task_progress_status_id !== 4
      ).map(x => x.length);
    },
    prct() {
      const [unresolved, resolved] = this.tasksByResolution;
      return (resolved / this.tasks.length) * 100;
    },
    chartData() {
      const [unresolved, resolved] = this.tasksByResolution;
      const sets = [
        {
          count: resolved,
          color: progressStatuses.completed.color,
        },
        {
          count: unresolved,
          color: "#9e9e9e",
        },
      ];
      const colors = sets.map(x => x.color);
      return {
        data: sets.map(x => x.count),
        backgroundColor: colors,
        borderWidth: 0,
        cutout: "80%",
        hoverBackgroundColor: colors,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.bar-container {
  width: 100%;
  height: 12px;
  display: flex;

  .status-bar {
    height: 100%;
    text-align: center;
    color: black;
    font-size: 9px;
    line-height: 12px;
  }
}

.CpDoughnut::v-deep {
  margin-top: -10px;

  .chart {
    margin: 0 auto;
  }
}
</style>
