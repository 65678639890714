<script>
import { Bar } from "vue-chartjs/legacy";
import { Bar as ChartJSBar } from "chart.js/auto";

import { deepMerge } from "@cp/utils/objectUtils";

export default {
  extends: Bar,
  props: {
    chartData: { type: Object, default: () => {} },
    options: { type: Object, default: () => {} },
    yMin: { type: Number },
    yMax: { type: Number },
  },

  methods: {
    render() {
      const $this = this;

      const defaultOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            min: this.yMin || null,
            max: this.yMax || null,
          },
        },
        plugins: {
          tooltip: {
            mode: "index",
            intersect: false,
            titleFontSize: 16,
            bodyFontSize: 16,
            usePointStyle: true,
            boxPadding: 4,
            itemSort: function(a, b) {
              return a.datasetIndex - b.datasetIndex;
            },
            callbacks: {
              title: function(tooltipItems) {
                return $this.chartData.labels
                  ? $this.chartData.labels[tooltipItems[0].dataIndex]
                  : "";
              },
            },
          },
          legend: {
            display: false,
          },
        },
      };

      const options = deepMerge({}, defaultOptions, this.options);
      this.renderChart(this.chartData, options);
    },
  },

  watch: {
    chartData() {
      this.render();
    },
  },

  mounted() {
    this.render();
    if (this.$listeners.click) {
      this.$refs.canvas.addEventListener("click", this.$listeners.click);
    }
  },
  beforeDestroy() {
    if (this.$listeners.click) {
      this.$refs.canvas.removeEventListener("click", this.$listeners.click);
    }
  },
};
</script>
