<template>
  <v-card
    v-bind="cardProps"
    :class="{ userCanDo }"
    v-on="listeners"
    :hover="userCanDo"
  >
    <v-card-text>
      <div class="d-flex align-start">
        <div class="ml-2">
          <div class="text-subtitle-2 mb-1" style="line-height:1em">
            {{ task.task.title }}
          </div>
          <div class="d-flex align-center">
            <v-chip v-bind="chipProps" class="text-bold taskStatus">
              {{ chipText }}
            </v-chip>
            <span class="ml-3 mr-1">{{ status.datePrefix }}:</span>
            <strong>
              {{ dateText }}
            </strong>
          </div>
          Employee: <strong>{{ forWho }}</strong>
        </div>
        <slot />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { parse, toMDY } from "@cp/utils/dateUtils";
import { progress_statuses, compliance_statuses } from "@/store/modules/tasks";
import { getFirst } from "@cp/utils/objectUtils";

export default {
  name: "TaskCard",
  props: {
    task: { type: Object, required: true },
  },
  computed: {
    ...mapGetters("haystackUser", ["userId"]),
    dates() {
      return {
        now: parse(),
        due: parse(this.task.due_by_date),
        updated: parse(this.task.updated_at),
      };
    },
    dateText() {
      const attrs = this.status.dateAttr.split(",");
      const date = getFirst(this.task, attrs, this.task.updated_at);
      return toMDY(date);
    },
    status() {
      if (this.item.user_task_progress_status_id === 4)
        return compliance_statuses[this.item.user_task_compliance_status_id];
      else return progress_statuses[this.item.user_task_progress_status_id];
    },
    cpIcon() {
      return "fiver";
    },
    userCanDo() {
      const assigneeIds = this.task.assignees.map(x => x.id);
      return assigneeIds.includes(this.userId);
    },
    listeners() {
      return this.userCanDo
        ? {
            click: this.click,
          }
        : {};
    },
    cardProps() {
      const color = this.status.chipProps.color;
      return { style: { borderLeft: `12px ${color} solid` } };
    },
    chipProps() {
      return this.status.chipProps;
    },
    chipText() {
      return this.status.title;
    },
    forWho() {
      if (this.userId === this.task.on_behalf_of.id) return "You";
      return `${this.task.on_behalf_of.first_name} ${this.task.on_behalf_of.last_name}`;
    },
  },
  methods: {
    toMDY,
    ...mapActions("tasks", ["openItemFormModal"]),
    click() {
      if (this.userCanDo) this.openItemFormModal({ id: this.task.id });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  max-height: 96px;
  &:not(.userCanDo) {
    cursor: default;
  }
}

.v-chip.taskStatus {
  padding: 0 6px;
  height: 1.25rem;
  font-size: 0.7rem;
}
</style>
