<template>
  <div class="d-flex">
    <likert
      :values="item.response_summary ? item.response_summary.importance : []"
      :average="item.ai_importance"
      type="importance"
    />
  </div>
</template>

<script>
export default {
  props: ["item"],
};
</script>
