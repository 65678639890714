<template>
  <div class="widget">
    <v-progress-circular v-if="loading" indeterminate />
    <component
      v-else-if="instance"
      :is="id"
      v-bind="{ instance }"
      ref="component"
    />
    <v-alert v-else type="error">{{ id }} widget missing</v-alert>
  </div>
</template>

<script>
import { get } from "@cp/utils/objectUtils";

import { registerWidget } from "@/store/modules/widgets";
import widgets from "@/widgets/index.js";

export default {
  name: "Widget",
  components: widgets,
  props: {
    data: { required: true },
    meta: { type: Object, required: true },
    standalone: { type: Boolean, default: false },
    config: { type: Object, default: () => ({}) },
    widgetId: { type: String },
  },
  data() {
    return {
      error: "",
      instance: null,
      widgetKeys: Object.keys(widgets),
      id: "",
    };
  },
  computed: {
    loading() {
      // if the instance doesn't exist yet, there is no state to bind to, show nothing
      // standalone widgets should use their own loading indicators
      if (!this.instance) {
        // so far no.. leaving it here, just in case
        console.log("Widget.vue - loading() - We should never see this");
      }
      if (!this.instance || this.standalone) return false;
      return get(this.$store.state, this.instance.p.s.loading);
    },
  },
  created() {
    const id = this.widgetId || get(this.$props, "meta.widget.id");
    if (widgets.hasOwnProperty(id)) {
      this.id = id;
      this.instance = registerWidget(this.$props);
      this.$emit("initialized", this.instance);
    } else {
      console.log(`Widget not found: '${id}'`);
    }
  },
};
</script>
