import Portfolio from "./Portfolio";
import PropertyShow from "./PropertyShow.vue";
import RegionShow from "./RegionShow.vue";

function parent(currentRoute) {
  return {
    name: "property",
    params: { id: currentRoute.params.id },
  };
}

export default [
  {
    path: "/property/:id/:widget?",
    name: "property",
    component: PropertyShow,
  },
  {
    path: "/region/:id/:widget?",
    name: "region",
    component: RegionShow,
  },
  {
    path: "/portfolio",
    name: "portfolio",
    component: Portfolio,
  },
];
