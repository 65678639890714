<template>
  <v-sheet rounded class="StatCard">
    <Stat v-bind="card" />
    <div class="StatCard__label">
      <v-icon v-if="card.icon" v-bind="iconProps">{{ card.icon }}</v-icon>
      <cp-icon v-else-if="card.cpIcon" :icon="card.cpIcon" v-bind="iconProps" />
      {{ label }}
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: "StatCard",
  props: {
    card: { type: Object, required: true },
  },
  computed: {
    label() {
      return this.card.label || "TODO card.label";
    },
    iconProps() {
      return this.card.iconProps || {};
    },
  },
};
</script>
