<template>
  <card title="Comments" icon="message">
    <v-alert
      v-if="data.length === 0"
      icon="mdi-message-off-outline"
      color="blue-grey"
      text
      class="mb-0"
    >
      This resident has not left any comments on surveys yet.
    </v-alert>
    <template v-else>
      <div
        v-for="{ id, name, comments, date } in surveys"
        :key="id"
        class="surveyComments"
      >
        <div class="text-h6">{{ toMDY(date) }} - {{ name }}</div>
        <div v-for="{ q_id, q_body, comment } in comments" :key="id + q_id">
          <div>
            <div class="commentQuestion d-flex align-center text-caption mb-n6">
              {{ q_body }}
            </div>
          </div>
          <v-alert
            icon="mdi-message-outline"
            color="blue-grey"
            text
            class="pt-7"
          >
            {{ comment }}
          </v-alert>
        </div>
      </div>
    </template>
  </card>
</template>

<script>
import { findByObj } from "@cp/utils/arrayUtils";
import widgetMixin from "@/widgets/mixin";
import { toMDY } from "@cp/utils/dateUtils";

export default {
  mixins: [widgetMixin],
  computed: {
    surveys() {
      return this.data.reduce((r, comment) => {
        const foundSurvey = findByObj(r, { id: comment.surveys_user_id });
        if (foundSurvey) {
          foundSurvey.comments.push({
            q_id: comment.question_id,
            q_body: comment.question.body,
            comment: comment.text_entry,
          });
        } else {
          r.push({
            id: comment.surveys_user_id,
            name: comment.survey.name,
            date: comment.updated_at,
            comments: [
              {
                q_id: comment.question_id,
                q_body: comment.question.body,
                comment: comment.text_entry,
              },
            ],
          });
        }
        return r;
      }, []);
    },
  },
  methods: { toMDY },
};
</script>

<style lang="scss" scoped>
.comment-alert {
  .subtitle-2 {
    border-bottom: 1px solid $gray-3;
  }
  + .comment-alert {
    margin-top: 10px;
  }
}
.comment-date {
  white-space: nowrap;
  color: $gray-3;
  vertical-align: middle;
}

.comments-table {
  tr + tr {
    border-top: 1px solid $gray-3;
  }
}
.surveyComments:first-child {
  margin-top: -16px;
}

.text-h6 {
  background: rgba($gray-3, 0.25);
  margin: 0 -16px 14px;
  padding: 4px 16px;
}

.commentQuestion {
  margin-left: 56px;
  color: $gray-4;
}
</style>
