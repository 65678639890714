<template>
  <v-card class="mt-6">
    <v-card-text class="d-flex align-center">
      <cp-icon class="service-icon" icon="ingage" color="grey" x-large />
      <div class="text-no-wrap ml-4">
        <div class="font-weight-bold">Engagement Surveys</div>
        <div>
          <div v-if="counts.length === 0">No surveys sent yet.</div>
          <div v-else>
            <span
              class="count"
              v-for="{ key, label, value } in counts"
              :key="key"
            >
              <strong>{{ value }}</strong>
              {{ properize(label) }}
            </span>
          </div>
        </div>
      </div>

      <v-spacer />

      <v-btn
        v-if="parent.location && parent.location.value"
        :to="{ name: 'property', params: { id: parent.location.value } }"
        color="primary"
      >
        View Location Results
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import widgetMixin from "@/widgets/mixin";

export default {
  mixins: [widgetMixin],
  computed: {
    counts() {
      const keys = ["sent", "new_assigned"];
      const labels = { new_assigned: "currently_assigned" };
      return keys
        .filter(k => this.data[k])
        .map(key => ({
          key,
          label: labels[key] || key,
          value: this.data[key],
        }));
    },
  },
};
</script>
