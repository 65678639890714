import haystackUser from "./modules/haystackUser";
import tasks from "./modules/tasks";
import dashboard from "./modules/dashboard";
import portfolio from "./modules/portfolio";
import escalations from "./modules/escalations";
import taskStatusForm from "./modules/taskStatusForm";
import team from "./modules/team";
import employee from "./modules/employee";
// import locations from "./modules/locations";
import positions from "./modules/positions";
import api from "./modules/api";
import residents from "./modules/residents";
import residentResponses from "./modules/residentResponses";
import widgets from "./modules/widgets";
import optOut from "./modules/optOut";

import { withoutDistrict, withDistrict } from "./modules/staticData/levels";

export default {
  haystackUser,
  tasks,
  dashboard,
  portfolio,
  escalations,
  taskStatusForm,
  team,
  employee,
  // locations,
  positions,
  api,
  residents,
  residentResponses,
  widgets,
  optOut,
  root: {
    state: {
      devMode: false,
    },
    getters: {
      clientLevels(state, getters, rootState, rootGetters) {
        const clientHasDistricts = rootGetters["auth/clientHasDistricts"];
        return clientHasDistricts ? withDistrict : withoutDistrict;
      },
      userLevels(state, { clientLevels }, rootState, rootGetters) {
        const bigwigLevel = rootGetters["auth/thisAppAccessLevel"];
        console.log("You are a " + bigwigLevel);
        // TODO -- limit based on bigwigLevel
        return clientLevels;
      },
    },
  },
};
