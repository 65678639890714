<template>
  <div>
    <cp-h1>
      Resident Detail
    </cp-h1>

    <card v-if="data.has_active_lease" :set="(lease = data.current_lease)">
      <v-row class="align-center summaryRow">
        <v-col>
          <h6 class="text-h6 font-weight-bold">
            {{ parent.first_name }}
            {{ parent.last_name }}
          </h6>
          <table>
            <tbody>
              <tr>
                <td>Property</td>
                <td colspan="2">
                  {{ (lease.location || { name: "N/A" }).name }}
                </td>
              </tr>
              <tr
                v-if="
                  !lease.unit.unit_number.includes(lease.unit.building_name)
                "
              >
                <td>Building</td>
                <td colspan="2">
                  {{ lease.unit.building_name }}
                </td>
              </tr>
              <tr>
                <td>Unit</td>
                <td colspan="2">
                  {{ lease.unit.unit_number }}
                </td>
              </tr>
              <tr>
                <td>Lease Start</td>
                <td>{{ dates.leaseStart }}</td>
                <td>{{ dates.leaseStartFrom }}</td>
              </tr>
              <tr>
                <td>Lease End</td>
                <td>{{ dates.leaseEnd }}</td>
                <td>{{ dates.leaseEndFrom }}</td>
              </tr>
              <tr>
                <td>Moved In</td>
                <td>{{ dates.moveIn }}</td>
                <td>{{ dates.moveInFrom }}</td>
              </tr>
            </tbody>
          </table>
        </v-col>
        <v-col md="3">
          <v-menu
            max-width="300"
            v-if="
              data.maintenance_managers.length ||
                data.property_managers.length ||
                data.regional_managers.length
            "
          >
            <template #activator="{on}">
              <v-btn v-on="on" color="primary" text small>
                <v-icon left>mdi-email</v-icon>
                Contact a manager
              </v-btn>
            </template>

            <v-list dense>
              <template v-if="data.maintenance_managers.length">
                <v-subheader>
                  Maintenance
                  {{ pluralize("Manager", data.maintenance_managers.length) }}
                </v-subheader>
                <v-list-item
                  v-for="manager in data.maintenance_managers"
                  :key="`m-${manager.id}`"
                  :href="manager.cell_phone ? null : `mailto:${manager.email}`"
                  target="_blank"
                >
                  <v-list-item-content>
                    {{ manager.full_name }}
                  </v-list-item-content>
                  <v-list-item-icon v-if="manager.email">
                    <a :href="`mailto:${manager.email}`" target="_blank">
                      <v-icon>mdi-email</v-icon>
                    </a>
                  </v-list-item-icon>
                  <v-list-item-icon v-if="manager.cell_phone">
                    <a :href="`tel:${manager.cell_phone}`">
                      <v-icon>mdi-phone</v-icon>
                    </a>
                  </v-list-item-icon>
                </v-list-item>
              </template>

              <template v-if="data.property_managers.length">
                <v-subheader>
                  Property
                  {{ pluralize("Manager", data.property_managers.length) }}
                </v-subheader>
                <v-list-item
                  v-for="manager in data.property_managers"
                  :key="`p-${manager.id}`"
                  :href="manager.cell_phone ? null : `mailto:${manager.email}`"
                  target="_blank"
                >
                  <v-list-item-content>
                    {{ manager.full_name }}
                  </v-list-item-content>
                  <v-list-item-icon v-if="manager.email">
                    <a :href="`mailto:${manager.email}`" target="_blank">
                      <v-icon>mdi-email</v-icon>
                    </a>
                  </v-list-item-icon>
                  <v-list-item-icon v-if="manager.cell_phone">
                    <a :href="`tel:${manager.cell_phone}`">
                      <v-icon>mdi-phone</v-icon>
                    </a>
                  </v-list-item-icon>
                </v-list-item>
              </template>

              <template v-if="data.regional_managers.length">
                <v-subheader>
                  Regional
                  {{ pluralize("Manager", data.regional_managers.length) }}
                </v-subheader>
                <v-list-item
                  v-for="manager in data.regional_managers"
                  :key="`r-${manager.id}`"
                  :href="manager.cell_phone ? null : `mailto:${manager.email}`"
                  target="_blank"
                >
                  <v-list-item-content>
                    {{ manager.full_name }}
                  </v-list-item-content>
                  <v-list-item-icon v-if="manager.email">
                    <a :href="`mailto:${manager.email}`" target="_blank">
                      <v-icon>mdi-email</v-icon>
                    </a>
                  </v-list-item-icon>
                  <v-list-item-icon v-if="manager.cell_phone">
                    <a :href="`tel:${manager.cell_phone}`">
                      <v-icon>mdi-phone</v-icon>
                    </a>
                  </v-list-item-icon>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>

          <div class="text-subtitle ml-2">Contact Resident:</div>
          <div v-if="parent.primary_email" class="text-truncate">
            <v-btn
              :href="`mailto:${parent.primary_email}`"
              class="text-truncate"
              color="primary"
              text
              small
            >
              <v-icon left>mdi-email</v-icon>
              <span class="text-truncate">{{ parent.primary_email }}</span>
            </v-btn>
          </div>
          <div v-if="parent.phone_number" class="text-truncate">
            <v-btn
              :href="`tel:${parent.phone_number}`"
              color="primary"
              text
              small
            >
              <v-icon left>mdi-phone</v-icon>
              <span class="text-truncate">{{ parent.phone_number }}</span>
            </v-btn>
          </div>
        </v-col>
        <v-col class="text-center text-no-wrap">
          <v-icon :color="likelihood.color" size="40">
            {{ likelihood.icon }}
          </v-icon>
          <div class="text-caption" :class="`${likelihood.color}--text`">
            {{ likelihood.tooltip }}
          </div>
        </v-col>
      </v-row>
    </card>

    <card
      v-if="data.previous_leases && data.previous_leases.length"
      :title="pluralize('Previous Lease', data.previous_leases.length)"
      icon="account-cash"
    >
      <v-row>
        <v-col>
          TODO - add previous_leases to data, and display in a list
        </v-col>
      </v-row>
    </card>
  </div>
</template>

<script>
import pluralize from "pluralize";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

import { toMDY, toRelative } from "@cp/utils/dateUtils";
import { get } from "@cp/utils/objectUtils";

import widgetMixin from "../mixin";
import liklihoodToEmoticon from "@/lib/liklihoodToEmoticon";

export default {
  mixins: [widgetMixin],
  computed: {
    title() {
      return `Resident Detail - ${this.parent.first_name} ${this.parent.last_name}`;
    },
    likelihood() {
      return liklihoodToEmoticon(this.data.likely_to_renew);
    },
    dates() {
      const leaseStart = get(this.data, "current_lease.start_date");
      const leaseEnd = get(this.data, "current_lease.end_date");
      const moveIn = get(this.data, "current_lease.move_in_date");
      return {
        leaseStart: toMDY(leaseStart),
        leaseStartFrom: dayjs(leaseStart).fromNow(),
        leaseEnd: toMDY(leaseEnd),
        leaseEndFrom: dayjs(leaseEnd).fromNow(),
        moveIn: toMDY(moveIn),
        moveInFrom: dayjs(moveIn).fromNow(),
      };
    },
  },
  methods: { toMDY, toRelative, pluralize },
};
</script>

<style lang="scss" scoped>
.v-list-item {
  .v-icon {
    color: $primary;
  }

  .v-list-item__content {
    font-weight: normal;
  }

  &:hover {
    text-decoration: none;
  }
}

.summaryRow table {
  white-space: nowrap;
  max-width: 100%;

  td:first-child,
  td:nth-child(3) {
    font-weight: 200;
    font-size: 0.85rem;
    color: $gray-4;
  }

  td:first-child {
    padding-right: 10px;
  }

  td:nth-child(2) {
    font-weight: 500;
  }

  td:nth-child(3) {
    font-weight: 200;
    font-size: 0.85rem;
    color: $gray-4;
    padding-left: 10px;
  }
}

.text-truncate .v-btn::v-deep {
  justify-content: start;

  &,
  .v-btn__content {
    max-width: 100%;
  }
}
</style>
