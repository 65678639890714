<template>
  <div>
    <cp-h1 :hide-back-button="hasToken">
      Tasks for employee
    </cp-h1>

    <!-- <task-list :module="employeeTasks" /> -->
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "EmployeeOverview.vue",
  data() {
    return {
      // employeeTasks,
    };
  },
  computed: {
    ...mapState("auth", ["hasToken"]),
    ...mapGetters("haystackUser", ["userId"]),
    title() {
      return "Tasks for employee";
    },
    breadcrumbs() {
      return [
        {
          text: "Home",
          icon: "mdi-home",
          to: { name: "alerts" },
          exact: true,
        },
        {
          text: "Tasks for employee",
        },
      ];
    },
  },
  methods: {
    fetch() {
      // const id = get(this.$router, "currentRoute.params.id");
      // if (id) this.$store.dispatch(`tasks/fetchEmployeeTasks`, { id });
      // else
      //   this.$store.dispatch(`tasks/fetchEmployeeTasks`, { id: this.userId });
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>
