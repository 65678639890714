<template>
  <div class="d-flex justify-center" v-on="$listeners">
    <bar v-bind="{ ...activatorProps, ...props }" />
  </div>
</template>

<script>
import { get } from "@cp/utils/objectUtils";

const activatorProps = {
  width: 100,
  height: 32,
  yMin: 0,
  options: {
    responsive: false,
    scales: {
      x: { display: false },
      y: { display: false },
    },
    layout: {
      autoPadding: false,
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
    },
  },
};

const explainerProps = {
  width: 300,
  height: 180,
  yMin: 0,
  options: {
    responsive: false,
  },
};

export default {
  props: {
    item: { type: Object, required: true },
    table: { type: Object, required: true },
  },
  data() {
    return { activatorProps, explainerProps };
  },
  computed: {
    props() {
      const values = get(this.item, "response_summary", []).map(parseFloat);
      const yMax = Math.max(...values);
      return {
        yMax,
        chartData: {
          yMin: 0,
          yMax,
          labels: [
            "Very Unlikely",
            "Unlikely",
            "Neutral",
            "Likely",
            "Very Likely",
          ],
          datasets: [
            {
              label: "Agreement",
              data: values,
              backgroundColor: [
                "#eb4969",
                "#ee7850",
                "#f3aa47",
                "#99c061",
                "#68ab5d",
              ],
            },
          ],
        },
      };
    },
  },
};
</script>
