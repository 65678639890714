import { mapState, mapGetters } from "vuex";
import pluralize from "pluralize";

import { nonEmpty } from "@cp/utils/itertees";
import { capitalize, properize } from "@cp/utils/stringUtils";
import { get, getFirst, isEmpty, filterObject } from "@cp/utils/objectUtils";
import {
  findByObj,
  includesObject,
  zipObject,
  firstInArray,
  findByHas,
  compact,
  intersection,
} from "@cp/utils/arrayUtils";
import {
  parse as parseDate,
  toMDY,
  date,
  toEXACT,
  toTIME,
  toRelative,
} from "@cp/utils/dateUtils";
import { mapItem } from "@cp/utils/jsonapiUtils";
import { roundPrct, coaxRoundPrct } from "@/lib/formats";

export default {
  props: {
    instance: { type: Object, required: true },
  },
  computed: {
    ...mapState(["devMode"]),
    breadcrumbs() {
      const { breadcrumbs = false } = this.$router.currentRoute.meta;
      return typeof breadcrumbs === "function" && breadcrumbs(this);
    },
    data() {
      return get(this.$store.state, this.instance.p.s.stateKey);
    },
    meta() {
      return get(this.$store.state, this.instance.p.s.meta);
    },
    addons() {
      return get(this.$store.state, this.instance.p.s.addons);
    },
    parent() {
      const parent = get(this.$store.state, this.instance.p.s.meta + ".parent");
      return parent ? mapItem(parent) : {};
    },
    title() {
      const full_name = this.parent.full_name;
      const first_and_last = compact([
        this.parent.first_name,
        this.parent.last_name,
      ]).join(" ");
      const name = this.parent.name;
      const title = this.parent.title;
      return [full_name, first_and_last, name, title].find(x => x);
    },
  },
  methods: {
    // string
    capitalize,
    pluralize,
    properize,

    // object
    get,
    getFirst,
    isEmpty,
    filterObject,

    // date
    parseDate,
    toMDY,
    date,
    toEXACT,
    toTIME,
    toRelative,

    // array
    findByObj,
    includesObject,
    zipObject,
    firstInArray,
    findByHas,
    compact,
    intersection,

    // number
    roundPrct,
    coaxRoundPrct,

    // any
    nonEmpty,

    ...mapGetters("haystackUser", ["userIs", "clientHasService"]),
  },
};
