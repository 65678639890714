<template>
  <div>
    <cp-h1>
      Employee Development

      <template #subtitle>
        {{ parent.name }}
      </template>

      <template #right>
        <cp-filters-menu :module="table" noun="Report" />
      </template>
    </cp-h1>

    <v-card>
      <v-data-table
        v-bind="tableProps"
        :headers="headers"
        @update:options="updateTableOptions"
        hide-default-footer
        @click:row="navToEmployee"
        class="clickable-rows"
      >
        <template #item.task_status="{item}">
          <progress-bar v-bind="progressBarProps(item)" hide-caption />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { widgetMixin, tableMixin } from "@/widgets/mixins";

export default {
  mixins: [widgetMixin, tableMixin],
  data() {
    return {
      headers: [
        {
          text: "Employee",
          value: "name",
          sortable: false,
        },
        {
          text: "Position",
          value: "position",
          sortable: false,
        },
        {
          text: "Type",
          value: "employee_status",
          width: 130,
          sortable: false,
        },
        {
          text: "Status",
          value: "task_status",
          width: 180,
          sortable: false,
        },
      ],
    };
  },
  computed: {
    title() {
      return `${this.parent.name} - Employee Development`;
    },
  },
  methods: {
    progressBarProps(item) {
      const { task_count = 0, completed = 0, pending = 0, past_due = 0 } = item;
      return {
        resolved: completed,
        late: past_due,
        unresolved: pending,
        total: task_count,
        classes: {
          resolved: "success white--text",
        },
      };
    },
    navToEmployee({ m_id: id }, x, event) {
      const route = {
        name: "teamEmployeeDetails",
        params: { id },
      };
      this.$router.push(route);
    },
  },
};
</script>

<style lang="scss" scoped>
.Stat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  color: rgba(0, 0, 0, 0.6);
  &.clickableStat {
    cursor: pointer;
    &:hover {
      background: rgba($primary, 0.2);
    }
  }
}
.value {
  font-size: 2.5rem;
  line-height: 3.5rem;
  font-weight: lighter;
}
.label {
  white-space: nowrap;

  span {
    vertical-align: middle;
  }
}

.employeesInPlans::v-deep .v-data-table__wrapper table {
  min-height: 120px;
}

.ps {
  position: relative;
  max-height: 120px;
}
</style>
