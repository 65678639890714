import List from "./List";
import Show from "./Show";
import CareerDevelopment from "./CareerDevelopment";

export default [
  {
    path: "/team",
    name: "team",
    component: List,
    meta: {
      pageTitle: "Team",
    },
  },
  {
    path: "/team/employee-details/:id",
    name: "teamEmployeeDetails",
    component: Show,
    meta: { parent: "team", title: "Employee Details" },
  },
  {
    path: "/team/employee-details/:id/career",
    name: "employeeCareerDevelopment",
    component: CareerDevelopment,
    meta: {
      parent: currentRoute => ({
        name: "teamEmployeeDetails",
        params: { id: currentRoute.params.id },
      }),
      title: "Career Development",
    },
  },
];
