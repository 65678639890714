<template>
  <cp-toolbar-module-modal
    :modalConf="modalConf"
    v-on="$listeners"
    width="680"
    @closed="reset"
    @opened="resetDropzoneOptions"
  >
    <template #title>{{
      editMode
        ? `Edit ${item.first_name} ${item.last_name}'s profile`
        : "Create new employee"
    }}</template>

    <div v-if="showCreatingTimedOut" class="pa-10 text-center">
      <v-icon>mdi-timer-sand</v-icon>
      <span class="ml-4">
        It is taking longer than usual to create the employee. Please check back
        later.
      </span>
    </div>
    <div v-else-if="showCreatingSpinner" class="pa-10 text-center">
      <v-progress-circular indeterminate />
      <span class="ml-4">
        Creating employee. Please wait.
      </span>
    </div>
    <div v-else>
      <div v-if="editMode" class="d-flex align-center justify-center mb-4">
        <div class="profileImageArea">
          <cp-profile-avatar :profile="item" style="width: 150px" />
          <!-- <v-btn
            v-if="editingImage"
            @click="deleteImage"
            :loading="uploadingImage"
            class="imageHandle"
            color="error"
            fab
            x-small
          >
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
          <v-btn
            v-else
            @click="editingImage = true"
            class="imageHandle"
            color="primary"
            fab
            x-small
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn> -->
        </div>
        <!-- <VueDropzone
          v-if="editingImage"
          id="profile-image-upload"
          :options="dropzoneOptions"
          :use-custom-slot="true"
          class="mx-3"
          :class="{ small }"
          @vdropzone-success="uploadSuccess"
          @vdropzone-sending="sendData"
        >
          <v-btn color="primary" v-bind="{ small: !small }">
            <v-icon class="mr-2">mdi-upload</v-icon>
            <span class="d-none d-sm-inline">Click to&nbsp;</span>
            upload
          </v-btn>
          <div class="d-none d-sm-block">
            <div class="my-2">Or</div>

            <div>
              <v-icon class="animateDragDrop mr-3">mdi-cursor-default</v-icon>
              Drag and drop here
            </div>
          </div>
        </VueDropzone>
        <v-btn
          v-if="editingImage"
          @click="editingImage = false"
          :loading="uploadingImage"
          fab
          x-small
          title="cancel"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn> -->
      </div>
      <div v-else class="mx-auto mb-6" style="width: 150px">
        <cp-profile-avatar :profile="item" />
      </div>

      <v-row class="dense-vertical">
        <v-col cols="6">
          <v-text-field
            v-bind="fields.first_name.attrs"
            @input="fields.first_name.input"
            outlined
            dense
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-bind="fields.last_name.attrs"
            @input="fields.last_name.input"
            outlined
            dense
          />
        </v-col>
        <v-col cols="6">
          <cp-search-autocomplete
            :form="form"
            :searchModule="locationsSearch"
            name="location_id"
            v-bind="fields.location_id.attrs"
            @input="fields.location_id.input"
            :initialValue="
              item.location_id
                ? {
                    value: item.location_id,
                    label: item.location,
                  }
                : null
            "
            outlined
            dense
          />
        </v-col>
        <v-col cols="6">
          <cp-search-autocomplete
            :form="form"
            :searchModule="positionsSearch"
            name="position_id"
            v-bind="fields.position_id.attrs"
            @input="fields.position_id.input"
            :initialValue="
              item.position_id
                ? {
                    value: item.position_id,
                    label: item.position,
                  }
                : null
            "
            outlined
            dense
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-bind="fields.employee_id.attrs"
            @input="fields.employee_id.input"
            outlined
            dense
          />
        </v-col>
        <v-col cols="6">
          <date-picker
            v-bind="fields.start_date.attrs"
            @input="fields.start_date.input"
            outlined
            dense
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-bind="fields.cell_phone.attrs"
            @input="fields.cell_phone.input"
            outlined
            dense
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-bind="fields.email.attrs"
            @input="fields.email.input"
            outlined
            dense
          />
        </v-col>
        <!-- <v-col v-if="editMode" cols="6">
          <v-autocomplete
            ref="managers"
            v-bind="fields.manager_ids.attrs"
            @input="fields.manager_ids.input"
            @update:search-input="searchEmployees"
            @change="changeManagers"
            :items="managers"
            :loading="employeesLoading"
            label="Managers"
            auto-select-first
            multiple
            outlined
            dense
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-bind="fields.personal_email.attrs"
            @input="fields.personal_email.input"
            outlined
            dense
          />
        </v-col> -->
        <v-col cols="6">
          <v-select
            v-bind="fields.locale.attrs"
            @input="fields.locale.input"
            outlined
            dense
          />
        </v-col>
        <!-- <v-col v-if="editMode" cols="6">
          <v-checkbox
            v-bind="fields.opt_out.attrs"
            @input="fields.opt_out.input"
          />
        </v-col> -->
      </v-row>

      <cp-unhandled-errors :formConf="formConf" />

      <cp-form-modal-success-or-buttons
        :modalConf="modalConf"
        :formConf="formConf"
        :buttons="['cancel', editMode ? 'update' : 'create']"
        @submit="submit"
        noun="Employee"
        :success-message="`Employee ${editMode ? 'updated' : 'created'}`"
      />

      <!-- <div class="mt-6 text-right">
        <v-btn color="white" @click="closeModal">Cancel</v-btn>
        <v-btn color="primary" @click="submit" class="ml-4">
          {{ editMode ? "Save" : "Create" }}
        </v-btn>
      </div> -->
    </div>
  </cp-toolbar-module-modal>
</template>

<script>
// import "vue2-dropzone/dist/vue2Dropzone.min.css";
// import VueDropzone from "vue2-dropzone";
import { mapState, mapGetters } from "vuex";

import Client, { auth } from "@cp/lib/Client";
import { onCpEvent } from "@cp/mixins";

import {
  marigoldEmployee,
  locationsSearch,
  positionsSearch,
} from "@/store/modules/employee";
import { wait } from "@cp/utils/promiseUtils";
import { get } from "@cp/utils/objectUtils";

const baseUrl = `${process.env.VUE_APP_MARIGOLD_API_PATH}/en/v1/users`;
const usersApi = new Client({
  baseUrl,
});
const teamUserApi = new Client({
  baseUrl: `${process.env.VUE_APP_HAYSTACK_API_PATH}/en/v1/team/`,
});

export default {
  mixins: [
    marigoldEmployee.modalMixin,
    marigoldEmployee.mixin,
    onCpEvent(marigoldEmployee.keys.modalOpenedEvent, function() {
      this.resetCreating();
    }),
    onCpEvent(marigoldEmployee.keys.modalClosedEvent, function() {
      this.resetCreating();
    }),
  ],
  // components: { VueDropzone },
  data() {
    return {
      selectedManagers: [],
      locationsSearch,
      positionsSearch,
      // editingImage: false,
      // uploadingImage: false,
      // dropzoneOptions: {},

      creatingId: "",
      showCreatingSpinner: false,
      showCreatingTimedOut: false,
      creatingSpinnerCounter: 0,
      creatingSpinnerTimeout: 0,
    };
  },
  computed: {
    ...mapState("employee", ["item"]),
    ...mapGetters("auth", ["userIsSwiftbunny"]),
    editMode() {
      return !!this.item.id;
    },
    small() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    resetDropzoneOptions({ id } = {}) {
      this.dropzoneOptions = {
        url: `${baseUrl}/${id}/upload-avatar`,
        maxFiles: 1,
        acceptedFiles: ".jpg,.jpeg,.png",
        headers: { Authorization: `Bearer ${auth.jwt}` },
      };
    },
    changeManagers(values) {
      this.selectedManagers = values.map(id =>
        this.employeeOptions.find(x => x.value === id)
      );
      this.$refs.managers.internalSearch = "";
    },
    reset() {
      this.editingImage = false;
      this.uploadingImage = false;
    },
    uploadSuccess() {
      this.uploadingImage = false;
      this.editingImage = false;
      this.fetchItem({ id: this.item.id });
    },
    sendData(file, xhr, formData) {
      this.uploadingImage = true;
      formData.append("upload_avatar[avatar]", file);
    },
    async submit() {
      const config = this.editMode ? { method: "POST", id: this.item.id } : {};
      const response = await this.itemSubmit(config);

      if (!this.success) return;

      const id = get(response, "data.user_id");
      if (this.editMode) {
        await wait(1500);
        this.closeModal({ success: true, id });
      } else {
        this.creatingId = id;
        this.showCreatingTimedOut = false;
        this.creatingSpinnerCounter = 0;
        this.checkIfCreated();
      }
    },
    async checkIfCreated() {
      // if we've checked 5x (5s) give up and show timed out message
      if (this.creatingSpinnerCounter > 10 && !this.showCreatingTimedOut) {
        this.showCreatingTimedOut = true;
        return;
      }
      try {
        const response = await teamUserApi.request({
          url: this.creatingId,
        });
        this.closeModal({ success: true, id: this.creatingId, created: true });
      } catch (error) {
        console.log(
          "New Employee not ready yet. Delaying 1 second and trying again."
        );
        this.creatingSpinnerTimeout = setTimeout(this.checkIfCreated, 1000);
        await wait(1000);
        this.showCreatingSpinner = true;
        this.creatingSpinnerCounter += 1;
      }
    },
    resetCreating() {
      this.showCreatingSpinner = false;
      this.creatingId = "";
      this.showCreatingTimedOut = false;
      this.creatingSpinnerCounter = 0;
      if (this.creatingSpinnerTimeout)
        clearTimeout(this.creatingSpinnerTimeout);
    },
    async deleteImage() {
      this.uploadingImage = true;
      await usersApi.request({
        method: "PUT",
        url: `/${this.item.id}/remove-avatar`,
      });
      await this.fetchItem({ id: this.item.id });
      this.editingImage = false;
      this.uploadingImage = false;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.dense-vertical) {
  margin-top: -5px;
  margin-bottom: -5px;

  & > div {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.profileImageArea {
  position: relative;
  height: 160px;
}

.imageHandle {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: 24px;
}

:deep(.vue-dropzone.dropzone) {
  min-height: 116px;
  height: 116px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: content-box;
  padding: 0;

  &.dz-drag-hover {
    border: 2px solid $primary;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    .dz-message {
      pointer-events: none;
    }
  }

  &.small {
    border: none;
  }

  .dz-message {
    margin: 0;
  }

  .dz-preview.dz-processing {
    height: 116px;
    width: 116px;
    margin: 0;
    .dz-image img {
      width: 100%;
      height: 100%;
    }
  }
}
.animateDragDrop {
  transition: translate;
  transform: translate(0, 0) scale(1);
  animation: drag-drop 4s ease-in-out infinite;
  color: transparent;
  &:before {
    color: $gray-4;
  }
  &:after {
    background: none;
    background-color: currentColor;
    opacity: 1;
    z-index: -1;
  }
}

$noClickBg: rgba($primary, 0);
$clickBg: rgba($primary, 0.25);

@keyframes drag-drop {
  0% {
    transform: translate(0, 0);
  }
  15% {
    transform: translate(15px, 30px);
  }
  21% {
    color: $noClickBg;
  }
  22% {
    color: $clickBg;
  }
  30% {
    transform: translate(15px, 30px);
  }
  50% {
    transform: translate(0, 0);
  }
  69% {
    color: $clickBg;
  }
  70% {
    color: $noClickBg;
  }
  100% {
    transform: translate(0, 0);
  }
}
</style>
