import Home from "../views/Home.vue";
import MyTasks from "../views/MyTasks";
import MyEscalations from "../views/MyEscalations";
import EmployeeOverview from "../views/EmployeeOverview";
import residentRoutes from "@/views/resident/routes";
import portfolioRoutes from "@/views/portfolio/routes";
import teamRoutes from "@/views/team/routes";
import reportRoutes from "@/views/reports/routes";
import docsRoutes from "@/views/docs/routes";
import OptOut from "@/views/OptOut.vue";

export default [
  {
    path: "/",
    name: "alerts",
    component: Home,
    meta: {
      pageTitle: "Home",
    },
  },
  {
    path: "/my-tasks/:service?",
    name: "MyTasks",
    component: MyTasks,
    meta: {
      pageTitle: "My Tasks",
    },
  },
  {
    path: "/my-escalations/:service?",
    name: "MyEscalations",
    component: MyEscalations,
    meta: {
      pageTitle: "My Escalations",
    },
  },
  {
    path: "/employee-overview/:id?",
    name: "EmployeeOverview",
    component: EmployeeOverview,
    meta: {
      pageTitle: "Employee Overview",
      parent: "team",
    },
  },
  ...residentRoutes,
  ...portfolioRoutes,
  ...teamRoutes,
  ...reportRoutes,
  ...docsRoutes,
  {
    path: "/opt-out",
    name: "opt-out",
    component: OptOut,
    meta: {
      layout: "signIn",
      noAuthCheck: true,
      disableAuthChangeRedirect: true,
      title: "Opt Out",
    },
  },
];
