<template>
  <div v-if="hasData" :class="{ clickableChart: hasData && modalProps.url }">
    <line-chart v-bind="props" />
    <widget-modal
      ref="modal"
      v-if="modalProps.url"
      v-bind="modalProps"
      :activator="`#${$attrs.id}`"
      width="700"
    />
  </div>
  <div v-else class="insufficientData">Insufficient Data</div>
</template>

<script>
import { nonEmpty } from "@cp/utils/itertees";

export default {
  props: {
    chartData: { type: Object, default: () => ({}) },
    options: { type: Object, default: () => ({}) },
    modalProps: { type: Object, default: () => ({}) },
    lineChartProps: { type: Object, default: () => ({}) },
  },
  computed: {
    hasData() {
      return (
        this.chartData.datasets &&
        this.chartData.datasets
          .map(ds => ds.data.map(d => d.y))
          .flat()
          .filter(nonEmpty).length > 1
      );
    },
    props() {
      return {
        height: 120,
        width: 350,
        showDetails: true,
        chartData: this.chartData,
        options: {
          showLines: false,
          plugins: {
            tooltip: {
              enabled: false,
            },
          },
          ...this.options,
        },
        ...this.lineChartProps,
      };
    },
  },
  methods: {
    openModal() {
      if (this.modalProps.url) {
        console.log(this.$refs.modal);
        this.$refs.modal.open();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.insufficientData {
  height: 120px;
  width: 450px;
  display: flex;
  background: $gray-1;
  align-items: center;
  justify-content: center;
}

.clickableChart {
  cursor: pointer;
  &:hover {
    background: rgba($primary, 0.2);
  }
  canvas {
    pointer-events: none;
  }
}
</style>
