import { Api } from "@cp/lib";
import { apps } from "@cp/constants/apps";
import { keyBy } from "@cp/utils/arrayUtils";
import { deepMerge } from "@cp/utils/objectUtils";

// which services CAN show up in Intel's UI
const serviceIds = ["team", "portfolio", "ingage", "intouch", "introduce"];

// start with services from constants/apps.js
const services = keyBy(
  apps.filter(({ serviceId }) => serviceIds.includes(serviceId)),
  "serviceId"
);

function primaryMetricColor(value) {
  return value < 65 ? "red" : "#008A09";
}

function secondaryMetricPrepend(value) {
  if (value >= 80) {
    return { icon: "mdi-emoticon-excited", color: "success" };
  } else if (value >= 65) {
    return { icon: "mdi-emoticon-neutral", color: "warning" };
  } else {
    return { icon: "mdi-emoticon-sad", color: "red" };
  }
}

const breakdownOrder = ["very_unlikely", "unlikely", "likely", "very_likely"];
const breakdownLabels = {
  very_unlikely: { text: "Very Unlikely", color: "#e40000" },
  unlikely: { text: "Unlikely", color: "#e86826" },
  neutral: { text: "Neutral", color: "#f0b323" },
  likely: { text: "Likely", color: "#6cc24a" },
  very_likely: { text: "Very Likely", color: "#008a09" }
};

function breakdownToPieChartValues(breakdown) {
  const keys = this.breakdownOrder.filter(key => breakdown[key]);
  const labels = keys.map(key => this.breakdownLabels[key].text);
  const backgroundColor = keys.map(key => this.breakdownLabels[key].color);
  const data = keys.map(key => breakdown[key]);
  return {
    labels,
    datasets: [
      {
        backgroundColor,
        data
      }
    ]
  };
}

deepMerge(services, {
  portfolio: {
    reportClient: new Api(
      `${process.env.VUE_APP_HAYSTACK_API_PATH}/en/v1/portfolio`
    ),
    doReports: true,
    entityTitle: "Employees",
    primaryMetricTitle: "???",
    primaryMetricColor,
    secondaryMetricPrepend,
    secondaryMetricAppend: "???",
    secondaryMetricTitle: "???",
    breakdownOrder,
    breakdownLabels,
    breakdownToPieChartValues
  },
  team: {
    reportClient: new Api(
      `${process.env.VUE_APP_HAYSTACK_API_PATH}/en/v1/team`
    ),
    doReports: true,
    entityTitle: "Employees",
    primaryMetricTitle: "???",
    primaryMetricColor,
    secondaryMetricPrepend,
    secondaryMetricAppend: "???",
    secondaryMetricTitle: "???",
    breakdownOrder,
    breakdownLabels,
    breakdownToPieChartValues
  },
  ingage: {
    reportClient: new Api(`${process.env.VUE_APP_ENGAGE_API_PATH}/en/v1/intel`),
    doReports: false,
    entityTitle: "Employees",
    primaryMetricTitle: "Engagement",
    primaryMetricColor,
    secondaryMetricPrepend,
    secondaryMetricAppend: "% Likely",
    secondaryMetricTitle: "Retention",
    breakdownOrder,
    breakdownLabels,
    breakdownToPieChartValues,
    defaultReportPayload: {
      filters: {
        confidential_only: null,
        reporting_group: []
      },
      structures: {
        date_interval: "month"
      },
      q_text: null
    }
  },
  intouch: {
    reportClient: new Api(
      `${process.env.VUE_APP_ENGAGE_API_PATH}/en/v1/intouch`
    ),
    doReports: true,
    entityTitle: "Residents",
    primaryMetricTitle: "Satisfaction",
    primaryMetricColor,
    secondaryMetricPrepend,
    secondaryMetricAppend: "% Likely",
    secondaryMetricTitle: "Renewal",
    breakdownOrder,
    breakdownLabels,
    breakdownToPieChartValues
  },
  introduce: {
    reportClient: new Api(
      `${process.env.VUE_APP_FIVER_API_PATH}/en/v1/introduce`
    ),
    doReports: true,
    entityTitle: "Onboarding",
    primaryMetricTitle: "Compliance",
    primaryMetricColor,
    secondaryMetricPrepend: () => {
      return "Total: ";
    },
    secondaryMetricAppend: false,
    secondaryMetricTitle: "Active Tasks",
    breakdownOrder: [
      "past_due",
      "unable_to_complete",
      "abandoned",
      "pending",
      "completed"
    ],
    breakdownLabels: {
      past_due: { text: "Past Due", color: "#E40000" },
      unable_to_complete: { text: "Can’t Complete", color: "#FBAF57" },
      abandoned: { text: "Abandoned", color: "#8B5DCB" },
      pending: { text: "Pending", color: "#FEDC08" },
      completed: { text: "Completed", color: "#008a09" }
    },
    breakdownToPieChartValues
  }
});

export default services;
