import { render, staticRenderFns } from "./ReasonsLeavingReport.vue?vue&type=template&id=e66adfa8&scoped=true&"
import script from "./ReasonsLeavingReport.vue?vue&type=script&lang=js&"
export * from "./ReasonsLeavingReport.vue?vue&type=script&lang=js&"
import style0 from "./ReasonsLeavingReport.vue?vue&type=style&index=0&id=e66adfa8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e66adfa8",
  null
  
)

export default component.exports