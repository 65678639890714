import List from "./List";
import Show from "./Show.vue";

export default [
  {
    path: "/reports",
    name: "reports",
    component: List,
  },
  {
    path: "/report/:id",
    name: "report",
    component: Show,
    props: true,
  },
];
