import { thisApp } from "@cp/constants";
import { ApiDataMixin } from "@cp/store/mixins";
import { get, getFirst } from "@cp/utils/objectUtils";
import { intersection } from "@cp/utils/arrayUtils";

const haystackIdentity = `${process.env.VUE_APP_HAYSTACK_API_PATH}/en/v1/identity/user`;

// ONLY needed when impersonating a user in haystack
const userIdentity = new ApiDataMixin({
  module: "haystackUser",
  baseUrl: haystackIdentity,
  clientConfig: {
    catchAuthErrors: false,
    prioritizeTokenOverCookie: true,
  },
  name: "identity",
  initialValue: {
    avatar: {},
    id: "",
    client_id: "",
    position_id: "",
    position: "",
    location_id: "",
    location: "",
    user_status_id: 1,
    first_name: "",
    last_name: "",
    email: "",
    personal_email: "",
    locale: "en",
    client_is_admin: false,
    service_access: [
      {
        service: "intel",
        status: "active",
        top_roll: "employee",
        bento: false,
        admin: false,
        managed_id: "",
      },
    ],
    reports: [],
    region: {
      text: "",
      value: "",
    },
    client: {
      id: "",
      name: "",
      slug: "",
      has_areas: false,
    },
    is_impersonating: false,
    is_on_vpn: false,
  },
});

const haystackUserIdPath = "auth.status.haystack_user";
const haystackUserNamePath = "auth.status.haystack_user_name";
userIdentity.add({
  getters: {
    isImpersonatingUser(state, getters, rootState) {
      return !!get(rootState, haystackUserIdPath);
    },
    userId(state, getters, rootState, rootGetters) {
      return get(rootState, haystackUserIdPath) || rootGetters["auth/userId"];
    },
    clientId(state, getters, rootState, rootGetters) {
      return (
        get(rootState, "auth.status.impersonating") ||
        rootGetters["auth/clientId"]
      );
    },
    userName(state, getters, rootState, rootGetters) {
      return get(rootState, haystackUserNamePath) || rootGetters["auth/userId"];
    },
    identity(state, getters, rootState) {
      const haystackUserIdentity = state.identity;
      const authIdentity = rootState.auth.identity;
      const haystackImpersonating = rootState.auth.status.haystack_user;
      return haystackImpersonating ? haystackUserIdentity : authIdentity;
    },
    clientServices(state, { identity }) {
      return identity.service_access
        .filter(({ status }) => status === "active")
        .map(({ service }) => service);
    },
    userServices(state, { identity, clientServices }) {
      const userServiceKeys = identity.service_access
        .filter(({ bento }) => bento)
        .map(({ service }) => service);
      const userClientServices = intersection(userServiceKeys, clientServices);
      return userClientServices;
    },
    avatarUrl({ identity }) {
      return getFirst(identity.avatar, ["thumb.url", "url"], false);
    },
    userRoles(state, { identity }) {
      const services = identity.service_access
        .filter(({ status }) => status === "active")
        .map(({ top_role }) => top_role);
      const userRoles = Object.values(services);
      return userRoles;
    },
    thisAppAccessLevel(state, { identity }) {
      const found = identity.service_access.find(
        ({ service }) => service === thisApp.serviceId
      );
      return found ? found.top_role : "employee";
    },
    clientHasService(state, { clientServices }) {
      return (keyOrArrayOfKeys, { mustHaveAll = false } = {}) => {
        if (typeof keyOrArrayOfKeys === "string") {
          return clientServices.includes(keyOrArrayOfKeys);
        } else if (Array.isArray(keyOrArrayOfKeys)) {
          return mustHaveAll
            ? keyOrArrayOfKeys.every(key => clientServices.includes(key))
            : clientServices.some(slug => keyOrArrayOfKeys.includes(slug));
        }
      };
    },
    userIs(
      { hasToken },
      { userRoles, thisAppAccessLevel },
      rootState,
      rootGetters
    ) {
      const userIsSwiftbunny = rootGetters["auth/userIsSwiftbunny"];
      let roleSlugs = [...userRoles];

      if (hasToken) {
        const temporalRoles = get(auth.tokenData, "scope.items.roles", []);
        roleSlugs.push(...temporalRoles);
      }

      return (keyOrArrayOfKeys, { mustHaveAll = false } = {}) => {
        if (userIsSwiftbunny) return true;
        if (thisAppAccessLevel === "client-admin") return true;
        if (typeof keyOrArrayOfKeys === "string") {
          return roleSlugs.includes(keyOrArrayOfKeys);
        } else if (Array.isArray(keyOrArrayOfKeys)) {
          return mustHaveAll
            ? keyOrArrayOfKeys.every(key => roleSlugs.includes(key))
            : roleSlugs.some(slug => keyOrArrayOfKeys.includes(slug));
        }
      };
    },
    managedId(state, { identity }) {
      const found = identity.service_access.find(
        ({ service }) => service === thisApp.serviceId
      );
      return found ? found.managed_id : "";
    },
  },
  actions: {
    check({ rootState, dispatch }) {
      if (get(rootState, haystackUserIdPath)) dispatch("fetchIdentity");
    },
  },
});

export default userIdentity.toVuex;
