<template>
  <div class="expandable-table__wrapper">
    <cp-h1>
      Employee Engagement Report

      <template v-if="devMode" #subtitle>
        <dev-mode-widget-links v-bind="{ instance }" />
      </template>

      <template #right>
        <cp-filters-menu :module="table" noun="Report" />
      </template>
    </cp-h1>

    <div class="card-row">
      <div class="card-row-fields">
        <div class="card-row-fields__container">
          <table class="card-row-fields__table">
            <tr>
              <td class="text-caption">Viewing By:</td>
              <td>
                <v-menu>
                  <template #activator="{on}">
                    <v-btn color="primary" class="mr-2" v-on="on" text small>
                      <v-icon left>mdi-chevron-down</v-icon>
                      {{ selectedScope.label }}
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item-group
                      v-model="selectedScopeIndex"
                      color="primary"
                    >
                      <v-list-item v-for="s in scopes" :key="s.value">
                        {{ s.label }}
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </td>
            </tr>
            <tr>
              <td class="pr-3 text-caption">
                Report Type:
              </td>
              <td>
                <v-menu>
                  <template #activator="{on}">
                    <v-btn v-on="on" color="primary" small text>
                      <v-icon left>mdi-chevron-down</v-icon>
                      {{ selectedReportType.label }}
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item-group
                      v-model="selectedReportTypeIndex"
                      color="primary"
                    >
                      <v-list-item v-for="rt in reportTypes" :key="rt.value">
                        <v-list-item-content>
                          {{ rt.label }}
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </td>
            </tr>
            <tr v-if="metrics.length">
              <td class="pr-3 text-caption">
                Primary Metric:
              </td>
              <td>
                <v-menu>
                  <template #activator="{on}">
                    <v-btn v-on="on" color="primary" small text>
                      <v-icon left>mdi-chevron-down</v-icon>
                      {{ selectedMetric.label }}
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item-group
                      v-model="selectedMetricIndex"
                      color="primary"
                    >
                      <v-list-item
                        v-for="metric in metrics"
                        :key="metric.value"
                        v-bind="metric.props || {}"
                      >
                        <v-list-item-content>{{
                          metric.label
                        }}</v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </td>
            </tr>
            <tr v-if="dateIntervals.length">
              <td class="pr-3 text-caption">
                Date Interval:
              </td>
              <td>
                <v-menu>
                  <template #activator="{on}">
                    <v-btn v-on="on" color="primary" small text>
                      <v-icon left>mdi-chevron-down</v-icon>
                      {{ selectedDateInterval.label }}
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item-group
                      v-model="selectedDateIntervalIndex"
                      color="primary"
                    >
                      <v-list-item v-for="di in dateIntervals" :key="di.value">
                        <v-list-item-content>{{
                          di.label
                        }}</v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </td>
            </tr>
            <tr>
              <td class="pr-3 text-caption">
                Date Range:
              </td>
              <td>
                <v-menu>
                  <template #activator="{on}">
                    <v-btn v-on="on" color="primary" small text>
                      <v-icon left>mdi-chevron-down</v-icon>
                      {{ selectedDateRange.label }}
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item-group
                      v-model="selectedDateRangeIndex"
                      color="primary"
                    >
                      <v-list-item v-for="dr in dateRanges" :key="dr.value">
                        <v-list-item-content>{{
                          dr.label
                        }}</v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </td>
            </tr>
            <tr>
              <td class="pr-3 text-caption">
                Show Indexes:
              </td>
              <td>
                <v-simple-checkbox
                  :value="showIndexesInTable"
                  @input="changeShowIndexesInTable"
                  color="primary"
                  class="ml-1"
                />
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="card-row-cards">
        <v-expand-transition>
          <card v-if="showTimelines">
            <table-timelines
              :xLabelFn="dateIntervalLabelFns[dateInterval]"
              :yLabelFn="metricLabelFormatMap[metric]"
              :showIndexes="showIndexesInTable && showIndexesInTimeline"
              v-bind="{
                instance,
                selected,
                selectedMetric,
                boldIndex: 0,
              }"
            />
          </card>
        </v-expand-transition>
        <v-expand-transition>
          <stat-cards v-if="!showTimelines" v-bind="{ statCards, table }" />
        </v-expand-transition>
      </div>
    </div>

    <v-card>
      <expandable-table
        v-bind="tableProps"
        :instance="instance"
        :scope="scope"
        :scopeChangeLoading="scopeChangeLoading"
        :scopeHeaders="scopeHeaders"
        @update:options="updateTableOptions"
        item-key="m_id"
        hide-default-footer
        :show-select="showSelect"
        v-model="selected"
      >
        <template #header.name>
          {{ selectedScope.label }}
        </template>
        <template #header.body>
          {{ selectedScope.label }}
        </template>

        <template #header.data-table-select="{props: {value}, on: {input}}">
          <v-btn
            @click="
              input(!value);
              setShowIndexesInTimeline(!value);
            "
            color="primary"
            icon
          >
            <cp-icon-stack
              v-if="value"
              :icons="['mdi-chart-timeline-variant', 'mdi-close']"
            />
            <v-icon v-else>mdi-chart-timeline-variant-shimmer</v-icon>
          </v-btn>
        </template>

        <template #item.data-table-select="{isSelected, select}">
          <v-btn @click.stop="select(!isSelected)" color="primary" icon>
            <cp-icon-stack
              v-if="isSelected"
              :icons="['mdi-chart-timeline-variant', 'mdi-minus']"
            />
            <cp-icon-stack
              v-else
              :icons="['mdi-chart-timeline-variant', 'mdi-plus']"
            />
          </v-btn>
        </template>

        <template #body.prepend v-if="showIndexesInTable">
          <index-rows
            v-bind="indexesRowsProps"
            @input="setShowIndexesInTimeline"
          />
        </template>

        <template #item.metric="{item, header}">
          {{ header.format ? header.format(item[metric]) : item[metric]
          }}{{ header.append ? header.append : "" }}
        </template>

        <template v-for="n of 20" :slot="`item.t_${n}`" slot-scope="x">
          <span :key="`${x.item.id}-timeline-${n}`">
            {{
              x.header.format
                ? x.header.format(get(x.item.timeline, x.header.path))
                : get(x.item.timeline, x.header.path)
            }}{{ x.header.append ? x.header.append : "" }}
          </span>
        </template>

        <template #item.question_response_summary="{item}">
          <question-response-summary v-bind="{ item, table }" />
        </template>

        <template #item.swift_bunny_index="{item}">
          {{ Math.round(get(item, ["sb_index", "agreement_importance"])) }}
        </template>
      </expandable-table>

      <v-pagination
        v-if="!noData && !scopeChangeLoading"
        class="mt-2 text-center"
        v-bind="paginationProps"
        @input="changePage"
      />
    </v-card>
  </div>
</template>

<script>
import Row from "vuetify/es5/components/VDataTable/Row";

import { onCpEvent } from "@cp/mixins";
import { snakeToSentenceCase } from "@cp/utils/stringUtils";

import {
  widgetMixin,
  tableMixin,
  multiScopeMixin,
  timelinesMixin,
  indexesMixin,
} from "@/widgets/mixins";

const questionScopesAndFilters = [
  "questions",
  "question_types",
  "topics",
  "factors",
  "indicators",
];

const scopeHeaders = {
  default: [
    { text: "", value: "name" },
    {
      text: "SBX",
      value: "swift_bunny_index",
      sortable: false,
      width: 90,
      align: "center",
      showIf: ({ scope }) =>
        ["position_categories", "surveys", "topics"].includes(scope),
    },
    {
      text: "Engagement",
      value: "agreement_importance",
      width: 140,
      align: "center",
    },
    {
      text: "Likely to Remain",
      value: "likely_to_remain_percentage",
      width: 150,
      align: "end",
    },
    {
      text: "Sent",
      value: "surveys_sent",
      width: 90,
      align: "end",
      hideIfScopeOrFilters: questionScopesAndFilters,
    },
    {
      text: "Started",
      value: "surveys_started",
      width: 100,
      align: "end",
      hideIfScopeOrFilters: questionScopesAndFilters,
    },
    {
      text: "Completed",
      value: "surveys_completed",
      width: 125,
      align: "end",
      hideIfScopeOrFilters: questionScopesAndFilters,
    },
    {
      text: "Response Rate",
      value: "response_rate",
      width: 150,
      align: "end",
      hideIfScopeOrFilters: questionScopesAndFilters,
    },
  ],
  questions: [
    { text: "", value: "name" },
    {
      text: "SBX",
      value: "swift_bunny_index",
      sortable: false,
      width: 90,
      align: "center",
    },
    {
      text: "Engagement",
      value: "agreement_importance",
      width: 140,
      align: "center",
    },
    { text: "Type", value: "question_type_slug" },
    { text: "Responses", value: "response_count" },
    {
      text: "Summary",
      value: "question_response_summary",
      sortable: false,
      align: "center",
    },
  ],
  indicators: [
    { text: "", value: "name" },
    {
      text: "SBX",
      value: "swift_bunny_index",
      sortable: false,
      width: 90,
      align: "center",
    },
    {
      text: "Engagement",
      value: "agreement_importance",
      width: 140,
      align: "center",
    },
    { text: "Responses", value: "response_count" },
    {
      text: "Summary",
      value: "question_response_summary",
      sortable: false,
      align: "center",
    },
  ],
  topics: [
    { text: "", value: "name" },
    {
      text: "SBX",
      sortable: false,
      value: "swift_bunny_index",
      width: 90,
      align: "center",
    },
    {
      text: "Engagement",
      value: "agreement_importance",
      width: 140,
      align: "center",
    },
    { text: "Responses", value: "response_count" },
    {
      text: "Summary",
      value: "question_response_summary",
      sortable: false,
      align: "center",
    },
  ],
  factors: [
    { text: "", value: "name" },
    {
      text: "Engagement",
      value: "agreement_importance",
      width: 140,
      align: "center",
    },
    { text: "Responses", value: "response_count" },
    {
      text: "Summary",
      value: "question_response_summary",
      sortable: false,
      align: "center",
    },
  ],
};

export default {
  components: { Row },
  mixins: [
    widgetMixin,
    tableMixin,
    indexesMixin,
    timelinesMixin,
    multiScopeMixin,
    onCpEvent("multiScopeMixin:scopeChanged", function() {
      this.resetSelected();
    }),
  ],
  computed: {
    scopeHeaders() {
      switch (this.reportType) {
        case "timeline":
          const firstHeaderFromScope = (scopeHeaders[this.scope] ||
            scopeHeaders.default)[0];
          return {
            default: [firstHeaderFromScope, ...this.timelineHeaders],
          };
        default:
          return scopeHeaders;
      }
    },
  },
  methods: { snakeToSentenceCase },
};
</script>

<style lang="scss" scoped>
tr.bodyPrependIndexes td {
  background: newColor("gray-2");
  height: 30px !important;
  white-space: nowrap;
  &,
  &:hover {
    cursor: default !important;
  }
}
tr.bodyPrependIndexes:nth-child(1) td {
  padding-top: 5px;
  border-bottom: none !important;
}
tr.bodyPrependIndexes:nth-child(2) td,
tr.bodyPrependIndexes:nth-child(1) td[rowspan="2"] {
  padding-bottom: 5px;
  border-bottom: 1px solid newColor("gray-4") !important;
}
</style>
