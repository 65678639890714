<template>
  <div>
    <cp-h1>
      <docs-nav />

      <span class="mx-2 text-caption grey--text text--darken-1">/</span>

      <v-menu>
        <template #activator="{on, attrs}">
          <v-btn v-bind="attrs" v-on="on" color="white" small>
            <template v-if="has.category">
              <span class="mr-2 text-caption grey--text text--darken-1"
                >Category:
              </span>
              {{ snakeToTitleCase($route.params.category) }}
            </template>
            <span v-else>Select Category</span>
            <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item
              v-for="({ label }, category) in categories"
              :key="category"
              :to="{ name: 'widget_docs', params: { category } }"
            >
              {{ label }}
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

      <span class="mx-2 text-caption grey--text text--darken-1">/</span>

      <v-menu>
        <template #activator="{on, attrs}">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="white"
            small
            :disabled="!has.category"
          >
            <template v-if="has.widget">
              <span class="mr-2 text-caption grey--text text--darken-1"
                >Widget:
              </span>
              {{ snakeToTitleCase($route.params.widget) }}
            </template>
            <span v-else>Select Widget</span>
            <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item
              v-for="{ url_key: widget } in currentCategoryWidgets"
              :key="widget"
              @click="
                $CpEvent.$emit('redirect', {
                  name: 'widget_docs',
                  params: {
                    category: $route.params.category,
                    widget,
                    id: $route.params.id,
                  },
                })
              "
            >
              {{ snakeToTitleCase(widget) }}
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

      <span class="mx-2 text-caption grey--text text--darken-1">/</span>

      <v-menu :close-on-content-click="false">
        <template #activator="{on, attrs}">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="white"
            small
            :disabled="!(has.category && has.widget)"
          >
            <template v-if="has.id">
              <span class="mr-2 text-caption grey--text text--darken-1"
                >{{ model }}:
              </span>
              {{ parentTitle }}
            </template>
            <span v-else>Select {{ model }}</span>
            <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-card class="searchCard">
          <v-text-field
            ref="searchField"
            v-model="qText"
            prepend-inner-icon="mdi-magnify"
            :loading="itemsLoading"
            clearable
            autofocus
            @input="searchSearch"
          />
          <Scrollbar ref="scrollbar" @ps-y-reach-end="loadMoreItems">
            <v-list>
              <v-list-item
                v-for="{ label, value: id } in items"
                :key="id"
                :to="{
                  name: 'widget_docs',
                  params: {
                    category: $route.params.category,
                    widget: $route.params.widget,
                    id,
                  },
                }"
              >
                {{ label }}
              </v-list-item>
            </v-list>
          </Scrollbar>
        </v-card>
      </v-menu>

      <template #right>
        <div class="d-flex align-center">
          <div class="text-caption">
            Viewing as:
          </div>
          <div class="mx-4" style="width:40px">
            <cp-profile-avatar :profile="identity" />
          </div>
          <div>{{ identity.first_name }} {{ identity.last_name }}</div>
        </div>
      </template>
    </cp-h1>

    <calculator
      v-if="has.all"
      :calculator="calculator"
      :params="$route.params"
      :defaultOpenResponsePanel="2"
      @loaded="loaded"
      ref="calculator"
    />

    <cp-h1>
      Rendering Widget:&nbsp;
      <span v-if="url">{{ widgetId }}</span>
      <template #subtitle>{{ url }}</template>
      <template #right>
        <v-btn @click="refresh" color="white" small>
          <v-icon left>mdi-reload</v-icon>
          Refresh
        </v-btn>
      </template>
    </cp-h1>
    <v-divider />
    <template v-if="url">
      <widget-v2 :url="url" />
    </template>
  </div>
</template>

<script>
import Scrollbar from "vue-custom-scrollbar";
import { mapState, mapGetters, mapActions } from "vuex";
import pluralize from "pluralize";

import { snakeToTitleCase } from "@cp/utils/stringUtils";
import { isEmpty, get } from "@cp/utils/objectUtils";
import { compact } from "@cp/utils/arrayUtils";
import { wait } from "@cp/utils/promiseUtils";
import { widgetPage, widgetCalculator } from "@/store/modules/widgets";
import { nonEmpty } from "carrot-patch-v2/src/utils/itertees";

export default {
  name: "WidgetsPage",
  components: { Scrollbar },
  data() {
    return {
      qText: "",
      currentCategory: null,
      currentWidget: null,
      responsePanels: [],
      url: "",
      calculator: widgetCalculator,
    };
  },
  computed: {
    ...mapState("widgets", ["categoriesLoading"]),
    ...mapGetters("widgets", { categories: "widgetCategories" }),
    ...mapState("widgets/search", ["items", "itemsLoading"]),
    ...mapState("widgets/item", ["item", "itemLoading"]),
    ...mapState("widgets/widget", ["widget", "widgetLoading"]),
    ...mapGetters("haystackUser", ["avatarUrl", "userServices", "identity"]),
    dataState() {
      return get(this.$store.state, this.calculator.p.s.stateKey, {});
    },
    meta() {
      return get(this.$store.state, this.calculator.p.s.meta);
    },
    loading() {
      return get(this.$store.state, this.calculator.p.s.loading);
    },
    model() {
      return this.currentCategory
        ? pluralize(this.currentCategory.search, 1)
        : "item";
    },
    currentCategoryWidgets() {
      if (!this.currentCategory) return [];
      return this.currentCategory.widgets || [];
    },
    computeSearchMenuHeight() {
      return { height: "400px" };
    },
    has() {
      const { category, widget, id } = this.$route.params;
      const all = [category, widget, id].every(nonEmpty);
      return {
        category: nonEmpty(category),
        widget: nonEmpty(widget),
        id: nonEmpty(id),
        all,
      };
    },
    parentTitle() {
      const full_name = get(this.meta, "parent.attributes.full_name");
      const first_and_last = compact([
        get(this.meta, "parent.attributes.first_name"),
        get(this.meta, "parent.attributes.last_name"),
      ]).join(" ");
      const name = get(this.meta, "parent.attributes.name");
      const title = get(this.meta, "parent.attributes.title");
      return [full_name, first_and_last, name, title].find(x => x);
    },
    data() {
      return this.dataState.data;
    },
    widgetId() {
      return get(this.meta, "widget.id");
    },
  },
  methods: {
    snakeToTitleCase,
    isEmpty,
    pluralize,
    ...mapActions("widgets", ["fetchCategories"]),
    ...mapActions("widgets/search", [
      "fetchItems",
      "searchSearch",
      "loadMoreItems",
    ]),
    ...mapActions("widgets/widget", ["fetchWidget"]),
    async loaded({ url }) {
      this.url = "";
      await this.$nextTick();
      await wait(100);
      await this.$nextTick();
      this.url = url;
    },
    refresh() {
      this.loaded({ url: this.url });
    },
  },
  async mounted() {
    await this.fetchCategories();
    await this.$nextTick();
    const { category, widget } = this.$route.params;
    if (!category) return;
    this.currentCategory = this.categories[category];
    widgetPage.setModel(this.currentCategory);
    this.fetchItems();

    if (!this.currentCategory || !widget) return;
    this.currentWidget = this.currentCategory.widgets.find(
      ({ url_key }) => url_key === widget
    );

    if (this.has.all) this.calculator.urlArgs = this.$route.params;
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-btn {
  text-transform: none;
}

.searchCard::v-deep {
  height: 50vh;
  overflow: hidden;
  position: relative;

  > .ps {
    display: block;
    height: 100% !important;
    max-height: 100% !important;
  }

  > .v-text-field + .ps {
    height: calc(100% - 64px) !important;
    max-height: calc(100% - 64px) !important;
  }

  .v-text-field {
    padding-top: 0;
    margin-top: 0;

    .v-input__slot {
      margin-bottom: 0;
    }
    .v-text-field__details {
      display: none;
    }
    .v-input__control {
      padding: 1rem;
    }
  }
}
</style>
