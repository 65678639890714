import { nonEmpty } from "@cp/utils/itertees";
import { get } from "@cp/utils/objectUtils";
import { flattenPath } from "@cp/utils/pathUtils";

export default function digAndFormatStat(
  data,
  {
    basePath,
    path,
    append = "",
    prepend = "",
    format = null,
    showIfEmpty = "N/A",
  } = {}
) {
  let value;
  if (typeof path === "function") {
    value = path(get(data, basePath) || {});
  } else {
    value = get(data, flattenPath(basePath, path));
  }
  if (nonEmpty(value)) {
    if (!isNaN(value)) value = value.toLocaleString();
    if (append) value += append;
    if (prepend) value = `${prepend}${value}`;
    if (format && typeof format === "function") value = format(value);
  } else if (showIfEmpty) {
    value = showIfEmpty;
  }
  return value;
}
