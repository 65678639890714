<template>
  <div>
    <cp-h1>
      <span class="mr-5">
        My Escalations
      </span>

      <cp-explain>
        These are tasks that have <span class="red--text">not</span> been
        completed on time, and need your attention.
      </cp-explain>

      <template v-if="!hasToken" #subtitle>
        <cp-breadcrumbs :items="breadcrumbs" />
      </template>
    </cp-h1>

    <v-card>
      <escalations-table />
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "MyEscalations",
  computed: {
    ...mapState("auth", ["hasToken"]),
    breadcrumbs() {
      return [
        {
          text: "Home",
          icon: "mdi-home",
          to: { name: "alerts" },
          exact: true,
        },
        {
          text: "My Escalations",
        },
      ];
    },
  },
};
</script>
