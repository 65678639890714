<template>
  <div>
    <cp-h1
      css-class="align-center"
      right-class="justify-space-between align-center flex-grow-1 ml-8"
    >
      Resident Surveys

      <template #subtitle>
        <cp-table-bulk-actions-menu
          v-if="selected.length"
          :bulk-actions="bulkActions"
          :selected="selected"
          noun="employees"
          x-small
        />
        <span v-else>Total: {{ meta.pagination.record_count }}</span>
      </template>

      <template #right>
        <cp-filters-menu :module="table" />
      </template>
    </cp-h1>

    <v-card>
      <v-data-table
        v-bind="tableProps"
        v-on="tableListeners"
        v-model="tableSelected"
        class="clickable-rows"
        @click:row="item => openModal({ id: item.m_id })"
        hide-default-footer
      >
        <template #item.score_danger="{ item }">
          <v-tooltip
            right
            transition="slide-x-reverse-transition"
            v-if="
              Number.isInteger(item.agreement_importance) &&
                item.agreement_importance < 70
            "
          >
            <template #activator="{ on }">
              <v-icon color="error" v-on="on">
                mdi-alert-circle
              </v-icon>
            </template>
            Score below 70
          </v-tooltip>
        </template>

        <template #item.created_at="{value}">
          {{ toMDY(value) }}
        </template>

        <template #item.agreement_importance="{ item }">
          <span v-if="!item.agreement_importance">––</span>
          <span v-else>
            {{ item.agreement_importance }}
          </span>
        </template>

        <template #item.full_name="{ item }">
          <template v-if="item.is_confidential">
            Confidential
            <v-icon small color="primary">
              mdi-shield-account-outline
            </v-icon>
          </template>
          <template v-else>
            {{ item.first_name }} {{ item.last_name }}
          </template>
        </template>

        <template #item.likely_to_renew="{ value }">
          <span>
            <v-tooltip
              right
              transition="slide-x-reverse-transition"
              max-width="250"
            >
              <template #activator="{ on }">
                <v-icon :color="emoticonProps(value).color" v-on="on">
                  {{ emoticonProps(value).icon }}
                </v-icon>
              </template>
              {{ emoticonProps(value).tooltip }}
            </v-tooltip>
          </span>
        </template>
      </v-data-table>

      <v-pagination
        class="mt-2 text-center pagination"
        v-bind="paginationProps"
        v-on="paginationListeners"
        :total-visible="15"
      />
    </v-card>
    <resident-survey-result />
  </div>
</template>

<script>
import {
  residentResponsesTable,
  residentSurveyResult,
} from "@/store/modules/residentResponses";
import { toMDY } from "@cp/utils/dateUtils";

export default {
  name: "Residents",
  mixins: [residentResponsesTable.mixin, residentSurveyResult.modalMixin],
  methods: {
    toMDY,
    emoticonProps(v) {
      const value = parseFloat(v);

      if (value >= 4)
        return {
          icon: "mdi-emoticon-excited",
          color: "success",
          tooltip: "Likely to Renew",
        };
      if (value <= 2)
        return {
          icon: "mdi-emoticon-sad",
          color: "error",
          tooltip: "Unlikely to Renew",
        };

      return {
        icon: "mdi-emoticon-neutral",
        color: null,
        tooltip: "Neutral to Renew",
      };
    },
  },
};
</script>
