<template>
  <v-card>
    <v-card-text>
      TODO -- Portfolio Service Issue Widget
    </v-card-text>
  </v-card>
</template>

<script>
import widgetMixin from "@/widgets/mixin";

export default {
  mixins: [widgetMixin],
};
</script>
