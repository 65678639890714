<template>
  <widget-v2 id="report-widget" :url="url" />
</template>

<script>
const reportUrlKeyMap = {
  "employee-engagement": "employee_survey_report",
  "employee-priorities": "employee_priority_report",
  "employee-action-items": "employee_action_item_report",
  "employee-tenure": "employee_tenure",
  "employee-turnover": "employee_turnover",
  "resident-summary": "resident_satisfaction_report",
  "resident-reasons-leaving": "resident_reasons_leaving_report",
  "task-compliance-report": "compliance_summary_report",
  "enrollment-report": "enrollment_summary_report",
};

export default {
  name: "ReportShow",
  computed: {
    url() {
      const urlKey = reportUrlKeyMap[this.$route.params.id];
      return `/reports/${urlKey}`;
    },
  },
};
</script>

<style scoped>
.loadingSheet {
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
