<template>
  <v-card>
    <v-card-text>
      <employee-data-validation-form
        :cantComplete.sync="cantComplete"
        @success="sendComplete"
      />

      <v-expand-transition>
        <div v-show="showNotesField">
          <div class="text--caption mt-4 mb-2">
            Please leave a note explaning why you cannot confirm the employee's
            data.
          </div>
          <v-textarea
            label="Notes on task"
            v-bind="fields.note.attrs"
            @input="fields.note.input"
            auto-grow
            outlined
            rows="5"
            row-height="25"
            dense
          />
        </div>
      </v-expand-transition>

      <cp-unhandled-errors :formConf="formConf" />

      <cp-form-modal-success-or-buttons
        v-if="showTaskButtons"
        :modalConf="modalConf"
        :formConf="formConf"
        :buttons="['cancel', 'markCantComplete']"
        @submit="sendCantComplete"
        :buttonConf="buttonConf"
        noun="Task"
        :success-message="successMessage"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { get, isEmpty } from "@cp/utils/objectUtils";
import { wait } from "@cp/utils/promiseUtils";

import { taskStatusForm } from "@/store/modules/taskStatusForm";
import { marigoldEmployee, taskStatusesById } from "@/store/modules/employee";

export default {
  name: "EmployeeDataValidation",
  mixins: [taskStatusForm.mixin, taskStatusForm.modalMixin],
  data() {
    return {
      cantComplete: false,
      formSubmitted: false,
      showTaskCompleteMessage: false,
      buttonConf: {
        markCantComplete: {
          text: "Submit Can't Complete",
          action: "submit",
          icon: taskStatusesById[3].icon,
          props: taskStatusesById[3].chipProps,
        },
      },
    };
  },
  computed: {
    showNotesField() {
      if (this.cantComplete) return true;
      const { unhandledErrors, ...otherErrors } = this.errors;
      return !isEmpty(unhandledErrors) || !isEmpty(otherErrors);
    },
    showTaskButtons() {
      return this.cantComplete || this.formSubmitted;
    },
    successMessage() {
      if (this.cantComplete) return "Task marked as Can't Complete";
      if (this.showTaskCompleteMessage)
        return "Employee data confirmed, and task completed!";
      else return "Task Updated!";
    },
  },
  methods: {
    async sendComplete() {
      this.setItemValue({ tasker_action: "complete" });
      const response = await this.itemSubmit({
        id: this.item.id,
        method: "PUT",
      });

      this.formSubmitted = true;

      if (!this.success) {
        this.$store.state.taskStatusForm.itemErrors = {
          "Task Update Error": "The task failed to update",
        };
        return;
      }

      this.showTaskCompleteMessage = true;
      await wait(1000);
      this.closeModal({ success: true });
    },
    async sendCantComplete() {
      this.setItemValue({ tasker_action: "cant_complete" });
      const response = await this.itemSubmit({
        id: this.item.id,
        method: "PUT",
      });

      if (this.success) {
        await wait(1000);
        this.closeModal({ success: true });
      }
    },
  },
  mounted() {
    const id = get(this.item, "on_behalf_of.id");
    this.$store.commit(`employee/${marigoldEmployee.keys.reset}`);
    this.$store.dispatch(marigoldEmployee.p.a.fetch, { id });
  },
};
</script>
