<template>
  <div v-if="statCards.length">
    <div class="mb-3 d-none d-md-flex Stats">
      <card
        v-for="(card, i) in cards"
        :key="i"
        v-bind="card"
        class="StatsCard flex-grow-1"
        :loading="loading"
        :set="(cardStats = stats[i] || [{}])"
      >
        <v-progress-circular v-if="loading" indeterminate />
        <template v-else>
          <div class="text-center">
            <div class="primaryValue">
              {{ cardStats[0].value }}
            </div>
            <div class="primaryLabel">
              <v-icon
                v-if="cardStats[0].icon"
                small
                v-bind="cardStats[0].iconProps || {}"
              >
                mdi-{{ cardStats[0].icon }}
              </v-icon>
              {{ cardStats[0].label }}
            </div>
          </div>
          <div v-if="cardStats.length > 1">
            <table>
              <tbody>
                <template
                  v-for="({ label, value, click }, i) of cardStats.slice(1)"
                >
                  <tr
                    :key="`${label}-${i}`"
                    @click="onStatClick(click)"
                    :class="{ clickable: click }"
                  >
                    <td class="stat-label text-right">{{ label }}:</td>
                    <td class="text-right">{{ value }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </template>
      </card>
    </div>
    <v-sheet
      class="responsiveStats px-1 py-1 d-md-none"
      :set="(card = cards[responsiveStats])"
    >
      <v-menu>
        <template #activator="{on, attrs}">
          <v-btn v-bind="attrs" v-on="on" color="primary" text class="px-1">
            <v-icon v-if="card.icon">mdi-{{ card.icon }}</v-icon>
            <cp-icon
              v-else-if="card.cpIcon"
              :icon="card.cpIcon"
              color="currentColor"
            />
            <span class="d-none d-md-inline-block ml-2">{{ card.title }}</span>
            <v-icon right class="ml-1">mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-for="(card, i) in cards"
            :key="i"
            @click="responsiveStats = i"
            class="stats-item"
          >
            <v-list-item-icon class="mr-2">
              <v-icon v-if="card.icon">mdi-{{ card.icon }}</v-icon>
              <cp-icon
                v-else-if="card.cpIcon"
                :icon="card.cpIcon"
                color="currentColor"
              />
            </v-list-item-icon>
            <v-list-item-content>
              {{ card.title }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <div class="responsiveStatsLiner">
        <Scrollbar
          class="responsiveStatsSideScroll"
          :settings="{ suppressScrollY: true }"
        >
          <span
            v-for="({ label, value }, i) in stats[responsiveStats]"
            :key="`${label}-${i}`"
            class="px-2"
          >
            <span class="responsiveLabel mr-1">{{ label }}</span>
            <span class="responsiveValue">{{ value }}</span>
          </span>
        </Scrollbar>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import Scrollbar from "vue-custom-scrollbar";

import { nonEmpty } from "@cp/utils/itertees";
import { get, exclude, isObject } from "@cp/utils/objectUtils";

import digAndFormatStat from "@/lib/digAndFormatStat";

export default {
  components: { Scrollbar },
  props: {
    table: { type: Object, required: true },
    statCards: { type: Array, default: () => [] },
  },
  data() {
    return {
      stats: [],
      responsiveStats: 0,
    };
  },
  computed: {
    meta() {
      return get(this.$store.state, this.table.p.s.meta);
    },
    loading() {
      return get(this.$store.state, this.table.p.s.loading);
    },
    cards() {
      return this.statCards.map(x => exclude(x, ["stats", "basePath"]));
    },
  },
  methods: {
    updateStats() {
      this.stats = this.statCards.map(
        ({ stats, basePath: cardBasePath = "summary_object" }) =>
          stats.reduce(
            (
              r,
              [
                label,
                path,
                {
                  basePath = cardBasePath,
                  click,
                  icon,
                  iconProps,
                  ...args
                } = {},
              ]
            ) => {
              let value = digAndFormatStat(this.meta, {
                basePath,
                path,
                ...args,
              });
              if (nonEmpty(value))
                r.push({ label, value, click, icon, iconProps });
              return r;
            },
            []
          )
      );
    },
    onStatClick(clickDef = {}) {
      if (!isObject(clickDef)) return;
      const { filter, to } = clickDef;
      if (filter) {
        const path = [...this.table.modulePath, this.table.k.updateFilter];
        this.$store.dispatch(path.join("/"), filter);
      } else if (to) this.$router.push(to);
    },
  },
  mounted() {
    if (nonEmpty(this.meta)) this.updateStats();
  },
  watch: {
    meta: {
      handler() {
        this.updateStats();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.Stats {
  gap: 12px;
}

.StatsCard {
  .primaryValue {
    font-size: 2.5rem;
    line-height: 3.5rem;
    font-weight: lighter;
  }
  &::v-deep {
    .v-card__title {
      flex-wrap: nowrap;
      white-space: nowrap;
      .heading-6 {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .v-card__text {
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      @media (max-width: 1024px) {
        padding-left: 4px;
        padding-right: 4px;
      }
    }
  }

  .stat-label {
    white-space: nowrap;
    padding-right: 6px;

    @media (max-width: 1024px) {
      padding-right: 4px;
    }
  }
}
.responsiveStats {
  white-space: nowrap;
  display: flex;
  justify-content: stretch;
  align-items: center;

  .responsiveStatsLiner {
    flex-grow: 1;
    overflow: hidden;
  }

  .responsiveLabel {
    color: $gray-4;
    font-weight: lighter;
  }
}

.responsiveStatsSideScroll {
  position: relative;
  margin: 0;
  width: 100%;
  padding: 10px 3px;
}

.stats-item .v-list-item__icon {
  color: #6b6b70;
  svg {
    color: inherit;
  }
}

.clickable {
  cursor: pointer;
  color: $primary;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    background: rgba(0, 0, 0, 0.05);
  }
}
</style>
