<template>
  <widget-modal v-bind="$attrs" v-on="$listeners">
    <template #activator="{on, attrs}">
      <v-btn v-on="on" color="primary" v-bind="{ ...attrs, ...btnProps }">
        <v-avatar v-if="btnCount && btnCount < 99" v-bind="colors" size="24px">
          {{ btnCount }}
        </v-avatar>
        <v-chip v-else-if="btnCount" v-bind="colors" small>{{
          btnCount
        }}</v-chip>
        <v-icon v-else-if="btnIcon" left>mdi-{{ btnIcon }}</v-icon>
        <cp-icon
          v-else-if="btnCpIcon"
          :icon="btnCpIcon"
          color="currentColor"
          small
        />
        {{ btnText }}
      </v-btn>
    </template>
  </widget-modal>
</template>

<script>
const colors = {
  primary: {
    color: "white",
    class: "primary--text",
  },
};
export default {
  name: "WidgetModalBtn",
  props: {
    btnProps: { type: Object, default: () => ({}) },
    btnText: { type: String, default: "Default Button Text" },
    btnIcon: { type: String, default: "" },
    btnCpIcon: { type: String, default: "" },
    btnCount: { default: null },
  },
  computed: {
    colors() {
      return colors[this.btnProps.color || "primary"];
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-avatar,
  .v-chip,
  .cp-icon--small {
    margin-left: -4px;
    margin-right: 8px;
  }

  .v-chip,
  .v-avatar {
    font-size: 12px;
    letter-spacing: 0px;
  }

  .v-chip {
    padding-left: 5px;
    padding-right: 5px;
  }
}
</style>
