<template>
  <div>
    <cp-h1>
      <docs-nav />

      <span class="mx-2 text-caption grey--text text--darken-1">/</span>

      <v-menu>
        <template #activator="{on, attrs}">
          <v-btn v-bind="attrs" v-on="on" color="white" small>
            <span class="mr-2 text-caption grey--text text--darken-1"
              >Stat:
            </span>
            {{ $route.params.type }}
            <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item
              v-for="type in cardTypes"
              :key="type"
              @click="$router.push({ name: 'stat_docs', params: { type } })"
            >
              {{ type }}
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </cp-h1>

    <v-row>
      <v-col cols="4">
        <v-card>
          <v-card-title>
            Display Stat:
          </v-card-title>

          <v-card-text>
            <template v-for="(field, i) in form">
              <v-text-field
                :key="`field-${i}`"
                v-if="field.component === 'v-text-field'"
                v-bind="field.props"
                v-on="field.listeners"
              />

              <v-checkbox
                :key="`checkbox-${i}`"
                v-if="field.component === 'v-checkbox'"
                v-bind="field.props"
                v-on="field.listeners"
              />

              <array-field
                :key="`array-${i}`"
                v-if="field.component === 'array-field'"
                v-bind="field.props"
                v-on="field.listeners"
              />

              <two-dimensional-array-field
                :key="`2d-array-${i}`"
                v-if="field.component === 'two-dimensional-array-field'"
                v-bind="field.props"
                v-on="field.listeners"
              />
            </template>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="4">
        <v-card>
          <v-card-title>response.meta.summary_object.stats</v-card-title>
          <v-card-text>
            <VueJsonPretty :data="jsonData" @node-click="handleNodeClick" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";

import { statTypes } from "@/components/stats/statTypes.js";

export default {
  components: { VueJsonPretty },
  data() {
    const selectedType = statTypes[this.$route.params.type];
    const formData = {};
    Object.entries(selectedType).forEach(([fieldName, prop]) => {
      if (prop.default) {
        formData[fieldName] =
          typeof prop.default === "function" ? prop.default() : prop.default;
      } else {
        formData[fieldName] = prop.type();
      }
    });
    return {
      selectedType,
      formData,
    };
  },
  computed: {
    cardTypes() {
      return Object.keys(statTypes);
    },
    form() {
      const selectedType = statTypes[this.$route.params.type];
      const $vm = this;
      return Object.entries(selectedType).map(([fieldName, prop]) => {
        const props = {
          label: fieldName,
          value: this.formData[fieldName],
        };
        if (!props.value && prop.required) {
          props["error-messages"] = "required";
        }

        const listeners = {
          input(v) {
            $vm.formData[fieldName] = v;
          },
        };

        switch (prop.type) {
          case String:
            return {
              component: "v-text-field",
              props,
              listeners,
            };
          case Boolean:
            return {
              component: "v-checkbox",
              props,
              listeners: { change: listeners.input },
            };
          case Number:
            return {
              component: "v-text-field",
              props: {
                type: "number",
                ...props,
              },
              listeners: {
                input(v) {
                  $vm.formData[fieldName] = parseFloat(v) || 0;
                },
              },
            };
          case Array:
            const component = Array.isArray(this.formData[fieldName][0])
              ? "two-dimensional-array-field"
              : "array-field";
            const labels = Array.isArray(this.formData[fieldName][0])
              ? ["Label", "Value"]
              : ["Text"];
            return {
              component,
              props: {
                ...props,
                labels,
              },
              listeners,
            };
        }
      });
    },
    jsonData() {
      return {
        type: this.$route.params.type,
        ...this.formData,
      };
    },
  },
  methods: {
    handleNodeClick(arg) {
      if (!navigator) return;
      const content = arg.content;
      navigator.clipboard.writeText(content).then(
        function() {
          console.log(`Coppied to clipboard: ${content}`);
        },
        function(err) {
          console.error("Copy to clipboard failed!");
          console.error(err);
        }
      );
    },
  },
  mounted() {
    if (!this.cardTypes.includes(this.$route.params.type)) {
      this.$router.push({
        name: "stat_docs",
        params: { type: this.cardTypes[0] },
      });
    }
  },
};
</script>
