<template>
  <cp-toolbar-module-modal
    :modalConf="modalConf"
    v-on="$listeners"
    :width="(archetype && archetype.width) || 600"
    root-slot
  >
    <template #title>
      {{ archetype && archetype.title }}
    </template>

    <div v-if="loading" class="loading">
      <v-card>
        <v-card-text class="d-flex align-center justify-center pa-6">
          <v-progress-circular :size="180" color="#0070a8" indeterminate>
            <cp-icon icon="swift_bunny_logo" color="#0070a8" xLarge />
          </v-progress-circular>
        </v-card-text>
      </v-card>
    </div>
    <template v-else-if="archetype">
      <component :is="archetype.component" />
    </template>
  </cp-toolbar-module-modal>
</template>
<script>
import { get } from "@cp/utils/objectUtils";

import { taskStatusForm } from "@/store/modules/taskStatusForm";

import EmployeeDataValidation from "./archetypes/EmployeeDataValidation.vue";
import SimpleCompletion from "./archetypes/SimpleCompletion.vue";
import AcknowledgeGivenNotice from "./archetypes/AcknowledgeGivenNotice.vue";

const archetypeMap = {
  2: {
    component: SimpleCompletion,
    title: "Task Status",
  },
  3: {
    component: EmployeeDataValidation,
    title: "Confirm data for employee",
  },
  4: {
    component: AcknowledgeGivenNotice,
    title: "Acknowledge employee gave notice",
  },
};

export default {
  name: "TaskModal",
  mixins: [
    taskStatusForm.mixin,
    taskStatusForm.modalMixin,
    taskStatusForm.routedModalMixin,
  ],
  computed: {
    archetype() {
      const statusId = get(this.item, "user_task_progress_status_id", 2);
      if (statusId === 1 || this.item.completed_at) return archetypeMap[2];
      const id = get(this.item, "task_archetype.value") || 2;
      return archetypeMap[id];
    },
  },
};
</script>
