<template>
  <tr>
    <td></td>

    <td v-if="reportType === 'timeline'">
      <v-btn
        @click.stop="$emit('input', !showIndexesInTimeline)"
        color="primary"
        icon
      >
        <cp-icon-stack
          :icons="[
            'mdi-chart-timeline-variant',
            showIndexesInTimeline ? 'mdi-minus' : 'mdi-plus',
          ]"
        />
      </v-btn>
    </td>

    <td class="text-no-wrap">
      <div class="index-value">
        <cp-icon icon="swift_bunny_logo" color="#0070a8" x-small />
        Swift Bunny Index
      </div>
      <div class="index-value">
        <v-icon color="primary" x-small>mdi-home-city</v-icon>
        Company Index
      </div>
    </td>

    <td v-for="(td, i) in tds" :key="`index-cell-${i}`" v-bind="td.props">
      <div class="index-value">
        {{ td.sb }}
      </div>
      <div class="index-value">
        {{ td.company }}
      </div>
    </td>
  </tr>
</template>

<script>
import { get } from "@cp/utils/objectUtils";

export default {
  props: {
    reportType: { type: String },
    showIndexesInTimeline: { type: Boolean },
    tds: { type: Array },
  },
  methods: { get },
};
</script>

<style lang="scss" scoped>
tr td {
  background: newColor("gray-3");
  white-space: nowrap;
  border-bottom: 1px solid newColor("gray-4") !important;

  &,
  &:hover {
    cursor: default !important;
  }

  .index-value {
    line-height: 32px;
  }
}
</style>
