<template>
  <div v-if="tasks.length" style="width: 100%">
    <div class="text-caption">Statuses</div>
    <div class="bar-container">
      <cp-explain
        v-for="status in statuses"
        :key="status.id"
        root-slot
        nudge-bottom="20"
      >
        <template #activator="{on}">
          <div
            :key="status.id"
            v-on="on"
            @click="$emit('filter', status.id)"
            :style="status.style"
            class="status-bar"
          >
            {{ status.tasks.length }}
          </div>
        </template>
        <v-card class="text-center">
          <div class="px-2">{{ status.status.title }}</div>
          <v-divider />
          <div class="text-caption px-2">click to filter</div>
        </v-card>
      </cp-explain>
    </div>
  </div>
</template>

<script>
import pluralize from "pluralize";
import { taskStatusesById } from "@/store/modules/employee";

const statusOrder = [1, 2, 3, 6, 4, 5];

export default {
  name: "TasksComplianceBar",
  props: { tasks: { type: Array, default: () => [] } },
  computed: {
    statuses() {
      return statusOrder.reduce((r, id) => {
        const tasks = this.tasks.filter(x => x.user_task_progress_status_id === id);
        const prct = (tasks.length / this.tasks.length) * 100;
        const status = taskStatusesById[id];
        if (tasks.length)
          r.push({
            id,
            status,
            tasks,
            style: {
              width: `${prct}%`,
              backgroundColor: status.color,
              color: status.chipProps.textColor,
            },
          });
        return r;
      }, []);
    },
  },
  methods: { pluralize },
};
</script>

<style lang="scss" scoped>
.bar-container {
  width: 100%;
  height: 12px;
  display: flex;

  .status-bar {
    height: 100%;
    cursor: pointer;
    text-align: center;
    font-size: 9px;
    line-height: 12px;
  }
}
</style>
