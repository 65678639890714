import { render, staticRenderFns } from "./SummaryYesNo.vue?vue&type=template&id=501cff70&scoped=true&"
import script from "./SummaryYesNo.vue?vue&type=script&lang=js&"
export * from "./SummaryYesNo.vue?vue&type=script&lang=js&"
import style0 from "./SummaryYesNo.vue?vue&type=style&index=0&id=501cff70&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "501cff70",
  null
  
)

export default component.exports