<template>
  <div>
    <cp-h1>
      Career Development

      <template #subtitle>
        {{ data.employee_details.full_name }}
      </template>
    </cp-h1>

    <v-card class="modal-conetnet-card">
      <v-list class="py-0">
        <template v-for="(enrollment, i) in enrollments">
          <v-divider v-if="i" :key="`divider-${enrollment.id}`" />
          <v-list-item
            :key="enrollment.id"
            :input-value="filter.plan === enrollment.plan_id"
            class="py-2"
          >
            <v-row class="align-center">
              <v-col cols="auto" style="width: 145px">
                <v-chip small outlined color="primary" class="pl-0">
                  <v-avatar>
                    <v-icon small>
                      {{ enrollmentStatus(enrollment).icon }}
                    </v-icon>
                  </v-avatar>
                  <span>
                    {{ enrollmentStatus(enrollment).text }}
                  </span>
                </v-chip>
              </v-col>
              <v-col cols="4">
                {{ enrollment.plan.title }}
              </v-col>
              <v-col cols="auto">
                <tasks-progress-pie :tasks="enrollment.tasks" />
              </v-col>
              <v-col class="align-self-start">
                <tasks-status-bar
                  :tasks="enrollment.tasks"
                  @filter="
                    obj => setFilter({ ...obj, plan: enrollment.plan_id })
                  "
                />
              </v-col>
              <v-col v-if="enrollments.length > 1" cols="auto" align="right">
                <v-btn
                  small
                  @click="toggleFilter('plan', enrollment.plan_id)"
                  :color="filter.plan === enrollment.plan_id ? '' : 'primary'"
                >
                  <v-icon left>
                    {{
                      filter.plan === enrollment.plan_id
                        ? "mdi-filter-off"
                        : "mdi-filter"
                    }}
                  </v-icon>
                  Filter tasks
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item>
        </template>
      </v-list>
    </v-card>
    <cp-h1
      right-class="align-center"
      hide-back-button
      disable-set-document-title
    >
      Tasks List
      <template #subtitle>
        <span v-if="filterCount">
          showing {{ filtered.tasks.length }} / {{ data.tasks.length }}
        </span>
        <span v-else> {{ data.tasks.length }} total </span>
      </template>
      <template #right>
        <v-text-field
          v-model="search"
          prepend-icon="mdi-magnify"
          placeholder="Search for task"
          class="mr-3"
        />

        <v-btn v-if="filterCount" class="mr-3" small @click="setFilter({})">
          <v-icon class="mr-2">mdi-filter-off</v-icon>
          Clear Filters
        </v-btn>

        <v-menu
          v-if="
            canFilterBy.plan ||
              canFilterBy.progressStatus ||
              canFilterBy.complianceStatus
          "
          :close-on-content-click="false"
        >
          <template #activator="{on, attrs}">
            <v-btn v-bind="attrs" v-on="on" color="primary" small>
              <v-icon left>mdi-filter</v-icon>
              <v-badge
                v-if="filterCount"
                :content="filterCount"
                class="buttonCount"
              >
                Filter
              </v-badge>
              <span v-else>Filter</span>
            </v-btn>
          </template>

          <v-list dense>
            <template v-if="canFilterBy.plan">
              <v-subheader>Plans</v-subheader>
              <v-list-item
                v-for="enrollment in enrollments"
                :key="`plan-${enrollment.plan_id}`"
                @click="toggleFilter('plan', enrollment.plan_id)"
                :input-value="filter.plan === enrollment.plan_id"
              >
                <v-list-item-icon>
                  <v-icon v-if="filter.plan === enrollment.plan_id">
                    mdi-filter-off-outline
                  </v-icon>
                  <v-icon v-else>
                    mdi-clipboard-text-multiple
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ enrollment.plan.title }}
                </v-list-item-title>
              </v-list-item>
            </template>

            <v-subheader>Progress</v-subheader>
            <v-list-item
              v-for="status in filtered.progressStatuses"
              :key="`ps-${status.id}`"
              @click="toggleFilter('progressStatus', status.id)"
              :input-value="filter.progressStatus === status.id"
            >
              <v-list-item-icon>
                <v-icon v-if="filter.status === status.id">
                  mdi-filter-off-outline
                </v-icon>
                <v-icon v-else>
                  mdi-filter-outline
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ status.title }}
              </v-list-item-title>
            </v-list-item>
            <template v-if="canFilterBy.complianceStatus">
              <v-subheader>Status</v-subheader>
              <v-list-item
                v-for="status in filtered.complianceStatuses"
                :key="`cs-${status.id}`"
                @click="toggleFilter('complianceStatus', status.id)"
                :input-value="filter.complianceStatus === status.id"
              >
                <v-list-item-icon>
                  <v-icon v-if="filter.status === status.id">
                    mdi-filter-off-outline
                  </v-icon>
                  <v-icon v-else>
                    mdi-filter-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ status.title }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>
    </cp-h1>
    <v-card class="modal-content-card" elevation="20">
      <v-data-table
        :headers="headers"
        :items="filtered.tasks"
        :loading="loading"
        :no-data-text="
          `0 tasks ${
            filterCount ? `match those ${filterCount} filters` : 'found'
          }`
        "
        hide-default-footer
        disable-pagination
      >
        <template #item.completion="{ item }">
          <task-progress-cell v-bind="{ item }" />
        </template>

        <template v-slot:item.explanation="{ item }">
          <task-status-summary-cell v-bind="{ item }" />
        </template>

        <template v-slot:item.task.title="{ item }">
          <cp-explain
            :close-on-content-click="false"
            max-width="550"
            offset-x
            root-slot
          >
            <template #activator="{on}">
              <span v-on="on">
                {{ item.task.title }}
              </span>
            </template>
            <v-card>
              <v-card-title>{{ item.task.title }}</v-card-title>
              <v-card-text>
                <p>
                  <strong>Description:</strong>
                  <div v-html="item.task.description" class="displayHTML"></div>
                </p>
                <p>
                  <strong>Schedule:</strong>
                  {{ item.task.schedule }}
                </p>
                <p>
                  <strong>Plan using this task:</strong>
                  {{ item.plans.map(x => x.title).join(", ") }}
                </p>
                <p>
                  <strong>Assign To:</strong>
                  {{ item.assignees.map(x => x.name).join(", ") }}
                </p>
              </v-card-text>
            </v-card>
          </cp-explain>
        </template>

        <template #item.notes="{ item }">
          <cp-explain v-if="item.notes.length" root-slot min-width="400">
            <template #activator="{on, attrs}">
              <v-btn v-bind="attrs" v-on="on" icon color="primary">
                <v-icon>mdi-message</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>Notes:</v-card-title>
              <v-card-text>
                <v-alert
                  v-for="(note, i) in item.notes"
                  :key="`note-${i}`"
                  color="blue-grey"
                  class="note"
                  text
                >
                  <div class="note-attribution text-caption">
                    {{ note.user }} at {{ toTIME(note.created_at) }}
                    {{ toMDY(note.created_at) }}
                  </div>
                  {{ note.note }}
                </v-alert>
              </v-card-text>
            </v-card>
          </cp-explain>
        </template>

        <template #item.due_by_date="{item}">
          {{ toMDY(item.due_by_date) }}
        </template>
      </v-data-table>
    </v-card>
    <div style="height:10vh;"></div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import cloneDeep from "clone-deep";

import {
  completedTypes,
  completedTypeObj,
  enrollmentStatusObj,
} from "@/store/modules/employee";

import {
  progressStatusesOrder as PSO,
  complianceStatusesOrder as CSO,
} from "@/store/modules/tasks";
import { toMDY, toTIME } from "@cp/utils/dateUtils";
import { get, pick, filterObject } from "@cp/utils/objectUtils";
import { compact, unique } from "@cp/utils/arrayUtils";

const headers = [
  {
    text: "✓",
    sortable: false,
    value: "completion",
    width: 60,
  },
  {
    text: "Task",
    value: "task.title",
    sortable: true,
  },
  {
    text: "Status",
    sortable: false,
    value: "explanation",
    cellClass: "text-no-wrap",
    width: "40%",
  },
  {
    text: "Due",
    value: "due_by_date",
    cellClass: "text-no-wrap",
    width: 80,
    sortable: true,
  },
  // {
  //   text: "Status",
  //   value: "status",
  //   width: "30%",
  //   class: "align-center",
  // },
  {
    text: "Notes",
    value: "notes",
    sortable: false,
    width: 80,
  },
];

const DEFAULT_FILTER = {
  plan: "",
  progressStatus: 0,
  complianceStatus: 0,
};

export default {
  data() {
    return {
      completedTypes,
      completedTypeObj,
      headers,
      search: "",

      filter: cloneDeep(DEFAULT_FILTER),
    };
  },
  computed: {
    ...mapState("employee", {
      data: "employeeFiverReport",
      errors: "employeeFiverReportErrors",
      loading: "employeeFiverReportLoading",
    }),
    ...mapGetters("employee", {
      enrollments: "employeeEnrollments",
    }),
    title() {
      return `Career Development - ${get(
        this.data,
        "employee_details.full_name"
      )}`;
    },
    breadcrumbs() {
      return [
        {
          to: { name: "team" },
          text: "Team",
          exact: true,
        },
        {
          to: { name: "teamEmployeeDetails", params: this.$route.params },
          text: get(this.data, "employee_details.full_name"),
          exact: true,
        },
        {
          disabled: true,
          text: "Career Development",
        },
      ];
    },

    filtered() {
      let tasks = this.data.tasks;

      if (this.filter.plan)
        tasks = tasks.filter(t =>
          t.plans.map(x => x.id).includes(this.filter.plan)
        );

      if (this.search)
        tasks = tasks.filter(t =>
          t.task.title.toLowerCase().includes(this.search.toLowerCase())
        );

      const psIds = compact(
        unique(tasks.map(x => x.user_task_progress_status_id))
      );
      const csIds = compact(
        unique(tasks.map(x => x.user_task_compliance_status_id))
      );

      const progressStatuses = PSO.filter(x => psIds.includes(x.id));
      const complianceStatuses = CSO.filter(x => csIds.includes(x.id));

      if (this.filter.progressStatus)
        tasks = tasks.filter(
          t => t.user_task_progress_status_id === this.filter.progressStatus
        );

      if (this.filter.complianceStatus)
        tasks = tasks.filter(
          t => t.user_task_compliance_status_id === this.filter.complianceStatus
        );

      return { tasks, progressStatuses, complianceStatuses };
    },
    canFilterBy() {
      return {
        plan: this.enrollments.length > 1,
        progressStatus: true,
        complianceStatus: this.filtered.complianceStatuses.length,
      };
    },
    filterCount() {
      return [
        this.filter.plan,
        this.filter.progressStatus,
        this.filter.complianceStatus,
      ].filter(x => x).length;
    },
  },
  methods: {
    toMDY,
    toTIME,
    ...mapActions("employee", ["fetchEmployeeFiverReport"]),
    ...mapMutations("employee", ["resetEmployeeFiverReport"]),
    setFilter(obj) {
      const newValue = pick(obj, Object.keys(filterObject(this.canFilterBy)));
      Object.assign(this.filter, DEFAULT_FILTER, newValue);
    },
    toggleFilter(k, v) {
      if (!this.canFilterBy[k]) return;
      if (this.filter[k] === v) {
        this.filter[k] = DEFAULT_FILTER[k];
      } else {
        this.filter[k] = v;
      }
    },
    enrollmentStatus({ enrollment_status_id }) {
      return enrollmentStatusObj[enrollment_status_id] || {};
    },
  },
  mounted() {
    this.fetchEmployeeFiverReport({ id: this.$route.params.id }).then(() => {
      document.title = this.title;
    });
  },
  beforeRouteLeave(to, from, next) {
    this.resetEmployeeFiverReport();
    next();
  },
};
</script>

<style lang="scss" scoped>
.status-prefix {
  display: inline-block;
  width: 90px;
}

.buttonCount::v-deep .v-badge__badge {
  box-shadow: inset 0 0 0 1.5px #fff, 0 0 4px rgba(0, 0, 0, 0.8);
}

.v-list-item--active {
  background-color: lighten($primary, 55%);
}

.note {
  margin-top: 22px;

  & + .note {
    margin-top: 30px;
  }

  &:before {
    border-radius: 0 4px 4px 4px;
  }

  .note-attribution {
    position: absolute;
    left: 0;
    bottom: 100%;
    border-radius: 5px 5px 0 0;
    background: #eceff1;
    padding: 0 10px;
    box-shadow: inset 0 -2px 2px -2px rgba(0, 0, 0, 0.3);
  }
}
</style>
