<template>
  <v-card>
    <v-card-text>
      <table>
        <tbody>
          <tr>
            <td>Employee:</td>
            <td>{{ employee.full_name }}</td>
          </tr>
          <tr>
            <td>Last Day:</td>
            <td>{{ toMDY(employee.last_day) }}</td>
          </tr>
          <tr>
            <td>Position:</td>
            <td>{{ employee.position_name }}</td>
          </tr>
          <tr>
            <td>Location:</td>
            <td>{{ employee.location_name }}</td>
          </tr>
          <tr>
            <td>Termination Type:</td>
            <td>
              {{ terminationType }}
            </td>
          </tr>
          <tr v-if="employee.termination_reason_note">
            <td colspan="2">Reason for leaving:</td>
          </tr>
          <tr v-if="employee.termination_reason_note">
            <td colspan="2" class="value" style="white-space: pre-wrap;">
              <!-- div required because whitespace and vuetr -->
              <div>{{ employee.termination_reason_note }}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </v-card-text>
    <template
      v-if="employee.personal_email || employee.email || employee.cell_phone"
    >
      <v-divider />
      <v-card-text>
        <div class="text-subtitle mb-5">
          Contact Methods:
        </div>
        <table>
          <tbody>
            <tr></tr>
            <tr v-if="employee.email">
              <td>Work Email:</td>
              <td class="text-truncate">
                <a :href="`mailto:${employee.email}`">{{ employee.email }}</a>
              </td>
            </tr>
            <tr v-if="employee.personal_email">
              <td>Personal Email:</td>
              <td class="text-truncate">
                <a :href="`mailto:${employee.personal_email}`">{{
                  employee.personal_email
                }}</a>
              </td>
            </tr>
            <tr v-if="employee.cell_phone">
              <td>Phone:</td>
              <td>
                <a :href="`tel:${employee.cell_phone}`">{{
                  employee.cell_phone
                }}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </v-card-text>
    </template>
    <v-divider />
    <v-card-text>
      <div class="text-subtitle mb-5">
        Please update their last day and personal email, if needed:
      </div>

      <modify-before-acknowledge-notice-form
        :cantComplete.sync="cantComplete"
        @success="sendComplete"
      />

      <v-expand-transition>
        <div v-show="showNotesField">
          <div class="text--caption mt-4 mb-2">
            Please leave a note explaning why you cannot confirm the employee's
            data.
          </div>
          <v-textarea
            label="Notes on task"
            v-bind="fields.note.attrs"
            @input="fields.note.input"
            auto-grow
            outlined
            rows="5"
            row-height="25"
            dense
          />
        </div>
      </v-expand-transition>

      <cp-unhandled-errors :formConf="formConf" />

      <cp-form-modal-success-or-buttons
        v-if="showTaskButtons"
        :modalConf="modalConf"
        :formConf="formConf"
        :buttons="['cancel', 'markCantComplete']"
        @submit="sendCantComplete"
        :buttonConf="buttonConf"
        noun="Task"
        :success-message="successMessage"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { get, isEmpty } from "@cp/utils/objectUtils";
import { toMDY } from "@cp/utils/dateUtils";
import { wait } from "@cp/utils/promiseUtils";

import { taskStatusForm } from "@/store/modules/taskStatusForm";
import {
  acknowledgeNoticeForm,
  taskStatusesById,
  terminationTypeObj,
} from "@/store/modules/employee";

export default {
  name: "AcknowledgeGivenNotice",
  mixins: [taskStatusForm.mixin, taskStatusForm.modalMixin],
  data() {
    return {
      terminationTypeObj,
      cantComplete: false,
      formSubmitted: false,
      showTaskCompleteMessage: false,
      buttonConf: {
        markCantComplete: {
          text: "Submit Can't Complete",
          action: "submit",
          icon: taskStatusesById[3].icon,
          props: taskStatusesById[3].chipProps,
        },
      },
    };
  },
  computed: {
    employee() {
      return get(this.item, "on_behalf_of");
    },
    terminationType() {
      const id = get(this.employee, "termination_categories_id");
      if (!id) return "N/A";
      return get(terminationTypeObj, [id, "text"], "");
    },
    showNotesField() {
      if (this.cantComplete) return true;
      const { unhandledErrors, ...otherErrors } = this.errors;
      return !isEmpty(unhandledErrors) || !isEmpty(otherErrors);
    },
    showTaskButtons() {
      return this.cantComplete || this.formSubmitted;
    },
    successMessage() {
      if (this.cantComplete) return "Task marked as Can't Complete";
      if (this.showTaskCompleteMessage)
        return "Employee notice confirmed, and task completed!";
      else return "Task Updated!";
    },
  },
  methods: {
    toMDY,
    async sendComplete() {
      this.setItemValue({ tasker_action: "complete" });
      const response = await this.itemSubmit({
        id: this.item.id,
        method: "PUT",
      });

      this.formSubmitted = true;

      if (!this.success) {
        this.$store.state.taskStatusForm.itemErrors = {
          "Task Update Error": "The task failed to update",
        };
        return;
      }

      this.showTaskCompleteMessage = true;
      await wait(1000);
      this.closeModal({ success: true });
    },
    async sendCantComplete() {
      this.setItemValue({ tasker_action: "cant_complete" });
      const response = await this.itemSubmit({
        id: this.item.id,
        method: "PUT",
      });

      if (this.success) {
        await wait(1000);
        this.closeModal({ success: true });
      }
    },
  },
  mounted() {
    this.$store.commit(`employee/${acknowledgeNoticeForm.keys.reset}`);

    const id = this.employee.id;
    const { last_day, personal_email } = this.item.on_behalf_of;
    this.$store.state.employee.acknowledgeNotice = {
      id,
      last_day,
      personal_email,
    };
    this.$store.commit(`employee/${acknowledgeNoticeForm.keys.input}`, {
      last_day,
      personal_email,
    });
  },
};
</script>

<style lang="scss" scoped>
table {
  white-space: nowrap;
  max-width: 100%;

  td:first-child,
  td:nth-child(3) {
    font-weight: 200;
    font-size: 0.85rem;
    color: $gray-4;
  }

  td:first-child {
    padding-right: 10px;
  }

  td.value,
  td:nth-child(2) {
    font-weight: 500;
    font-size: 1em;
  }
}
</style>
