<template>
  <div class="d-flex align-center" v-bind="$attrs">
    <v-alert color="success" text class="ma-0">
      <slot />
    </v-alert>

    <div class="arrow"></div>
    <img :src="devImg" class="devBunny" />
  </div>
</template>

<script>
const i =
  "https://assets.swiftbunny.com/public-assets/app-intel/dashboard_preview_assets/";
export default {
  name: "WIPMessage",
  data() {
    return {
      devImg: i + "dev_bunny.png",
    };
  },
};
</script>

<style scoped>
.arrow {
  width: 0;
  height: 0;
  border-width: 12px 0 12px 12px;
  border-color: transparent transparent transparent #008a09;
  border-style: solid;
  opacity: 0.12;
}

.devBunny {
  width: 140px;
  height: 140px;
}
</style>
