<template>
  <div>
    <cp-h1>
      Resident Comments

      <template #subtitle>
        {{ parent.name }}
      </template>

      <template #right>
        <cp-filters-menu :module="table" noun="Report" />
      </template>
    </cp-h1>
    <v-card class="pb-1" :loading="tableProps.loading">
      <div v-for="{ id, label, comments } in commentsByMonth" :key="id">
        <div class="text-h6 px-4">{{ label }}</div>
        <div
          v-for="{ c_id, s_name, q_body, comment, date } in comments"
          :key="id + c_id"
          class="commentsInMonth"
        >
          <v-alert icon="mdi-message-outline" color="blue-grey" text>
            <div class="commentQuestion text-caption">
              {{ s_name }}: {{ q_body }}
            </div>
            {{ comment }}

            <div class="commentDate text-caption">
              {{ date }}
            </div>
          </v-alert>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { widgetMixin, tableMixin } from "@/widgets/mixins";
import { parse, toMDY } from "@cp/utils/dateUtils";
import { findByObj } from "@cp/utils/arrayUtils";

export default {
  mixins: [widgetMixin, tableMixin],
  computed: {
    title() {
      return `${this.parent.name} - Resident Comments`;
    },
    commentsByMonth() {
      const months = this.data.reduce((r, comment) => {
        const label = parse(comment.created_at).format("MMMM YYYY");
        const foundMonth = findByObj(r, { label });
        if (foundMonth) {
          foundMonth.comments.push({
            c_id: comment.m_id,
            s_name: comment.survey_name,
            q_body: comment.body,
            comment: comment.text_value_1,
            date: toMDY(comment.created_at),
          });
        } else {
          r.push({
            label,
            ym: parse(comment.created_at).format("YYYY-MM"),
            id: `${label}-comments`,
            comments: [
              {
                c_id: comment.m_id,
                s_name: comment.survey_name,
                q_body: comment.body,
                comment: comment.text_value_1,
                date: toMDY(comment.created_at),
              },
            ],
          });
        }
        return r;
      }, []);
      return months.sort((a, b) => {
        if (a.ym > b.ym) return -1;
        if (a.ym < b.ym) return 1;
        return 0;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.text-h6 {
  background: rgba($gray-3, 0.25);
  margin-bottom: 14px;
  padding: 4px 16px;
}

.commentsInMonth {
  padding: 0 16px;
}

::v-deep .v-alert--text {
  padding-top: 4px;
  padding-bottom: 0;
}

.commentQuestion {
  color: $gray-4;
  margin-bottom: 8px;
}

.commentDate {
  color: $gray-4;
  text-align: right;

  margin-top: 8px;
}
</style>
