<template>
  <v-card v-if="clientHasService('introduce')">
    <v-card-text class="d-flex align-center">
      <v-icon x-large>mdi-account-star</v-icon>
      <div class="text-no-wrap ml-4">
        <div class="font-weight-bold">Career Development</div>
        <div v-if="counts.length === 0">No tasks yet.</div>
        <div v-else class="d-flex align-center">
          <span
            class="count"
            v-for="{ key, label, value, props: { color, icon } = {} } in counts"
            :key="key"
            :class="{ [`${color}--text`]: color }"
          >
            <v-icon :color="color" small>mdi-{{ icon }}</v-icon>
            <strong>{{ value }}</strong>
            {{ pluralize(properize(label), value) }}
          </span>
        </div>
      </div>
      <div class="mx-10 flex-grow-1">
        <div class="planProgress" v-for="plan in data" :key="plan.m_id">
          <progress-bar
            :resolved="plan.completed"
            :total="plan.task_count"
            :unresolved="null"
          >
            <template #title>{{ plan.name }}</template>
          </progress-bar>
        </div>
      </div>
      <v-btn
        color="primary"
        :to="{
          name: 'employeeCareerDevelopment',
          params: { id: parent.id.split(',')[0] },
        }"
      >
        View Details
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import widgetMixin from "@/widgets/mixin";

export default {
  mixins: [widgetMixin],
  computed: {
    ...mapGetters("haystackUser", ["thisAppAccessLevel"]),
    counts() {
      const counts = [
        ["Plans", "summary_object.plans"],
        ["Tasks", "summary_object.tasks"],
        [
          "Overdue Tasks",
          "summary_object.past_due_and_incomplete",
          { color: "error", icon: "alert" },
        ],
      ];
      return counts
        .map(([label, path, props], key) => ({
          key,
          label,
          props,
          value: this.get(this.meta, path),
        }))
        .filter(({ value }) => value);
    },
  },
};
</script>

<style lang="scss" scoped>
.count {
  display: flex;
  align-items: center;
  gap: 4px;
  + .count {
    margin-left: 10px;
  }
}

.planProgress {
  + .planProgress {
    margin-top: 5px;
  }
}
</style>
