<template>
  <v-list-item dense @click="click">
    <v-list-item-icon>
      <v-tooltip bottom>
        <template #activator="{on}">
          <v-icon v-on="on" :color="icon.color || 'gray-3'">{{
            icon.icon
          }}</v-icon>
        </template>
        {{ icon.title }}
      </v-tooltip>
    </v-list-item-icon>
    <v-list-item-content>
      <div class="task-content" :class="{ useMobileFormat }">
        <v-tooltip bottom>
          <template #activator="{on}">
            <div class="date" v-on="on">
              <span v-if="useMobileFormat">
                {{ parse(task.due_by_date).format("MMM D") }}
              </span>
              <h4 v-else class="text-uppercase d-inline-block mr-9">
                {{ parse(task.due_by_date).format("MMM") }}
                <br />
                <span class="day">{{
                  parse(task.due_by_date).format("D")
                }}</span>
              </h4>
            </div>
          </template>

          Task was created on {{ toRelativeVerbose(task.created_at) }}<br />
          Last notification sent on {{ toRelativeVerbose(task.alert_date) }}
        </v-tooltip>
        <div class="text">
          <v-list-item-title>
            {{ task.task.title }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <span class="text-uppercase">{{ task.task.task_type }}</span>
            <span v-if="task.plans && task.plans.length">
              &nbsp;(
              {{ task.plans.map(p => p.title).join(", ") }}
              )</span
            >
          </v-list-item-subtitle>
        </div>
      </div>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { parse, toRelativeVerbose } from "@cp/utils/dateUtils";
import { progress_statuses, compliance_statuses } from "@/store/modules/tasks";
export default {
  props: { task: Object, forceMobile: Boolean },
  computed: {
    icon() {
      if (this.task.completed_at) return icons[1];
      return progress_statuses[this.task.user_task_progress_status_id].icon;
    },
    useMobileFormat() {
      return this.$vuetify.breakpoint.mobile || this.forceMobile;
    },
  },
  status() {
    if (this.item.user_task_progress_status_id === 4)
      return compliance_statuses[this.item.user_task_compliance_status_id];
    else return progress_statuses[this.item.user_task_progress_status_id];
  },
  methods: {
    parse,
    toRelativeVerbose,
    click() {
      this.$store.dispatch("tasks/openItemFormModal", { id: this.task.id });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item__icon {
  align-self: center;
}
.task-content {
  display: flex;
  flex-direction: row;
  align-items: center;

  .date {
    text-align: center;
    color: $gray-4;
    min-width: 80px;

    .day {
      font-size: 1.2rem;
      line-height: 1rem;
    }
  }

  &.useMobileFormat {
    flex-direction: column;
    align-items: flex-start;

    .date {
      text-align: left;
    }
  }
}
</style>
