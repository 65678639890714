<template>
  <span v-if="status">
    <v-icon :color="color" :title="title" large>{{ status.icon }}</v-icon>
  </span>
</template>

<script>
import { progressStatusesById, abandoned } from "@/store/modules/tasks";

export default {
  props: {
    item: { type: Object, required: true },
  },
  computed: {
    status() {
      return progressStatusesById[this.item.user_task_progress_status_id];
    },
    color() {
      if (
        this.item.user_task_progress_status_id === 4 &&
        this.item.user_task_compliance_status_id === 4
      )
        return abandoned.color;
      return this.status.color;
    },
    title() {
      if (
        this.item.user_task_progress_status_id === 4 &&
        this.item.user_task_compliance_status_id === 4
      )
        return abandoned.title;
      return this.status.title;
    },
  },
};
</script>
