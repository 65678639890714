import { deepMerge } from "@cp/utils/objectUtils";
import services from "./services";

function fetch({ serviceKey, slug, filters }) {
  const service = services[serviceKey];
  const slugFilter = slug ? { filters: { [this.slugFilterKey]: [slug] } } : {};
  const payload = deepMerge(
    {},
    this.apiParams,
    service.defaultReportPayload || {},
    { filters },
    slugFilter
  );
  return service.reportClient.authorize().get("dashboard", payload);
}

const columns = {
  alert: {
    text: "",
    value: "alert",
    width: "20px",
    sortable: false
  },
  name: {
    text: "Name",
    value: "name",
    sortable: false
  },
  score: {
    text: "Score",
    value: "todo",
    sortable: false
  },
  ingage: {
    text: "Employee Engagement",
    value: "serviceScore",
    path: "metrics.ingage.primary",
    service: "ingage",
    sortable: false
  },
  intouch: {
    text: "Resident Satisfaction",
    value: "serviceScore",
    path: "metrics.intouch.primary",
    service: "intouch",
    sortable: false
  },
  introduce: {
    text: "Compliance",
    value: "serviceScore",
    path: "metrics.introduce.primary",
    service: "introduce",
    sortable: false
  },
  turnover: {
    text: "Turnover Cost",
    value: "metrics.introduce.tertiary",
    service: "introduce",
    sortable: false
  },
  property: {
    text: "Property",
    value: "todo",
    sortable: false
  },
  hireDate: {
    text: "Hire Date",
    value: "todo",
    sortable: false
  },
  residentName: {
    text: "Resident",
    value: "todo",
    sortable: false
  },
  unitNumber: {
    text: "Unit #",
    value: "todo",
    sortable: false
  },
  totalCost: {
    text: "Total Cost",
    value: "todo",
    sortable: false
  },
  position: {
    text: "Position",
    value: "todo",
    sortable: false
  },
  noticeDate: {
    text: "Notice Date",
    value: "todo",
    sortable: false
  },
  daysVacant: {
    text: "Days Vacant",
    value: "todo",
    sortable: false
  }
};

function tableProps(nextLevel) {
  return {
    headers: [
      columns.alert,
      {
        text: nextLevel ? nextLevel.label : "Name",
        value: "name"
      },
      columns.ingage,
      columns.intouch,
      columns.introduce
    ]
  };
}

const company = {
  id: "company",
  icon: "mdi-office-building",
  label: "Company",
  apiParams: {
    structures: {
      scope: "clients"
    }
  },
  slugFilterKey: "", // can't pass a slug at company level
  fetch,
  tabs: false,
  tableProps
};

const district = {
  id: "district",
  icon: "mdi-map-marker-radius",
  label: "District",
  apiParams: {
    structures: {
      scope: "areas"
    }
  },
  slugFilterKey: "areas",
  apiModel: "geographical_groups",
  fetch,
  tabs: false,
  tableProps
};

export const withoutDistrict = [
  company,
  {
    id: "region",
    icon: "mdi-map-marker-radius",
    label: "Region",
    apiParams: {
      structures: {
        scope: "regions"
      }
    },
    slugFilterKey: "regions",
    apiModel: "geographical_groups",
    fetch,
    tabs: [
      {
        text: "Properties",
        payload: {},
        tableProps: tableProps()
      },
      {
        text: "Employees",
        payload: {},
        service: ["ingage", "introduce"],
        tableProps: {
          headers: [
            columns.alert,
            columns.score,
            columns.name,
            columns.property,
            columns.hireDate
          ]
        }
      },
      {
        text: "Resident Surveys",
        payload: {},
        service: "intouch",
        tableProps: {
          headers: [
            columns.alert,
            columns.score,
            {
              text: "Survey",
              value: "todo"
            },
            columns.property
          ]
        }
      },
      {
        text: "Turnover Cost",
        payload: {},
        service: "introduce",
        tableProps: {
          headers: [
            columns.totalCost,
            columns.position,
            columns.property,
            columns.noticeDate,
            columns.daysVacant
          ]
        }
      }
    ]
  },
  {
    id: "property",
    icon: "mdi-home-group",
    label: "Property",
    apiParams: {
      structures: {
        scope: "locations"
      }
    },
    slugFilterKey: "locations",
    apiModel: "locations",
    fetch,
    tableProps,
    tabs: [
      {
        text: "Employees",
        payload: {},
        service: ["ingage", "introduce"],
        tableProps: {
          headers: [
            columns.alert,
            columns.score,
            columns.name,
            columns.hireDate
          ]
        }
      },
      {
        text: "Resident Surveys",
        payload: {},
        service: "intouch",
        tableProps: {
          headers: [
            columns.alert,
            columns.score,
            {
              text: "Survey",
              value: "todo"
            },
            columns.residentName,
            columns.unitNumber
          ]
        }
      },
      {
        text: "Turnover Cost",
        payload: {},
        service: "introduce",
        tableProps: {
          headers: [
            columns.totalCost,
            columns.position,
            columns.noticeDate,
            columns.daysVacant
          ]
        }
      }
    ]
  }
];

export const withDistrict = [company, district, ...withoutDistrict];
