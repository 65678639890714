<template>
  <div>
    <cp-h1>
      Employee Priority Report

      <template v-if="devMode" #subtitle>
        <dev-mode-widget-links v-bind="{ instance }" />
      </template>

      <template #right>
        <cp-filters-menu :module="table" noun="Report" />
      </template>
    </cp-h1>

    <div class="card-row">
      <div class="card-row-cards">
        <stat-cards v-bind="{ statCards, table }" />
      </div>
    </div>

    <v-card>
      <v-data-table
        v-bind="tableProps"
        @update:options="updateTableOptions"
        hide-default-footer
      >
        <template #header.previous_year_score
          >{{ previousYear }} score</template
        >
        <template #header.score>{{ year }} score</template>

        <template #item.previous_year_score="{item}">
          <span
            v-if="
              item.previous_year_results &&
                item.previous_year_results.agreement_importance
            "
          >
            {{
              Math.round(
                parseFloat(item.previous_year_results.agreement_importance)
              ) || "N/A"
            }}
          </span>
          <span v-else>N/A</span>
        </template>
        <template #item.direction="{item}">
          <span
            v-if="
              item.agreement_importance &&
                item.previous_year_results &&
                item.previous_year_results.agreement_importance
            "
            :set="
              (prev = Math.round(
                parseFloat(item.previous_year_results.agreement_importance)
              ))
            "
          >
            <span
              :set="(cur = Math.round(parseFloat(item.agreement_importance)))"
            >
              <v-icon v-if="prev < cur" color="success">
                mdi-trending-up
              </v-icon>
              <v-icon v-else-if="prev === cur">
                mdi-trending-neutral
              </v-icon>
              <v-icon v-else-if="prev > cur" color="error">
                mdi-trending-down
              </v-icon>
            </span>
          </span>
        </template>
        <template #item.score="{item}">
          {{ Math.round(parseFloat(item.agreement_importance)) || "N/A" }}
        </template>
      </v-data-table>

      <v-pagination
        v-if="!noData"
        class="mt-2 text-center"
        v-bind="paginationProps"
        @input="changePage"
      />
    </v-card>
  </div>
</template>

<script>
import { get } from "@cp/utils/objectUtils";

import { widgetMixin, tableMixin } from "@/widgets/mixins";

export default {
  mixins: [widgetMixin, tableMixin],
  data() {
    return {
      headers: [
        { text: "Priority", value: "name" },
        {
          text: "",
          value: "previous_year_score",
          width: 140,
          sortable: false,
          class: "text-end",
          cellClass: "text-end",
        },
        {
          text: "",
          value: "direction",
          width: 24,
          sortable: false,
          class: "px-0",
          cellClass: "px-0",
        },
        { text: "", value: "score", width: 140 },
      ],
    };
  },
  computed: {
    year() {
      return this.filterParams.year || this.parseDate().format("YYYY");
    },
    previousYear() {
      return parseInt(this.year) - 1;
    },
  },
};
</script>
