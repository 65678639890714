<template>
  <div class="d-flex">
    <likert
      :values="item.response_summary"
      :average="item.likelihood"
      type="likelihood"
    />
  </div>
</template>

<script>
export default {
  props: ["item"],
};
</script>
