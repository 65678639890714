<template>
  <div>
    <card
      v-if="current.length"
      :title="'Current ' + pluralize('Lease', current.length)"
      icon="account-cash"
    >
      TODO -- current lease survey responses
    </card>
    <card
      v-if="former.length"
      :title="'Former ' + pluralize('Lease', former.length)"
      icon="account-cash"
    >
      TODO -- former leases survey responses
    </card>
  </div>
</template>

<script>
import { partition } from "carrot-patch-v2/src/utils/arrayUtils";
import widgetMixin from "@/widgets/mixin";

export default {
  mixins: [widgetMixin],
  computed: {
    currentFormerLeases() {
      const [current, former] = partition(this.data, x => x.is_current);
      return { current, former };
    },
  },
};
</script>
