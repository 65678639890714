import { nonEmpty } from "@cp/utils/itertees";

export default function(value) {
  const _value = parseFloat(value);
  if (!nonEmpty(_value))
    return {
      icon: "mdi-help-circle",
      color: "gray-6",
      tooltip: "Unknown Renewal",
    };
  if (_value >= 4)
    return {
      icon: "mdi-emoticon-excited",
      color: "success",
      tooltip: "Likely to Renew",
    };
  else if (_value <= 2)
    return {
      icon: "mdi-emoticon-sad",
      color: "error",
      tooltip: "Unlikely to Renew",
    };
  return {
    icon: "mdi-emoticon-neutral",
    color: "gray-6",
    tooltip: "Neutral to Renew",
  };
}
