<template>
  <div>
    <cp-h1>
      Team

      <template #rightOfTitle>
        <cp-table-bulk-actions-menu
          v-if="selected.length"
          :bulk-actions="bulkActions"
          :selected="selected"
          noun="employees"
          x-small
        />
        <v-btn v-else @click="openModal" color="primary" class="ml-6" small>
          <v-icon left>mdi-plus</v-icon>
          Add Employee
        </v-btn>
      </template>

      <template #right>
        <cp-filters-menu :module="table" />
      </template>
    </cp-h1>

    <list-stats-cards v-bind="{ statCards, table }" />

    <v-card>
      <v-data-table
        v-bind="tableProps"
        v-on="tableListeners"
        v-model="tableSelected"
        class="clickable-rows"
        @click:row="
          item =>
            $router.push({
              name: 'teamEmployeeDetails',
              params: { id: item.id },
            })
        "
        show-select
        hide-default-footer
      >
        <template #header.position="{header}">
          <cp-table-header-subqueryable
            :header="header"
            :table="table"
            submoduleRefKey="employeesPositions"
          />
        </template>

        <template #header.location="{header}">
          <cp-table-header-subqueryable
            :header="header"
            :table="table"
            submoduleRefKey="employeesLocations"
          />
        </template>

        <template #item.employee_name="{item}">
          <div class="d-flex align-center">
            <div style="width:32px" class="mr-2">
              <cp-profile-avatar :profile="item" thumb />
            </div>
            <span>{{ item.full_name }}</span>
          </div>
        </template>

        <template #item.position="{value}">
          {{ value.label }}
        </template>

        <template #item.location="{value}">
          {{ value.label }}
        </template>

        <template #item.managers="{value}">
          {{ value.map(x => x.label).join(", ") }}
        </template>
      </v-data-table>

      <v-pagination
        v-if="!noData"
        class="mt-2 text-center"
        v-bind="paginationProps"
        v-on="paginationListeners"
      />
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { teamTable } from "@/store/modules/team";
import { marigoldEmployee } from "@/store/modules/employee";
import colorsAndIcons from "@/lib/colorsAndIcons";
import { roundPrct } from "@/lib/formats";

export default {
  mixins: [
    teamTable.mixin,
    teamTable.filtersMixin,
    marigoldEmployee.modalMixin,
  ],
  computed: {
    ...mapGetters("haystackUser", {
      has: "clientHasService",
      role: "thisAppAccessLevel",
    }),
    statCards() {
      const statCards = [
        {
          title: "Summary",
          icon: "account-group",
          basePath: ["summary_object", "general"],
          stats: [
            [
              "Employees",
              "employees",
              { icon: "account", iconProps: { color: "success" } },
            ],
            ["Positions", "positions"],
            ["Offices", "locations"],
          ],
        },
      ];

    
      if (this.has("introduce"))
        statCards.push({
          title: "Plans",
          cpIcon: "fiver",
          basePath: ["summary_object", "development"],
          stats: [
            [
              "Compliance",
              "compliance",
              {
                icon: "bullseye-arrow",
                iconProps: { color: "orange" },
                format: roundPrct,
              },
            ],
            ["Employees", "employees"],
            ["Pending", "pending"],
            ["Past due", "past_due"],
            ["Can't Complete", "cant_complete"],
          ],
        });

      if (!["property_manager", "maintenance_manager"].includes(this.role)) {
        statCards.push({
          title: "Managers",
          icon: "account-star",
          basePath: ["summary_object", "team"],
          stats: [
            [
              "Regional",
              "regional_managers",
              {
                icon: colorsAndIcons.region.icon,
                iconProps: { color: colorsAndIcons.region.color },
              },
            ],
            [
              "Property",
              "property_managers",
              {
                icon: colorsAndIcons.property.icon,
                iconProps: { color: colorsAndIcons.property.color },
              },
            ],
            ["Maintenance", "maintenance_managers"],
          ],
        });
      }

      return statCards;
    },
  },
};
</script>
