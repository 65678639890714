<template>
  <v-card tile>
    <v-card-text class="pt-4" style="max-width: 600px">
      <table class="summary-table">
        <tbody>
          <tr>
            <td>Task</td>
            <td>{{ item.task.title }}</td>
          </tr>
          <tr v-if="item.task.description">
            <td colspan="2">
              <div>Description:</div>
              <div v-html="item.task.description" class="displayHTML"></div>
            </td>
          </tr>
          <tr
            v-if="
              item.on_behalf_of && item.on_behalf_of.hasOwnProperty('full_name')
            "
          >
            <td>For:</td>
            <td>
              <cp-explain>
                <template #activator="{on}">
                  <v-chip v-on="on" class="text-no-wrap" small>
                    {{ item.on_behalf_of.full_name }}
                  </v-chip>
                </template>

                <table :set="(obo = item.on_behalf_of)" class="summary-table">
                  <tr v-if="obo.last_day">
                    <td>Last Day:</td>
                    <td>{{ obo.last_day }}</td>
                  </tr>
                  <tr v-else-if="obo.start_date">
                    <td>First Day:</td>
                    <td>{{ obo.start_date }}</td>
                  </tr>
                  <tr v-if="obo.position_name">
                    <td>Position:</td>
                    <td>{{ obo.position_name }}</td>
                  </tr>
                  <tr v-if="obo.location_name">
                    <td>Location:</td>
                    <td>{{ obo.location_name }}</td>
                  </tr>
                  <tr v-if="obo.email">
                    <td>Email:</td>
                    <td>
                      <a :href="`mailto:${obo.email}`">{{ obo.email }}</a>
                    </td>
                  </tr>
                  <tr v-if="obo.personal_email">
                    <td>Personal Email:</td>
                    <td>
                      <a :href="`mailto:${obo.personal_email}`">{{
                        obo.personal_email
                      }}</a>
                    </td>
                  </tr>
                  <tr v-if="obo.cell_phone">
                    <td>Phone:</td>
                    <td>
                      <a :href="`tel:${obo.cell_phone}`">{{
                        obo.cell_phone
                      }}</a>
                    </td>
                  </tr>
                </table>
              </cp-explain>
            </td>
          </tr>
          <tr v-if="!item.assignees || !item.assignees.length">
            <td colspan="2">
              <span class="red--text">
                Task is unassigned
              </span>
            </td>
          </tr>
          <tr v-else>
            <td>Assigned to</td>
            <td>
              <span v-if="item.assignees.length === 1">
                {{ item.assignees[0].name }}
              </span>
              <cp-explain v-else-if="item.assignees.length > 1">
                <template #activator="{on}">
                  <v-chip v-on="on" class="text-no-wrap" small>
                    {{ pluralize("Employee", item.assignees.length, true) }}
                  </v-chip>
                </template>

                <cp-profile-list :profiles="item.assignees" dense />
              </cp-explain>
            </td>
          </tr>
          <tr>
            <td>Created on</td>
            <td>{{ dates.created_at }}</td>
          </tr>
          <tr>
            <td>Due</td>
            <td>
              {{ dates.due_by_date }}
              <span
                v-if="dates.dueDatePassed && !item.completed_at"
                class="red--text"
              >
                ({{ pluralize("day", dates.dueDaysAgo, true) }} ago)
              </span>
            </td>
          </tr>
          <tr v-if="item.files.length">
            <td>Attachments:</td>
            <td>
              <cp-file-chip
                v-for="file in item.files"
                :key="file.id"
                :name="file.name"
                :url="file.url"
                class="ma-1"
                small
              />
            </td>
          </tr>
        </tbody>
      </table>
    </v-card-text>

    <v-alert :icon="status.icon" v-bind="status.chipProps" tile>
      <strong>{{ status.title }}</strong>
      <br />
      <span v-if="item.user_task_progress_status_id === 1">
        will alert on {{ dates.alert }}
      </span>
      <span v-if="item.user_task_progress_status_id === 2">
        due
        <span v-if="dates.dueDaysAgo < 0">
          in {{ pluralize("day", -dates.dueDaysAgo, true) }}
        </span>
        <span v-else>today</span>
      </span>
      <span v-if="item.user_task_progress_status_id === 3">
        {{ pluralize("days", dates.dueDaysAgo, true) }} late
      </span>
      <span v-if="item.user_task_compliance_status_id === 1">
        Completed on time by
        {{ item.completed_by_name }}
      </span>
      <span v-if="item.user_task_compliance_status_id === 2">
        Completed {{ pluralize("days", dates.dueDaysAgo, true) }} late by
        {{ item.completed_by_name }}
      </span>
      <span v-if="item.user_task_compliance_status_id === 3">
        Marked <span class="font-weight-black">Can't Complete</span> by
        {{ item.completed_by_name }} on
        {{ dates.completed }}
      </span>
      <span v-if="item.user_task_compliance_status_id === 4">
        Abandoned on {{ dates.abandon_date }}
      </span>
      <span v-if="item.user_task_compliance_status_id === 5">
        Marked
        <span class="font-weight-black">Canceled</span>
        {{ item.admin_note.replace(/^Cancell*ed /, "") }}
        on
        {{ dates.adminNote }}
      </span>
    </v-alert>

    <v-card-text v-if="item.notes.length" class="my-4 py-1">
      <v-alert
        v-for="(note, i) in item.notes"
        :key="`note-${i}`"
        color="blue-grey"
        class="note"
        text
      >
        <div class="note-attribution text-caption">
          {{ note.user }} at {{ toTIME(note.created_at) }}
          {{ toMDY(note.created_at) }}
        </div>
        {{ note.note }}
      </v-alert>
    </v-card-text>

    <v-card-text v-if="item.tasker_actions.length" class="mt-4 pt-0">
      <h3 class="text-header-3 mt-4">Update:</h3>
      <v-btn-toggle
        v-bind="fields.tasker_action.attrs"
        @change="fields.tasker_action.input"
        class="my-4"
        mandatory
      >
        <v-btn
          v-for="action in item.tasker_actions"
          :key="action"
          :set="(button = actionButton(action))"
          v-bind="button.props"
          :value="action"
          :class="action"
        >
          <v-icon v-if="button.icon">{{ button.icon }}</v-icon>
          {{ button.text }}
        </v-btn>
      </v-btn-toggle>

      <v-textarea
        label="Notes on task"
        v-bind="fields.note.attrs"
        @input="fields.note.input"
        auto-grow
        outlined
        rows="5"
        row-height="25"
        dense
      />

      <cp-form-modal-success-or-buttons
        :modalConf="modalConf"
        :formConf="formConf"
        :buttons="['cancel', 'update']"
        @submit="submit"
        :buttonConf="buttonConf"
        noun="Task"
        success-message="Task Updated!"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import pluralize from "pluralize";

import { taskStatusForm } from "@/store/modules/taskStatusForm";
import {
  complianceStatusesById,
  progressStatusesById,
} from "@/store/modules/tasks";
import { parse, toMDY, toTIME } from "@cp/utils/dateUtils";
import { wait } from "@cp/utils/promiseUtils";

export default {
  mixins: [taskStatusForm.mixin, taskStatusForm.modalMixin],
  computed: {
    ...mapGetters("haystackUser", ["userId", "userName"]),
    dates() {
      const now = parse();
      const due = parse(this.item.due_by_date);
      const completed = parse(this.item.completed_at);
      return {
        now,
        due,
        created_at: toMDY(this.item.created_at),
        completed_at: toMDY(this.item.completed_at),
        updated_at: toMDY(this.item.updated_at),
        due_by_date: toMDY(this.item.due_by_date),
        escalate_date: toMDY(this.item.escalate_date),
        abandon_date: toMDY(this.item.abandon_date),
        dueDatePassed: now.isAfter(due, "day"),
        dueDaysAgo: now.diff(due, "days"),
        completedAfterDue: completed.isAfter(due, "day"),
        completedAfterDueDays: completed.diff(due, "days"),
        adminNote: toMDY(this.item.admin_note_created_at),
        alert: toMDY(this.item.alert_date),
      };
    },
    status() {
      if (this.item.user_task_progress_status_id === 4)
        return complianceStatusesById[this.item.user_task_compliance_status_id];
      else return progressStatusesById[this.item.user_task_progress_status_id];
    },
    buttonConf() {
      if (this.itemFormData.tasker_action === "complete")
        return {
          update: { text: "Complete", icon: "mdi-check-bold" },
        };
      return {};
    },
  },
  methods: {
    pluralize,
    toMDY,
    toTIME,
    async submit() {
      const $vm = this;
      await this.itemSubmit({ id: this.item.id, method: "PUT" });
      if ($vm.success) {
        await wait(1500);
        $vm.closeModal({ success: true });
      }
    },
    actionButton(action) {
      return taskStatusForm.taskerActions[action];
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn-toggle.theme--light {
  width: 100%;

  .v-btn {
    text-transform: none;
    flex-grow: 1;
  }
  .v-btn.v-btn--active {
    color: white !important;
    border-color: $primary;
    box-shadow: none;
    background-color: $primary;
  }

  .v-icon {
    color: inherit !important;
    fill: currentColor !important;
    &:first-child {
      margin-right: 8px;
    }
  }
}

.v-alert {
  margin-bottom: 0;
}

.v-alert.white--text::v-deep .v-icon {
  color: white;
}

.note {
  margin-top: 22px;

  & + .note {
    margin-top: 30px;
  }

  &:before {
    border-radius: 0 4px 4px 4px;
  }

  .note-attribution {
    position: absolute;
    left: 0;
    bottom: 100%;
    border-radius: 5px 5px 0 0;
    background: #eceff1;
    padding: 0 10px;
    box-shadow: inset 0 -2px 2px -2px rgba(0, 0, 0, 0.3);
  }
}
</style>
