<template>
  <div class="summaries" v-on="$listeners">
    <bar v-bind="{ ...activatorProps, ...aProps }" />
    <bar v-bind="{ ...activatorProps, ...iProps }" />
  </div>
</template>

<script>
import { get } from "@cp/utils/objectUtils";

const activatorProps = {
  width: 100,
  height: 32,
  yMin: 0,
  class: "summary",
  options: {
    responsive: false,
    scales: {
      x: { display: false },
      y: { display: false },
    },
    layout: {
      autoPadding: false,
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
    },
  },
};

export default {
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return { activatorProps };
  },
  computed: {
    aProps() {
      const values = get(this.item, "response_summary.agreement", []).map(
        parseFloat
      );
      const yMax = Math.max(...values);
      return {
        yMax,
        chartData: {
          yMin: 0,
          yMax,
          labels: [
            "Totally Disagree",
            "Disagree",
            "Neutral",
            "Agree",
            "Totally Agree",
          ],
          datasets: [
            {
              label: "Agreement",
              data: values,
              backgroundColor: [
                "#eb4969",
                "#ee7850",
                "#f3aa47",
                "#99c061",
                "#68ab5d",
              ],
            },
          ],
        },
      };
    },
    iProps() {
      const values = get(this.item, "response_summary.importance", []).map(
        parseFloat
      );
      const yMax = Math.max(...values);
      return {
        yMax,
        chartData: {
          yMin: 0,
          yMax,
          labels: [
            "Very Unimportant",
            "Unimportant",
            "Neutral",
            "Important",
            "Very Important",
          ],
          datasets: [
            {
              label: "Agreement",
              data: values,
              backgroundColor: [
                "#eb4969",
                "#ee7850",
                "#f3aa47",
                "#99c061",
                "#68ab5d",
              ],
            },
          ],
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.summaries {
  display: flex;
  justify-content: center;
  gap: 4px;
}
.summary {
  padding: 2px 0;
  background: rgba(newColor(gray-2), 0.4);
  border: 1px solid newColor(gray-2);
}
</style>
