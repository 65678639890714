import List from "./List";
import Show from "./Show";
import Surveys from "./Surveys";

export default [
  {
    path: "/residents",
    name: "residents",
    component: List,
  },
  {
    path: "/residents/:id",
    name: "resident",
    component: Show,
    meta: { parent: "residents" },
  },
  {
    path: "/resident-surveys",
    name: "residentResponses",
    component: Surveys,
  },
];
