<template>
  <cp-fullscreen-module-modal :modalConf="modalConf">
    <template #title>Resident Survey Response</template>

    <div v-if="loading" class="text-center pa-4">
      <v-progress-circular
        color="primary"
        indeterminate
        :size="80"
        :width="10"
      />
    </div>
    <div v-else>
      <v-row class="mb-4">
        <v-col md="auto" v-if="Object.values(cardMetrics).some(x => x)">
          <v-card>
            <v-card-title class="d-block text-no-wrap">
              <div v-if="cardMetrics.score">
                Score:
                <span class="font-weight-bold">{{ cardMetrics.score }}</span>
              </div>
              <div v-if="cardMetrics.likelyToRenew" class="d-flex align-center">
                Renewal:&nbsp;
                <strong>{{ cardMetrics.likelyToRenew * 20 }}%</strong>
                <likelihood-emoticon :likelihood="cardMetrics.likelyToRenew" />
              </div>
              <div v-if="cardMetrics.serviceIssue" class="d-flex align-center">
                Service Issue
                <v-icon color="error" class="ml-3">
                  mdi-alert-circle
                </v-icon>
              </div>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col md="auto">
          <h6 class="text-h6 font-weight-bold">
            {{ resident.first_name }} {{ resident.last_name }}
          </h6>
          <div v-if="auth.hasToken" class="font-italic">
            Login to see contact info
          </div>
          <template v-else-if="!resident.is_confidential">
            <div v-if="resident.primary_email" class="d-flex align-center">
              Email:
              <v-btn
                color="primary"
                class="ml-2"
                outlined
                small
                rounded
                :href="`mailto:${resident.primary_email}`"
              >
                <v-icon left>mdi-email</v-icon>
                {{ resident.primary_email }}
              </v-btn>
            </div>
            <div v-if="resident.phone_number" class="d-flex align-center mt-2">
              Phone:
              <v-btn
                color="primary"
                class="ml-2"
                outlined
                small
                rounded
                :href="`tel:${resident.phone_number}`"
              >
                <v-icon left>mdi-phone</v-icon>
                {{ resident.phone_number }}
              </v-btn>
            </div>
          </template>
        </v-col>
        <v-divider inset vertical class="mx-auto" />
        <v-col md="auto">
          <h3>
            Location:
            <a
              v-if="location && location.slug"
              :href="locationResultsUrl"
              class="font-weight-bold"
            >
              {{ location.name }}
            </a>
            <span v-else class="font-weight-bold">
              No Location Assigned
            </span>
          </h3>
          <h3>
            Unit #:
            <span v-if="resident.is_confidential" class="font-weight-bold"
              >NA</span
            >
            <span v-else class="font-weight-bold">{{
              unit ? unit.unit_number : "Missing"
            }}</span>
          </h3>
          <h3>
            On-Site Managers:
            <template v-if="location_managers && location_managers.length">
              <cp-explain v-if="location_managers.length > 1">
                <template #activator="{on}">
                  <v-chip
                    color="primary"
                    v-on="on"
                    class="font-weight-bold"
                    outlined
                  >
                    {{ pluralize("manager", location_managers.length, true) }}
                  </v-chip>
                </template>
                <cp-profile-list
                  :profiles="location_managers"
                  :href="x => (x.email ? `mailto:${x.email}` : null)"
                  target="_blank"
                  dense
                />
              </cp-explain>
              <span v-else :set="(manager = location_managers[0])">
                <v-chip
                  color="primary"
                  class="mr-1"
                  outlined
                  :disabled="!manager.email"
                  :href="manager.email ? `mailto:${manager.email}` : null"
                >
                  {{ manager.full_name }}
                </v-chip>
              </span>
            </template>
            <span v-else class="font-weight-bold">No Manager Assigned</span>
          </h3>
        </v-col>
        <v-divider inset vertical class="mx-auto" />
        <v-col md="auto">
          <h3>
            Region:
            <span class="font-weight-bold">
              {{ region && region.name ? region.name : "No Region Assigned" }}
            </span>
          </h3>
          <h3>
            Regional Managers:
            <template v-if="region_managers && region_managers.length">
              <cp-explain v-if="region_managers.length > 1">
                <template #activator="{on}">
                  <v-chip
                    color="primary"
                    v-on="on"
                    class="font-weight-bold"
                    outlined
                  >
                    {{ pluralize("manager", region_managers.length, true) }}
                  </v-chip>
                </template>
                <cp-profile-list
                  :profiles="region_managers"
                  :href="x => (x.email ? `mailto:${x.email}` : null)"
                  target="_blank"
                  dense
                />
              </cp-explain>
              <span v-else :set="(manager = region_managers[0])">
                <v-chip
                  :disabled="!manager.email"
                  :href="manager.email ? `mailto:${manager.email}` : null"
                  target="_blank"
                  color="primary"
                  class="mr-1"
                  outlined
                >
                  {{ manager.full_name }}
                </v-chip>
              </span>
            </template>
            <span v-else class="font-weight-bold">
              No Regional Manager Assigned
            </span>
          </h3>
        </v-col>
      </v-row>

      <v-sheet rounded class="pa-3 d-flex">
        <div class="flex-grow-1 mr-auto">
          <span class="text-h6 font-weight-bold">
            {{ survey.name }}
          </span>
          <span class="grey--text">
            Sent {{ toMDY(resident_survey.date) }}
          </span>
        </div>
        <div class="scores-columns">
          <v-tooltip top>
            <template #activator="{on}">
              <v-icon v-on="on" color="#e86826">mdi-account</v-icon>
            </template>
            User Response
          </v-tooltip>

          <v-tooltip top>
            <template #activator="{on}">
              <v-icon v-on="on" color="#008a09">mdi-home</v-icon>
            </template>
            Location
          </v-tooltip>

          <v-tooltip top>
            <template #activator="{on}">
              <v-icon v-on="on" color="#003594">mdi-home-group</v-icon>
            </template>
            Region
          </v-tooltip>

          <v-tooltip top>
            <template #activator="{on}">
              <v-icon v-on="on" color="#685478">mdi-office-building</v-icon>
            </template>
            Company
          </v-tooltip>

          <v-tooltip top>
            <template #activator="{on}">
              <cp-icon v-on="on" color="#009cde" icon="swiftbunny" />
            </template>
            SB Index
          </v-tooltip>
        </div>
      </v-sheet>

      <response
        v-for="(response, i) in questions"
        :key="response.id"
        :response="response"
        :question-number="i + 1"
      />
    </div>
  </cp-fullscreen-module-modal>
</template>

<script>
import { mapState } from "vuex";
import { residentSurveyResult } from "@/store/modules/residentResponses";

import { toMDY } from "@cp/utils/dateUtils";

export default {
  name: "ResidentSurveyResult",
  mixins: [residentSurveyResult.modalMixin],
  computed: {
    ...mapState("residentResponses", {
      loading: "itemLoading",
      resident_survey: "item",
      resident: state => state.item.resident,
      unit: state => state.item.unit,
      location_managers: state => state.item.location_managers,
      region_managers: state => state.item.region_managers,
      location: state => state.item.location,
      questions: state => state.item.questions,
      region: state => state.item.region,
      survey: state => state.item.survey,
    }),
    ...mapState({ auth: state => state.auth }),
    locationResultsUrl() {
      if (!this.region || !this.location || !this.survey) {
        return "";
      }

      return `${process.env.VUE_APP_REDSTONE_UI_PATH}/regions/${this.region.slug}/${this.location.slug}/${this.survey.slug}?tab=1`;
    },

    cardMetrics() {
      if (!this.resident_survey) return {};
      return {
        score: this.resident_survey.agreement_importance,
        likelyToRenew: this.resident_survey.likely_to_renew,
        serviceIssue: this.resident_survey.service_issue,
      };
    },
  },
  methods: {
    toMDY,
  },
};
</script>

<style lang="scss" scoped>
.survey-responses-card::v-deep {
  .cp-card__title-text {
    width: 100%;
  }
  .v-card__text {
    padding: 0;
  }
}

::v-deep .scores-columns {
  display: flex;
  .v-icon,
  .icon {
    justify-content: center;
    width: 5rem;
    text-align: center;
  }
}
</style>
