<template>
  <card icon="account-file-text" title="Resident Responses" root-slot>
    <v-data-table v-bind="tableProps"> </v-data-table>
  </card>
</template>

<script>
import widgetMixin from "@/widgets/mixin";

const headers = [
  { text: "Question", value: "body" },
  { text: "Responses", value: "q_responded" },
];

export default {
  mixins: [widgetMixin],
  data() {
    return { headers };
  },
  computed: {
    tableProps() {
      return {
        headers,
        items: this.data,
        itemsPerPage: 15,
      };
    },
  },
};
</script>
