<template>
  <div>
    <cp-h1 css-class="align-center" right-class="align-center flex-grow-1 ml-8">
      Employee Turnover Report

      <template v-if="devMode" #subtitle>
        <dev-mode-widget-links v-bind="{ instance }" />
      </template>

      <template #right>
        <cp-filters-menu :module="table" noun="Report" />
      </template>
    </cp-h1>

    <div class="card-row">
      <div class="card-row-fields">
        <div class="card-row-fields__container">
          <table class="card-row-fields__table">
            <tbody>
              <tr>
                <td class="text-caption">Viewing By:</td>
                <td>
                  <v-menu>
                    <template #activator="{on}">
                      <v-btn color="primary" class="mr-2" v-on="on" text small>
                        <v-icon left>mdi-chevron-down</v-icon>
                        {{ selectedScope.label }}
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item-group
                        v-model="selectedScopeIndex"
                        color="primary"
                      >
                        <v-list-item v-for="s in scopes" :key="s.value">
                          {{ s.label }}
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-row-cards">
        <stat-cards v-bind="{ statCards, table }" />
      </div>
    </div>

    <v-card>
      <expandable-table
        v-bind="tableProps"
        :instance="instance"
        :scope="scope"
        :scopeChangeLoading="scopeChangeLoading"
        :scopeHeaders="scopeHeaders"
        @update:options="updateTableOptions"
        item-key="m_id"
        hide-default-footer
      >
        <template #item.separated_count="{value}">
          {{ Math.round(value) }}
        </template>
        <template #item.location_status_id="{value}">
          <v-chip v-if="value === 1" color="success" small>
            Active
          </v-chip>
          <v-chip v-else color="grey" class="white--text" small>
            Archived
          </v-chip>
        </template>
        <template #item.annualized_turn="{value}">{{ value }}%</template>
      </expandable-table>

      <v-pagination
        v-if="!noData && !scopeChangeLoading"
        class="mt-2 text-center"
        v-bind="paginationProps"
        @input="changePage"
      />
    </v-card>
  </div>
</template>

<script>
import { widgetMixin, tableMixin, multiScopeMixin } from "@/widgets/mixins";

const scopeHeaders = {
  default: [
    { text: "Name", value: "name" },
    { text: "Average Employees", value: "avg_employees" },
    { text: "Separated Employees", value: "separated_count" },
    { text: "Annualized Turnover", value: "annualized_turn" },
  ],
  locations: [
    { text: "Name", value: "name" },
    { text: "Location Status", value: "location_status_id" },
    { text: "Average Employees", value: "avg_employees" },
    { text: "Separated Employees", value: "separated_count" },
    { text: "Annualized Turnover", value: "annualized_turn" },
  ],
};

export default {
  mixins: [widgetMixin, tableMixin, multiScopeMixin],
  data() {
    return { scopeHeaders };
  },
};
</script>
