<template>
  <div v-if="hasData">
    <cp-h1 hide-back-button disable-set-document-title>
      Employee Development
    </cp-h1>
    <v-card>
      <v-row no-gutters>
        <v-col
          v-for="stat in stats"
          :key="stat.id"
          :id="stat.id"
          :class="{ clickableStat: stat.modalProps || stat.to }"
          @click="stat.to && $router.push(stat.to)"
          class="Stat text-center"
          style="height: 120px"
        >
          <div class="value">
            {{ nonEmpty(stat.value) ? stat.value : "N/A"
            }}<span v-if="stat.append">{{ stat.append }}</span>
          </div>
          <div class="label">
            <cp-explain v-if="stat.explainText">
              <template #activator="{on}">
                <span v-on="on">
                  <v-icon :size="16">mdi-information-outline</v-icon>
                  <span>
                    {{ stat.label }}
                  </span>
                </span>
              </template>
              {{ stat.explainText }}
            </cp-explain>
            <span v-else>
              <v-icon v-if="stat.icon">mdi-{{ stat.icon }}</v-icon>
              {{ stat.label }}
            </span>
          </div>
          <widget-modal
            v-if="stat.modalProps"
            v-bind="stat.modalProps"
            :activator="`#${stat.id}`"
          />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import Scrollbar from "vue-custom-scrollbar";

import { get } from "@cp/utils/objectUtils";
import { parse } from "@cp/utils/dateUtils";

import widgetMixin from "@/widgets/mixin";

export default {
  components: { Scrollbar },
  mixins: [widgetMixin],
  data() {
    return {
      headers: [
        {
          text: "Employee",
          value: "name",
          sortable: false,
        },
        {
          text: "Type",
          value: "employee_status",
          width: 130,
          sortable: false,
        },
        {
          text: "Status",
          value: "task_status",
          width: 180,
          sortable: false,
        },
      ],
    };
  },
  computed: {
    hasData() {
      return get(this.meta, "summary_object.task_count");
    },
    stats() {
      return [
        {
          id: "development-all",
          label: "Employees In Plans",
          icon: "account",
          value: this.data.length,
          to: {
            name: "employee-development",
            params: this.$router.currentRoute.params,
          },
        },
        // {
        //   id: "development-onboarding",
        //   label: "Onboarding",
        //   icon: "account-plus",
        //   value: this.data.filter(x => x.employee_status === "Onboarding")
        //     .length,
        //   to: {
        //     name: "employee-development",
        //     params: this.$router.currentRoute.params,
        //     query: { user_lifecycle_statuses: 2 },
        //   },
        // },
        // {
        //   id: "development-offboarding",
        //   label: "Offboarding",
        //   icon: "account-remove",
        //   value: this.data.filter(x => x.employee_status === "Offboarding")
        //     .length,
        //   to: {
        //     name: "employee-development",
        //     params: this.$router.currentRoute.params,
        //     query: { user_lifecycle_statuses: 5 },
        //   },
        // },
        {
          id: "development-overdue",
          icon: "timer-alert-outline",
          label: "Tasks Overdue",
          value: this.meta.summary_object.past_due,
        },
      ];
    },
  },
  methods: {
    progressBarProps(item) {
      const { task_count = 0, completed = 0, pending = 0, past_due = 0 } = item;
      return {
        resolved: completed,
        late: past_due,
        unresolved: pending,
        total: task_count,
        classes: {
          resolved: "success white--text",
        },
      };
    },
    navToEmployee(id, event) {
      const route = {
        name: "teamEmployeeDetails",
        params: { id },
      };
      if (event.shiftKey || event.ctrlKey) {
        const routeData = this.$router.resolve(route);
        window.open(routeData.href, "_blank");
        // } else if (event.ctrlKey) {
        //   const routeData = this.$router.resolve(route);
        //   window.open(routeData.href, "_blank");
      } else {
        this.$router.push(route);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.Stat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  color: rgba(0, 0, 0, 0.6);
  &.clickableStat {
    cursor: pointer;
    &:hover {
      background: rgba($primary, 0.2);
    }
  }
}
.value {
  font-size: 2.5rem;
  line-height: 3.5rem;
  font-weight: lighter;
}
.label {
  white-space: nowrap;

  span {
    vertical-align: middle;
  }
}

.employeesInPlans::v-deep .v-data-table__wrapper table {
  min-height: 120px;
}

.ps {
  position: relative;
  max-height: 120px;
}
</style>
