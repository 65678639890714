<template>
  <widgets-page
    :key="loadTime"
    :url="'/team/' + $router.currentRoute.params.id"
  />
</template>

<script>
import { marigoldEmployee } from "@/store/modules/employee";
import { onCpEvent } from "@cp/mixins";
export default {
  mixins: [
    onCpEvent(marigoldEmployee.k.modalClosedEvent, function({ success }) {
      if (success) this.loadTime = Date.now();
    }),
  ],
  data() {
    return {
      loadTime: Date.now(),
    };
  },
};
</script>
