import { mergeMixins, Search } from "@cp/store/mixins";

const positionSearch = new Search({
  baseUrl: `${process.env.VUE_APP_MARIGOLD_API_PATH}/en/v1/positions/autocomplete`,
  module: "positions",
  name: "options",
  mapOptions: ({ name: text, id: value }) => ({ value, text }),
});

export const positionSearchPaginated = new Search({
  baseUrl: `${process.env.VUE_APP_MARIGOLD_API_PATH}/en/v1/positions/autocomplete`,
  module: "positions",
  name: "optionsPaged",
  params: { page: { size: 15 } },
  mapOptions: ({ name: text, id: value }) => ({ value, text }),
});

export default mergeMixins(positionSearch, positionSearchPaginated);
