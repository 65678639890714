import { FormMixin } from "@cp/store/mixins";
import { required, email } from "@cp/utils/rules";

export const optOutForm = new FormMixin({
  module: "optOut",
  baseUrl: process.env.VUE_APP_ENGAGE_API_PATH + "/en/v1/user-opt-out",
  name: "optOut",
  jsonApiResponse: false,
  fields: {
    opt_out: { initialValue: "yes" },
    email: { rules: [required, email] },
  },
});

export default optOutForm.toVuex;
