<template>
  <cp-fullscreen-modal
    :show.sync="alerts.itemDetailDialogOpen"
    title="Alerts"
    back-icon="close"
    back-text="Close"
    hide-default-activator
    @modalClosed="$store.commit('alerts/resetItem')"
  >
    <cp-card :title-border="false">
      <template #title>
        <h3 v-if="item.category">
          <v-icon class="mr-2" large>{{ item.icon }}</v-icon>
          {{ item.category.name }}
        </h3>
      </template>

      <component v-if="item.category" :is="item.category.slug" />
    </cp-card>
  </cp-fullscreen-modal>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      alerts: state => state.alerts,
      item: state => state.alerts.item
    })
  }
};
</script>
