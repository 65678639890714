<template>
  <cp-card flush-body>
    <template #title>
      <span class="text-capitalize">{{ model.vuexPluralName }}</span>
    </template>

    <v-data-table
      v-if="model.items && model.items.length"
      disable-filtering
      disable-sort
      disable-pagination
      hide-default-footer
      dense
      :items="model.items"
      :headers="model.tableHeaders"
    >
      <template #item.lowScore="{item}">
        <v-tooltip
          right
          transition="slide-x-reverse-transition"
          max-width="250"
          v-if="item.scores.ingage < 70 || item.scores.intouch < 70"
        >
          <template #activator="{ on }">
            <v-icon color="red" v-on="on">
              mdi-circle
            </v-icon>
          </template>
          Score is under 65
        </v-tooltip>
      </template>

      <template #item.scores="{item}">
        <div class="bun-table-scores">
          <div
            class="bun-table-scores__score"
            :class="{
              'bun-table-scores__score--low-score': item.scores.ingage < 70,
            }"
          >
            <cp-icon small icon="ingage" />
            {{ item.scores.ingage }}
          </div>
          <v-spacer />
          <div
            class="bun-table-scores__score"
            :class="{
              'bun-table-scores__score--low-score': item.scores.intouch < 70,
            }"
          >
            <cp-icon small icon="redstone" />
            {{ item.scores.intouch }}
          </div>
        </div>
      </template>
    </v-data-table>
  </cp-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    model: { type: Object, default: () => {} },
  },

  mounted() {
    this.$store.dispatch(`${this.model.groupList.modelName}/fetchItems`);
  },
};
</script>

<style lang="scss" scoped>
.bun-table-scores {
  display: flex;

  .bun-table-scores__score {
    padding: 6px 8px;
    border-radius: 4px;
    font-weight: 700;

    svg {
      width: 18px;
      margin-right: 4px;
      margin-top: -2px;
      fill: #666666;
    }

    &.bun-table-scores__score--low-score {
      background-color: red;
      color: #ffffff;

      svg {
        fill: #ffffff;
      }
    }
  }
}
</style>
