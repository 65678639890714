import { mergeMixins, Table } from "@cp/store/mixins";

export const teamTable = new Table({
  module: "team",
  baseUrl: `${process.env.VUE_APP_HAYSTACK_API_PATH}/en/v1/team`,
  compoundId: true,
  name: "employees",
  params: {
    structures: {
      addon_options: ["summary"],
    },
  },
  initialMeta: {
    summary_object: {
      general: {
        employees: 0,
        positions: 0,
        locations: 0,
        tenure: 0,
      },
      development: {
        employees: 0,
        pending: 0,
        past_due: 0,
        cant_complete: 0,
      },
      data_issues: {
        no_manager: 0,
        opted_out: 0,
        missing_start_date: 0,
        missing_office: 0,
        missing_position: 0,
      },
      team: {
        regional_managers: 0,
        property_managers: 0,
        maintenance_managers: 0,
        new_hires: 0,
      },
    },
  },
  headers: [
    {
      text: "Employee",
      value: "employee_name",
      sortable: true,
    },
    {
      text: "Position",
      value: "position",
      sortable: false,
    },
    {
      text: "Location",
      value: "location",
      sortable: false,
    },
    {
      text: "Manager",
      value: "managers",
      sortable: false,
    },
    {
      text: "Status",
      value: "lifecycle_status",
      sortable: true,
    },
  ],
  filters: [
    "q_text",
    {
      label: "Region",
      type: "sub_query",
      key: "regions",
      multiple: true,
    },
    {
      label: "Location",
      type: "sub_query",
      key: "locations",
      multiple: true,
    },
    {
      label: "Reporting Groups",
      type: "sub_query",
      key: "reporting_groups_by_locations",
      multiple: true,
    },
    {
      label: "Position",
      type: "sub_query",
      key: "positions",
      multiple: true,
    },
    {
      label: "Position Category",
      type: "sub_query",
      key: "position_categories",
      multiple: true,
    },
    {
      label: "Status",
      type: "sub_query",
      key: "lifecycle_statuses",
      multiple: true,
    },
    {
      type: "radio",
      key: "general",
      items: [
        { label: "All", value: "" },
        { label: "Active", value: "active" },
        { label: "Inactive", value: "archived" },
        { label: "No Start Date", value: "missing_start_date" },
        { label: "No Location", value: "missing_locations" },
        { label: "No Position", value: "missing_position" },
        {
          label: "No Manager or subordinates",
          value: "no_managers_or_subordinates",
        },
        { label: "Opted out of emails", value: "opted_out" },
      ],
      initialValue: 1,
    },
  ],
  metaCounts: [
    {
      label: "Active",
      path: "additional_meta.active_employees",
    },
    {
      label: "Archived",
      path: "additional_meta.archived_employees",
    },
  ],
  bulkActions: [
    {
      label: "Archive",
    },
    {
      label: "Send Welcome Email",
    },
    {
      label: "Bulk Assign Manager",
    },
    {
      label: "Bulk Assign Position",
    },
  ],
  rowActions: [
    {
      label: "Archive",
    },
    {
      label: "Send Welcome Email",
    },
    {
      label: "Assign Manager",
    },
    {
      label: "Assign Position",
    },
    {
      label: "Assign As Location Manager",
    },
    {
      label: "Assign As Maintenance Manager",
    },
  ],
});

teamTable.add({
  getters: {
    [teamTable.keys.tableHeaders](state, getters, rootState, rootGetters) {
      // if the user is a property or maintenance manager, do not include the manager header
      if (
        ["property_manager", "maintenance_manager"].includes(
          rootGetters["haystackUser/thisAppAccessLevel"]
        )
      )
        return this.headers.filter(x => x.value !== "managers");
      return this.headers;
    },
  },
});

window.$team = teamTable;

export default mergeMixins(teamTable);
