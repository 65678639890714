<template>
  <cp-explain v-if="alerts && alerts.length">
    <template #activator="{on}">
      <span v-on="on" class="d-flex align-center">
        <span class="error--text text-bold">{{ alerts.length }}</span>
        <v-icon color="error">mdi-bell-alert-outline</v-icon>
      </span>
    </template>

    <table>
      <tbody>
        <tr v-for="({ created_at, name }, i) in alerts" :key="i">
          <td class="text-caption pr-2">{{ toMDY(created_at) }}</td>
          <td>{{ name }}</td>
        </tr>
      </tbody>
    </table>
  </cp-explain>
</template>

<script>
import { toMDY } from "@cp/utils/dateUtils";
export default {
  props: { alerts: { type: Array, default: () => [] } },
  methods: { toMDY },
};
</script>
