<template>
  <div class="expandable-table__wrapper">
    <cp-h1>
      Onboarding and Offboarding

      <template v-if="devMode" #subtitle>
        <dev-mode-widget-links v-bind="{ instance }" />
      </template>

      <template #right>
        <cp-filters-menu :module="table" noun="Report" />
      </template>
    </cp-h1>

    <div class="card-row">
      <div class="card-row-cards">
        <stat-cards v-bind="{ statCards, table }" />
      </div>
    </div>

    <v-card>
      <v-data-table
        v-bind="tableProps"
        @update:options="updateTableOptions"
        item-key="m_id"
        hide-default-footer
        class="magic-headers"
      >
        <template #item.complete_percentage="{value}">
          {{ value ? coaxRoundPrct(value) : "0%" }}
        </template>
      </v-data-table>

      <v-pagination
        v-if="!noData"
        class="mt-2 text-center"
        v-bind="paginationProps"
        @input="changePage"
      />
    </v-card>
  </div>
</template>

<script>
import { widgetMixin, tableMixin } from "@/widgets/mixins";
import { coaxRoundPrct } from "@/lib/formats";

export default {
  mixins: [widgetMixin, tableMixin],
  computed: {
    headers() {
      return [
        { text: "", value: "name" },
        { text: "Progress", value: "complete_percentage" },
        { text: "Complete", value: "positive_completions" },
        { text: "Pending", value: "pending" },
        { text: "Past Due", value: "past_due" },
        { text: "Can't Complete", value: "cant_complete" },
        { text: "Abandoned", value: "abandoned" },
        { text: "Canceled", value: "canceled" },
      ];
    },
  },
};
</script>
