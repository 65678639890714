import { mergeMixins, ItemsTable, ItemWithDetailModal } from "@cp/store/mixins";

export const residentResponsesTable = new ItemsTable({
  module: "residentResponses",
  baseUrl: `${process.env.VUE_APP_ENGAGE_API_PATH}/en/v1/intouch/dashboard`,
  noun: "Resident Survey",
  tableOptions: {
    sort_desc: true,
    sort_by: "created_at",
    page: {
      number: 1,
      size: 15,
    },
    q_text: "",
    structures: {
      date_interval: "month",
      scope: ["surveys_users"],
    },
  },
  headers: [
    {
      text: "",
      value: "score_danger",
      sortable: true,
      width: "10px",
      class: "thin-column",
      cellClass: "thin-column",
    },
    {
      text: "Date",
      value: "created_at",
      width: "140px",
      sortable: true,
    },
    {
      text: "Score",
      value: "agreement_importance",
      sortable: true,
      width: "80px",
      class: "thin-column",
      cellClass: "thin-column",
    },
    {
      text: "Renewal",
      value: "likely_to_renew",
      sortable: true,
      width: "120px",
      class: "thin-column",
      cellClass: "thin-column",
    },
    { text: "Survey", value: "survey_name" },
    { text: "Resident", value: "full_name" },
    { text: "Location", value: "location_name" },
    { text: "Unit #", value: "unit_number", width: "100px" },
  ],
  filters: [
    // "q_text", not working due to filters changes
    // TODO - date range filter
    // {
    //   type: "date_range",
    //   key: "date_range",
    //   label: "Filter by date",
    // },
    {
      type: "select",
      key: "surveys",
      label: "Surveys",
      multiple: true,
      items: [
        {
          text: "Community Follow Up Survey",
          value: 27,
        },
        {
          text: "Move In Survey",
          value: 25,
        },
        {
          text: "Resident Satisfaction Survey",
          value: 24,
        },
        {
          text: "Service Request Follow Up Survey",
          value: 26,
        },
      ],
    },
    {
      label: "Regions",
      type: "search",
      key: "regions",
      urlKey: "regions",
      multiple: true,
    },
    {
      label: "Location",
      type: "search",
      key: "locations",
      urlKey: "properties",
      multiple: true,
    },
    {
      label: "Reporting Group",
      type: "search",
      key: "reporting_group",
      urlKey: "reporting_group",
      multiple: true,
    },
    {
      type: "radio",
      key: "confidential_only",
      items: [
        { label: "All", value: "" },
        { label: "Confidential", value: true },
        { label: "Non-Confidential", value: false },
      ],
      initialValue: "",
    },
  ],
});
residentResponsesTable.mapData = response => response.data.data.results;

export const residentSurveyResult = new ItemWithDetailModal({
  module: "residentResponses",
  baseUrl: `${process.env.VUE_APP_ENGAGE_API_PATH}/en/v1/intouch/resident_survey`,
  url: "/:id",
  urlTemplate: true,
  jsonApiResponse: false,
  initialValue: {
    date: "",
    temp_token: "",
    resident: {
      id: "",
      first_name: "",
      is_confidential: true,
    },
    location: {
      id: "",
      name: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      email: "",
      phone: null,
      slug: "",
    },
    location_managers: [],
    region: {
      id: "",
      name: "",
      slug: "",
    },
    unit: null,
    region_managers: [],
    questions: [],
    survey: {
      id: 0,
      name: "",
      slug: "",
    },
    likely_to_renew: 5.0,
    agreement_importance: 100,
  },
});

export default mergeMixins(residentResponsesTable, residentSurveyResult);
