import { mergeMixins, ApiDataMixin } from "@cp/store/mixins";
import { apps } from "@cp/constants";
import eventbus from "@cp/lib/CpEventBus";
import dayjs from "dayjs";

const dashboardApps = [
  // {
  //   service_id: "intouch",
  //   showCardIf: ({ tasksCount }) => tasksCount.resident_alerts,
  //   buttons: [
  //     {
  //       showButtonIf: () => true,
  //       text: "Resident Alerts",
  //       countFn: ({ tasksCount }) => tasksCount.resident_alerts,
  //       props: {},
  //       listeners: {
  //         click() {
  //           eventbus.$emit("openBellMenu");
  //         },
  //       },
  //     },
  //   ],
  // },
  {
    service_id: "introduce",
    showCardIf: ({ tasksCount }) => tasksCount.todo || tasksCount.historical,
    buttons: [
      {
        showButtonIf: () => true,
        text: "View Tasks",
        countFn: ({ tasksCount }) => tasksCount.todo,
        props: { to: { name: "MyTasks", params: { service: "introduce" } } },
      },
      {
        showButtonIf: ({ tasksCount }) => tasksCount.escalations,
        text: "Escalations",
        countFn: ({ tasksCount }) => tasksCount.escalations,
        props: { to: { name: "MyEscalations" }, color: "error" },
      },
    ],
  },
];

const i =
  "https://assets.swiftbunny.com/public-assets/app-intel/dashboard_preview_assets/";
const baseUrl = `${process.env.VUE_APP_HAYSTACK_API_PATH}/en/v1/user_tasks`;

const myAssignments = new ApiDataMixin({
  baseUrl,
  url: "/my_assignments",
  name: "myAssignments",
  params: { before: "next_week" },
  initialValue: [],
  jsonApiResponse: false,
});

const tasksCount = new ApiDataMixin({
  baseUrl,
  url: "/dashboard_counts",
  name: "tasksCount",
  jsonApiResponse: false,
  initialValue: {
    todo: 0,
    historical: 0,
    escalations: 0,
    resident_alerts: 0,
  },
});

export default mergeMixins(myAssignments, tasksCount, {
  getters: {
    appActions(state) {
      return dashboardApps
        .filter(({ showCardIf }) => showCardIf(state))
        .map(({ service_id, buttons: appButtons }) => {
          const buttons = appButtons
            .filter(x => x.showButtonIf(state))
            .map(({ text, props, listeners, countFn }) => ({
              text,
              props,
              listeners,
              count: countFn(state),
            }));
          return {
            buttons,
            app: apps.find(({ serviceId }) => serviceId === service_id),
          };
        });
    },
    myManagers() {
      return [
        {
          name: "Jackie Henderson",
          position: "District Manager",
          profile_image: i + "f1.png",
        },
        {
          name: "Paula Field",
          position: "District Manager",
          profile_image: i + "f2.png",
        },
      ];
    },
    myReports() {
      return [
        {
          name: "Simone Grapes",
          position: "Maintenance Manager",
          profile_image: i + "f3.png",
        },
        {
          name: "Allan Lebowitz",
          position: "Maintenance Manager",
          profile_image: i + "m1.png",
        },
        {
          name: "Robert Geld",
          position: "Maintenance Manager",
          profile_image: i + "m3.png",
        },
        {
          name: "Justin Sorbit",
          position: "Maintenance Manager",
          profile_image: "",
        },
        {
          name: "Olivia Waters",
          position: "Maintenance Manager",
          profile_image: i + "f4.png",
        },
        {
          name: "David Branson",
          position: "Property Manager",
          profile_image: "",
        },
        {
          name: "John Freeman",
          position: "Property Manager",
          profile_image: i + "m4.png",
        },
      ];
    },
    myPeers() {
      return [
        {
          name: "Adelle Landrum",
          position: "Maintenance Manager",
          profile_image: i + "f5.png",
        },
        {
          name: "Betty Harmond",
          position: "Maintenance Manager",
          profile_image: "",
        },
        {
          name: "Liam Fresh",
          position: "Maintenance Manager",
          profile_image: i + "m5.png",
        },
        {
          name: "Rachel Limes",
          position: "Maintenance Manager",
          profile_image: i + "f5.png",
        },
        {
          name: "Alisha Emerald",
          position: "Maintenance Manager",
          profile_image: i + "f6.png",
        },
      ];
    },
    myTasks() {
      return [
        {
          name: "GROW Workplace Culture course",
          category: "Training",
          icon: "mdi-school",
          overdue: true,
          due: dayjs()
            .subtract(1, "week")
            .format(),
        },
        {
          name: "Mental & Emotional Health Survey",
          category: "Ingage Survey",
          cpIcon: "ingage",
          overdue: false,
          due: dayjs()
            .add(8, "days")
            .format(),
        },
        {
          name: "Diversity & Inclusion survey",
          category: "Inquire Survey",
          cpIcon: "inquire",
          overdue: false,
          due: dayjs()
            .add(13, "days")
            .format(),
        },
      ];
    },
    myCareerDevelopment() {
      return [
        {
          name: "Regional Manager Development Plan",
          taskStatuses: [
            {
              name: "Complete",
              count: 3,
            },
            {
              name: "In Progress",
              count: 1,
            },
            {
              name: "Overdue",
              count: 1,
              warn: true,
            },
          ],
          status: "pending",
          due: dayjs()
            .add(20, "days")
            .format(),
          progress: 0.6,
        },
        {
          name: "Maintenance Manager Development Plan",
          taskStatuses: [
            {
              name: "Complete",
              count: 10,
            },
          ],
          status: "complete",
          due: dayjs()
            .subtract(20, "days")
            .format(),
          progress: 1,
        },
      ];
    },
    myTraining() {
      return [
        {
          name: "GROW Workplace Culture course",
          status: "overdue",
          due: dayjs()
            .subtract(3, "days")
            .format(),
          progress: 0.6,
        },
        {
          name: "Leasing For a Living",
          status: "pending",
          due: dayjs()
            .add(10, "days")
            .format(),
          progress: 0,
        },
        {
          name: "Customer Service",
          status: "pending",
          due: dayjs()
            .add(20, "days")
            .format(),
          progress: 0,
        },
      ];
    },
  },
});
