<template>
  <div class="expandable-table__wrapper">
    <cp-h1 css-class="align-center" right-class="align-center flex-grow-1 ml-8">
      Enrollment Status Report

      <template v-if="devMode" #subtitle>
        <dev-mode-widget-links v-bind="{ instance }" />
      </template>

      <template #right>
        <cp-filters-menu :module="table" noun="Report" />
      </template>
    </cp-h1>

    <div class="card-row">
      <div class="card-row-fields">
        <div class="card-row-fields__container">
          <table class="card-row-fields__table">
            <tbody>
              <tr>
                <td class="text-caption">Viewing By:</td>
                <td>
                  <v-menu>
                    <template #activator="{on}">
                      <v-btn color="primary" class="mr-2" v-on="on" text small>
                        <v-icon left>mdi-chevron-down</v-icon>
                        {{ selectedScope.label }}
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item-group
                        v-model="selectedScopeIndex"
                        color="primary"
                      >
                        <v-list-item v-for="s in scopes" :key="s.value">
                          {{ s.label }}
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
              <tr>
                <td class="pr-3 text-caption">
                  Date Range:
                </td>
                <td>
                  <v-menu>
                    <template #activator="{on}">
                      <v-btn v-on="on" color="primary" small text>
                        <v-icon left>mdi-chevron-down</v-icon>
                        {{ selectedDateRange.label }}
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item-group
                        v-model="selectedDateRangeIndex"
                        color="primary"
                      >
                        <v-list-item v-for="dr in dateRanges" :key="dr.value">
                          <v-list-item-content>{{
                            dr.label
                          }}</v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-row-cards">
        <stat-cards v-bind="{ statCards, table }" />
      </div>
    </div>

    <v-card>
      <expandable-table
        v-bind="tableProps"
        :instance="instance"
        :scope="scope"
        :scopeChangeLoading="scopeChangeLoading"
        :scopeHeaders="scopeHeaders"
        @update:options="updateTableOptions"
        item-key="m_id"
        hide-default-footer
      >
        <template #header.name>
          {{ selectedScope.label }}
        </template>

        <template #item.complete_percentage="{value}">
          {{ value ? coaxRoundPrct(value) : "0%" }}
        </template>
      </expandable-table>

      <v-pagination
        v-if="!noData && !scopeChangeLoading"
        class="mt-2 text-center"
        v-bind="paginationProps"
        @input="changePage"
      />
    </v-card>
  </div>
</template>

<script>
import { widgetMixin, tableMixin, multiScopeMixin } from "@/widgets/mixins";

export default {
  mixins: [widgetMixin, tableMixin, multiScopeMixin],
  computed: {
    scopeHeaders() {
      return {
        default: [
          { text: "", value: "name" },
          { text: "Progress", value: "complete_percentage", width: 110 },
          { text: "Total", value: "users_task_count", width: 110 },
          { text: "Scheduled", value: "scheduled", width: 120 },
          { text: "Pending", value: "pending", width: 100 },
          { text: "Past Due", value: "past_due", width: 110 },
          { text: "Completed", value: "completed", width: 120 },
          { text: "Abandoned", value: "abandoned", width: 120 },
        ],
      };
    },
  },
};
</script>
