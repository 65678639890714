<template>
  <line-chart show-details responsive v-bind="props" />
</template>

<script>
import { unique } from "@cp/utils/arrayUtils";
import { get } from "@cp/utils/objectUtils";
import { colors as globalColors } from "@/lib/colorsAndIcons";

const colorsArr = [
  "#E35205",
  "#009A44",
  "#9063CD",
  "#C6579A",
  "#009CDE",
  "#6CC24A", // Meadow Green
  "#f9a825", // @cp/src/styles/partials/variables.scss $rank-color-mid
  "#dc001b", // @cp/src/styles/partials/variables.scss $rank-color-low
  "#00778B",
  "#003594",
];
function colors(index) {
  const color = colorsArr[index] || "#555";
  return {
    borderColor: color,
    pointBackgroundColor: color,
  };
}

export default {
  props: {
    instance: { type: Object, required: true },
    selected: { type: Array, required: true },
    selectedMetric: { type: Object, required: true },
    xLabelFn: { type: Function, required: true },
    yLabelFn: { type: Function, default: x => x },
    showIndexes: { type: Boolean, default: false },
    boldIndex: { default: undefined },
  },
  computed: {
    meta() {
      return get(this.$store.state, this.instance.p.s.meta);
    },
    props() {
      const sbxTimeline = get(this.meta, "summary_object.sbx_timeline", {});

      const clientTimeline = get(
        this.meta,
        "summary_object.client_index_timeline",
        []
      );

      const selectedLabels = this.selected
        .map(x => Object.keys(x.timeline))
        .flat();
      const indexLabels = this.showIndexes
        ? [...Object.keys(sbxTimeline), ...Object.keys(clientTimeline)]
        : [];
      let beLabels = unique([...selectedLabels, ...indexLabels]).sort();
      const datasets = [];

      const vFn = item => {
        const v = this.selectedMetric.format
          ? this.selectedMetric.format(item)
          : item[this.selectedMetric.value];
        return parseFloat(v);
      };

      const mapCoords = (y, x) => ({ x, y });
      const filterCoords = ({ y }) => y;

      if (this.showIndexes) {
        const sbxTimelineData = beLabels
          .map(x => vFn(sbxTimeline[x] || {}))
          .map(mapCoords)
          .filter(filterCoords);
        if (sbxTimelineData.length)
          datasets.push({
            fill: false,
            borderColor: globalColors.sbx,
            pointBackgroundColor: globalColors.sbx,
            borderWidth: 1.5,
            lineTension: 0.3,
            order: 2,
            data: sbxTimelineData,
            label: "SB Index",
          });

        const clientTimelineData = beLabels
          .map(x => vFn(clientTimeline[x] || {}))
          .map(mapCoords)
          .filter(filterCoords);
        if (clientTimelineData.length)
          datasets.push({
            fill: false,
            borderColor: globalColors.client,
            pointBackgroundColor: globalColors.client,
            borderWidth: 1.5,
            lineTension: 0.3,
            order: 2,
            data: clientTimelineData,
            label: "Company",
          });
      }

      for (const i in this.selected) {
        const item = this.selected[i];
        const data = beLabels
          .map(x => vFn(item.timeline[x] || {}))
          .map(mapCoords)
          .filter(filterCoords);
        let borderWidth = 1.5;
        if (this.boldIndex == i) {
          borderWidth = 3;
        }
        datasets.push({
          fill: false,
          ...colors(i),
          borderWidth,
          lineTension: 0.3,
          order: 2,
          data,
          label: item.name,
        });
      }

      const yValues = datasets
        .map(x => x.data.map(({ y }) => y))
        .flat()
        .map(parseFloat)
        .sort((a, b) => a - b);

      const minYValue = yValues[0];
      const maxYValue = yValues[yValues.length - 1];
      const ySpan = maxYValue - minYValue;
      const yPad = ySpan * 0.2; // pad by 20%
      const yMin = Math.max(Math.floor(parseFloat(minYValue) - yPad), 0);
      const yMax = Math.min(Math.ceil(parseFloat(maxYValue) + yPad), 105);

      return {
        options: {
          spanGaps: true,
          plugins: {
            legend: {
              display: true,
              position: "left",
              labels: { usePointStyle: true, pointStyle: "line" },
            },
          },
          scales: {
            y: {
              ticks: {
                callback: this.yLabelFn,
              },
            },
          },
        },
        height: Math.ceil(this.$vuetify.breakpoint.height / 4),
        yMin,
        yMax,
        chartData: {
          labels: beLabels.map(this.xLabelFn),
          datasets,
          yMin,
          yMax,
        },
        showDetails: true,
      };
    },
  },
};
</script>
