<template>
  <div>
    {{ label }}
    <div class="pl-5">
      <v-text-field
        v-for="(text, i) in value"
        :key="`text-n-${i}`"
        :value="text"
        :label="`${labels[0]} ${i + 1}`"
        @input="v => update(i, v)"
      >
        <template #append-outer>
          <v-icon color="error" @click="removeItem(i)">mdi-trash-can</v-icon>
        </template>
      </v-text-field>
    </div>
    <div class="d-flex justify-end">
      <v-btn color="primary" @click="addItem" text small>
        <v-icon left>mdi-plus</v-icon>
        Add Item
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, required: true },
    labels: { type: Array, required: true },
    value: { type: Array, required: true },
  },
  methods: {
    addItem() {
      const array = [...this.value];
      array.push("");
      this.$emit("input", array);
    },
    removeItem(i) {
      const array = [...this.value];
      array.splice(i, 1);
      this.$emit("input", array);
    },
    update(i, v) {
      const array = [...this.value];
      array[i] = v;
      this.$emit("input", array);
    },
  },
};
</script>
