import { mergeMixins, Table } from "@cp/store/mixins";

export const escalationsTable = new Table({
  baseUrl: `${process.env.VUE_APP_HAYSTACK_API_PATH}/en/v1/escalations/tasks`,
  module: "escalations",
  name: "escalations",
  initialValue: [],
  tableOptions: {
    sort_desc: false,
    sort_by: "",
    page: {
      number: 1,
      size: 15,
    },
    filters: [],
    q_text: "",
  },
  headers: [
    {
      text: "Due Date",
      value: "due_by_date",
      cellClass: "text-no-wrap",
    },
    {
      text: "Assigned To",
      value: "assignees",
      sortable: false,
      class: "header-assigned-to",
    },
    {
      text: "Task",
      value: "task_title",
      cellClass: "text-no-wrap",
    },
    {
      text: "For Employee",
      value: "on_behalf_of",
      sortable: false,
    },
  ],
  filters: [
    "q_text",
    { label: "Assigned Employee", key: "assignees", type: "sub_query" },
    { label: "For Employee", key: "employees", type: "sub_query" },
  ],
});

// export const escalationsByAssigneeTable = new Table({
//   baseUrl: `${process.env.VUE_APP_HAYSTACK_API_PATH}/en/v1/escalations/assignees`,
//   module: "escalations",
//   name: "escalationsByAssignee",
//   initialValue: [],
//   tableOptions: {
//     sort_desc: false,
//     sort_by: "",
//     page: {
//       number: 1,
//       size: 15,
//     },
//     filters: [],
//     q_text: "",
//   },
//   headers: [
//     {
//       text: "Last Activity",
//       value: "due_by_date",
//     },
//     {
//       text: "Task",
//       value: "task_title",
//     },
//     {
//       text: "For Employee",
//       value: "on_behalf_of",
//       sortable: false,
//     },
//     {
//       text: "Assigned To",
//       value: "on_behalf_of",
//       sortable: false,
//     },
//   ],
// });

export default escalationsTable.toVuex;

// export default mergeMixins(escalationsTable, escalationsByAssigneeTable);
