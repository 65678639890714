<template>
  <div v-if="r.y || r.n" class="yesNo" v-on="$listeners">
    <span>Yes&nbsp;</span>
    <div class="bars">
      <div
        class="bar-yes"
        :style="{ width: `${r.y}%` }"
        :class="{ smaller: r.y < r.n }"
      >
        {{ r.y ? roundPrct(r.y) : "" }}
      </div>
      <div
        class="bar-no"
        :style="{ width: `${r.n}%` }"
        :class="{ smaller: r.y > r.n }"
      >
        {{ r.n ? roundPrct(r.n) : "" }}
      </div>
    </div>
    <span>&nbsp;No</span>
  </div>
</template>

<script>
import { roundPrct } from "@/lib/formats";

export default {
  props: ["item"],
  computed: {
    r() {
      let n, y;
      if (Array.isArray(this.item.response_summary)) {
        [n, y] = this.item.response_summary;
      } else if (this.item.yes_no) {
        const prct = this.item.yes_no;
        y = Math.round(parseFloat(prct) * 100);
        n = 100 - y;
      }
      return { y, n };
    },
  },
  methods: { roundPrct },
};
</script>

<style lang="scss" scoped>
.yesNo {
  display: flex;
  align-items: center;
  min-width: 160px;

  .bars {
    min-width: 40px;
    flex-grow: 1;
    text-align: center;
    position: relative;
    line-height: 2.2em;
    display: flex;

    > div {
      white-space: nowrap;
      overflow: visible;
      z-index: 1;
      &.smaller {
        z-index: 2;
      }
    }

    .bar-yes {
      background: $info;
      color: white;
    }
    .bar-no {
      background: $gray-3;
      color: white;
    }
  }
}
</style>
