<template>
  <div v-if="_total">
    <div class="text-caption d-flex" v-if="!hideCaption">
      <slot name="title">Progress:</slot>
      <v-spacer horizontal />
      {{ Math.round(prct) }}%
    </div>
    <div class="bar-container">
      <div
        v-for="(bar, i) in bars"
        :key="i"
        class="status-bar"
        v-bind="bar.props"
      >
        {{ bar.count }}
      </div>
    </div>
  </div>
</template>

<script>
import { nonEmpty } from "@cp/utils/itertees";
import { deepMerge } from "@cp/utils/objectUtils";

export default {
  name: "ProgressBar",
  props: {
    resolved: { type: Number, default: 0 },
    unresolved: { type: Number, default: 0 },
    errors: { type: Number, default: 0 },
    total: { type: Number, default: 0 },
    classes: { type: Object, default: () => ({}) },
    hideCaption: { type: Boolean, default: false },
  },
  computed: {
    // optional prop total, if not passed, calculate
    _total() {
      return nonEmpty(this.total)
        ? this.total
        : this.resolved + this.unresolved + this.errors;
    },
    // optional prop unresolved, if not passed, calculate
    _unresolved() {
      return nonEmpty(this.unresolved)
        ? this.unresolved
        : this.total - this.resolved;
    },
    _classes() {
      const DEFAULT = {
        resolved: "grey lighten-1",
        errors: "red darken-1",
        unresolved: "grey lighten-2",
      };
      return deepMerge({}, DEFAULT, this.classes);
    },
    prct() {
      return (this.resolved / this._total) * 100;
    },
    bars() {
      const bars = [];

      if (this.resolved)
        bars.push({
          props: {
            class: this._classes.resolved,
            style: {
              width: (this.resolved / this._total) * 100 + "%",
            },
          },
          count: this.resolved,
        });

      if (this.errors) {
        bars.push({
          props: {
            class: this._classes.errors,
            style: {
              width: (this.errors / this._total) * 100 + "%",
            },
          },
        });
      }

      if (this._unresolved)
        bars.push({
          props: {
            class: this._classes.unresolved,
            style: {
              width: (this._unresolved / this._total) * 100 + "%",
            },
          },
          count: this._unresolved,
        });

      return bars;
    },
  },
};
</script>

<style lang="scss" scoped>
.bar-container {
  width: 100%;
  height: 12px;
  display: flex;

  .status-bar {
    height: 100%;
    text-align: center;
    color: black;
    font-size: 9px;
    line-height: 12px;
  }
}
</style>
