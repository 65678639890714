<template>
  <cp-card>
    <template #title>
      <v-icon>{{ model.icon }}</v-icon>
      <span
        v-if="$route.name != 'home'"
        class="ml-2 grey--text font-weight-regular"
      >
        {{ model.vuexSingularName }}:
      </span>
      <v-btn text color="primary" class="pa-2" @click="statsVisible = true">
        <span class="text-h6 font-weight-bold">
          {{ summaryTitle }}
          <v-icon style="margin-top: -3px;">mdi-information-outline</v-icon>
        </span>
      </v-btn>
    </template>

    <div class="bun-group-summary__body">
      <service-summary-card
        v-for="(service, i) in services"
        :key="i"
        :service="$store.state.services[service]"
      />
    </div>

    <cp-dialog
      :show-model.sync="statsVisible"
      max-width="640"
      hide-scroll-indicator
    >
      <template #title> Stats for {{ auth.currentClient.name }} </template>

      <template #body>
        <v-sheet color="#F5F6FB" rounded class="pa-3" style="line-height: 1.4">
          <h6><span class="grey--text text--darken-2">Districts</span> 4</h6>
          <h6><span class="grey--text text--darken-2">Regions</span> 23</h6>
          <h6><span class="grey--text text--darken-2">Properties</span> 140</h6>
          <h6>
            <span class="grey--text text--darken-2">Units</span> 35,000
            <span class="grey--text text--darken-2 ml-2">EMPL</span> 1481
          </h6>
        </v-sheet>
      </template>
    </cp-dialog>
  </cp-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      statsVisible: false
    };
  },

  computed: {
    ...mapState({
      auth: state => state.auth,
      meta: state => state.meta
    }),

    services() {
      return this.clientServices.filter(x =>
        ["ingage", "intouch", "introduce"].includes(x)
      );
    },

    summaryTitle() {
      if (this.$route.name == "home") {
        return this.$store.state.auth.currentClient.name;
      }

      return this.model.item.name;
    }
  }
};
</script>

<style lang="scss" scoped>
.bun-group-summary__body {
  white-space: nowrap;
  overflow-x: auto;
}
</style>
