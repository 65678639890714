<template>
  <div>
    <cp-h1>
      {{ title }}

      <template v-if="!hasToken" #subtitle>
        <cp-breadcrumbs :items="breadcrumbs" />
      </template>
    </cp-h1>

    <v-card>
      <v-tabs v-model="currentTab" icons-and-text grow>
        <v-tab>
          Current Tasks
          <div>
            <v-icon>mdi-checkbox-marked-outline</v-icon>
          </div>
        </v-tab>
        <v-tab>
          Task History
          <div>
            <v-icon>mdi-history</v-icon>
          </div>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="currentTab">
        <v-tab-item>
          <!-- v-if causes the tables to re-render, which triggers fetch -->
          <current-tasks-table v-if="currentTab === 0" />
        </v-tab-item>
        <v-tab-item>
          <historical-tasks-table v-if="currentTab === 1" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { capitalize } from "@cp/utils/stringUtils";

export default {
  name: "MyTasks",
  data() {
    return {
      currentTab: 0,
    };
  },
  computed: {
    ...mapState("auth", ["hasToken"]),
    title() {
      const service = this.$route.params.service;
      if (service) return `My ${capitalize(service)} Tasks`;
      return "My Tasks";
    },
    breadcrumbs() {
      return [
        {
          text: "Home",
          icon: "mdi-home",
          to: { name: "alerts" },
          exact: true,
        },
        {
          text: this.title,
        },
      ];
    },
  },
};
</script>
