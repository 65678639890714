<template>
  <cp-explain>
    <template #activator="{on}">
      <div class="icons" v-on="on" v-bind="$attrs">
        <cp-icon-stack v-bind="iconProps" />
      </div>
    </template>
    <div v-html="explanation" />
  </cp-explain>
</template>

<script>
import { get } from "@cp/utils/objectUtils";
import { properize } from "@cp/utils/stringUtils";

export default {
  props: ["item", "header"],
  computed: {
    slug() {
      return get(this.item, this.header.value);
    },
    iconProps() {
      switch (this.slug) {
        case "agreement":
          return {
            icons: [["mdi-thumb-up-outline", { color: "success" }]],
          };
        case "importance":
          return {
            icons: [["mdi-flag", { color: "error" }]],
          };
        case "agreement-importance":
          return {
            icons: [
              ["mdi-thumb-up-outline", { color: "success" }],
              ["mdi-flag", { style: { left: "-20%" } }],
            ],
            addonColor: "error",
          };
        case "text-entry":
          return {
            icons: ["mdi-message-text-outline"],
          };
        case "yes-no":
          return {
            icons: [
              [
                "mdi-alpha-y",
                { style: { transform: "scale(0.8) translate(-35%, -25%)" } },
              ],
              [
                "mdi-slash-forward",
                { style: { transform: "scale(0.8) translate(-20%, 0)" } },
              ],
              [
                "mdi-alpha-n",
                { style: { transform: "scale(0.8) translate(5%, 25%)" } },
              ],
            ],
          };
        case "identify":
          return {
            icons: ["mdi-account"],
          };
        case "likelihood":
          return {
            icons: ["mdi-head-question-outline"],
          };
        case "multiple-select":
        case "multiple-choice":
          return {
            icons: ["mdi-format-list-checks"],
          };
        case "numeric-entry":
        case "rating":
          return {
            icons: ["mdi-numeric"],
          };
        case "employee-list":
          return {
            icons: ["mdi-account-multiple"],
          };
        default:
          return {
            icons: ["mdi-format-list-checkbox"],
          };
      }
    },
    explanation() {
      switch (this.slug) {
        default:
          return properize(this.slug);
      }
    },
  },
};
</script>

<style scoped>
.icons {
  width: 30px;
  height: 30px;
  position: relative;
}
</style>
