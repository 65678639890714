<template>
  <div>
    <v-sheet outlined class="pa-4 mb-4">
      <v-alert v-if="item.completed_at" type="success" color="green" dense>
        Marked "Complete" on
        <span class="font-weight-bold">{{ item.completed_at }}</span> by
        <!-- <span class="font-weight-bold">{{ item.completed_by.full_name }}</span> -->
        Backend Missing
      </v-alert>

      <key-value-item label="Resident:" :text="item.details.full_name" />
      <key-value-item label="Unit:" :text="item.details.unit" />
      <key-value-item label="Phone:" :text="item.details.phone" />
      <key-value-item label="Email:" :text="item.details.email" />
      <key-value-item label="Date Received:" :text="item.created_at" />
      <v-btn
        :href="item.details.link"
        color="primary"
        text
        rounded
        small
        target="_blank"
        style="margin-left: -12px;"
      >
        View Resident Survey on Intouch
        <v-icon small>mdi-chevron-right</v-icon>
      </v-btn>
    </v-sheet>

    <template v-if="!item.completed_at">
      <v-textarea
        v-model="item.comment"
        outlined
        label="Internal Comment (Required to Complete)"
        style="border-radius: 0"
      />

      <v-row>
        <v-spacer />
        <v-col cols="auto">
          <v-btn
            color="success"
            :disabled="!canSubmit"
            @click="completeMaintenanceRequest"
            >Complete</v-btn
          >
        </v-col>
      </v-row>
    </template>

    <template v-else>
      <h4>
        Comment from
        <!-- <a :href="`mailto:${item.completed_by.email}`">
          {{ item.completed_by.full_name }}
        </a> -->
        <a>
          Backend Missing
        </a>
      </h4>

      <v-sheet outlined class="pa-4 mt-2">
        <!-- {{ item.completed_by.comment }} -->
        Backend Missing
      </v-sheet>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      item: (state) => state.alerts.item,
    }),

    canSubmit() {
      return this.item.comment && this.item.comment.trim();
    },
  },

  methods: {
    completeMaintenanceRequest() {
      if (!this.canSubmit) return;

      this.$store
        .dispatch("alerts/updateItem", {
          ...this.item,
          completed: true,
          comment: this.item.comment.trim(),
        })
        .then(() => {
          this.$store.dispatch("alerts/closeItemDetailDialog");
          this.$store.dispatch("alerts/fetchItems");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-text-field--outlined::v-deep fieldset {
  border-color: rgba(0, 0, 0, 0.12);
}
</style>
