<template>
  <span>
    <v-btn :to="linkTo" color="primary" small>
      <v-icon left>mdi-code-greater-than</v-icon> View Docs
    </v-btn>
    <v-btn @click="log" color="primary" class="ml-2" small>
      <v-icon left>mdi-code-greater-than</v-icon> Log class to console
    </v-btn>
  </span>
</template>

<script>
export default {
  props: {
    instance: { required: true, type: Object },
  },
  computed: {
    linkTo() {
      return this.$store.getters[this.instance.p.g.widgetDocsRoute];
    },
  },
  methods: {
    log() {
      console.log(this.instance);
      console.log(this);
    },
  },
};
</script>
