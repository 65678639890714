import { render, staticRenderFns } from "./UnlikelyToRenew.vue?vue&type=template&id=21859538&scoped=true&"
import script from "./UnlikelyToRenew.vue?vue&type=script&lang=js&"
export * from "./UnlikelyToRenew.vue?vue&type=script&lang=js&"
import style0 from "./UnlikelyToRenew.vue?vue&type=style&index=0&id=21859538&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21859538",
  null
  
)

export default component.exports