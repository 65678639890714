<template>
  <card icon="account-heart" title="Resident Satisfaction" root-slot>
    <line-chart v-bind="props" />
  </card>
</template>

<script>
import widgetMixin from "@/widgets/mixin";
import { mapStrToQLabel } from "@/lib/quarterOfYear";

export default {
  mixins: [widgetMixin],
  computed: {
    props() {
      const { timelines, labels, yMin: minYValue, yMax: maxYValue } = this.data;
      const ySpan = maxYValue - minYValue;
      const yPad = ySpan * 0.1; // pad by 40%
      const yMin = Math.min(Math.round(minYValue - yPad), 50);
      const yMax = Math.min(Math.round(maxYValue + yPad), 100);

      const datasets = [
        {
          fill: true,
          borderColor: "rgba(230, 126, 34, 1)",
          backgroundColor: "rgba(230, 126, 34, .1)",
          pointBackgroundColor: "rgba(230, 126, 34, 1)",
          borderWidth: 2,
          lineTension: 0.3,
          order: 2,
          data: timelines.result_timeline.map((y, x) => ({ x, y })),
          label: this.parent.name,
        },
        {
          fill: false,
          borderColor: "#0070a8",
          backgroundColor: "rgba(0,112,168,.1)",
          pointBackgroundColor: "#0070a8",
          borderWidth: 2,
          lineTension: 0.3,
          order: 2,
          data: timelines.sbx_timeline.map((y, x) => ({ x, y })),
          label: "SB Index",
        },
        {
          fill: false,
          borderColor: "#685478",
          backgroundColor: "rgba(104,84,120,.1)",
          pointBackgroundColor: "#685478",
          borderWidth: 2,
          lineTension: 0.3,
          order: 2,
          data: timelines.client_timeline.map((y, x) => ({ x, y })),
          label: "Company",
        },
      ];

      return {
        id: "satisfaction-line",
        height: 400,
        showDetails: true,
        responsive: true,
        yMin,
        yMax,
        chartData: {
          labels: labels.map(mapStrToQLabel),
          datasets,
          yMin,
          yMax,
        },
        options: {
          plugins: {
            legend: {
              display: true,
              labels: { usePointStyle: true, pointStyle: "line" },
              position: "bottom",
            },
          },
        },
      };
    },
  },
};
</script>
