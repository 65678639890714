const required = true;

export const profile = {
  title: { type: String, required },
  card: { type: Boolean, default: false },
  image: { type: String, required },
  stats: { type: Array, default: () => [[]] },
  region: { type: String, default: "" },
  location: { type: String, default: "" },
  managers: { type: Array, default: () => [] },
  maintenance_managers: { type: Array, default: () => [] },
};

export const scores = {
  title: { type: String, required },
  card: { type: Boolean, default: false },
  location: { type: Number, default: null },
  region: { type: Number, default: null },
  area: { type: Number, default: null },
  company: { type: Number, default: null },
  sbx: { type: Number, default: null },
};

export const percentage = {
  title: { type: String, required },
  card: { type: Boolean, default: false },
  value: { type: Number, required },
  icon: { type: String, required },
};

export const statTypes = {
  profile,
  scores,
  percentage,
};
