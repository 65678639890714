<template>
  <div>
    <v-sheet outlined class="pa-4 mb-4">
      <v-alert v-if="item.completed_at" type="success" color="green" dense>
        Marked "Complete" on
        <span class="font-weight-bold">{{ item.completed_at }}</span> by
        <!-- <span class="font-weight-bold">{{ item.completed_by.full_name }}</span> -->
        Backend Missing
      </v-alert>

      <key-value-item label="Resident:" :text="item.details.full_name" />
      <key-value-item label="Unit:" :text="item.details.unit" />
      <key-value-item label="Phone:" :text="item.details.phone" />
      <key-value-item label="Email:" :text="item.details.email" />
      <key-value-item label="Date Received:" :text="item.created_at" />
      <v-btn
        :href="item.details.link"
        color="primary"
        text
        rounded
        small
        target="_blank"
        style="margin-left: -12px;"
      >
        View Resident Survey on Intouch
        <v-icon small>mdi-chevron-right</v-icon>
      </v-btn>
    </v-sheet>

    <v-row v-if="item.status == 'created'">
      <v-spacer />
      <v-col cols="auto">
        <v-btn color="success" @click="completeAlert">Mark Resolved</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      item: (state) => state.alerts.item,
    }),
  },

  methods: {
    completeAlert() {
      this.$store
        .dispatch("alerts/updateItem", {
          ...this.item,
          completed: true,
        })
        .then(() => {
          this.$store.dispatch("alerts/closeItemDetailDialog");
          this.$store.dispatch("alerts/fetchItems");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-text-field--outlined::v-deep fieldset {
  border-color: rgba(0, 0, 0, 0.12);
}
</style>
