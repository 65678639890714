import { ItemFormModal } from "@cp/store/mixins";
import { requiredIf } from "@cp/utils/rules";

class statusForm extends ItemFormModal {
  fetch(ctx, args) {
    return super.fetch(ctx, args).then(({ data, response }) => {
      ctx.state[this.keys.formData].tasker_action =
        data.tasker_actions[0] || "";
      return { data, response };
    });
  }
}

export const taskStatusForm = new statusForm({
  module: "taskStatusForm",
  baseUrl: `${process.env.VUE_APP_FIVER_API_PATH}/en/v1/user_tasks`,
  clientConfig: {
    prioritizeTokenOverCookie: true,
  },
  routeName: "MyTasks",
  routeParamId: "",
  routeQueryId: "user_task_id",
  url: "/:id",
  urlTemplate: true,
  initialValue: {
    completed_at: "",
    tasker_actions: [],
    task: {},
    on_behalf_of: {},
    assignees: [],
    plans: [],
    files: [],
    due_by_date: "",
    abandon_date: "",
    escalate_date: "",
    user_task_progress_status_id: 1,
    task_archetype: { value: 2 },
    notes: [],
  },
  fields: {
    tasker_action: {
      initialValue: "complete",
    },
    note: {
      rules: [requiredIf(({ tasker_action }) => tasker_action != "complete")],
    },
  },
});
taskStatusForm.taskerActions = {
  complete: {
    text: "Mark Complete",
    icon: "mdi-check-bold",
  },
  cant_complete: {
    text: "Mark Can't Complete",
    icon: "mdi-checkbox-blank-off-outline",
  },
  update_note: {
    text: "Add Note",
    icon: "mdi-pencil-box-outline",
  },
};

export default taskStatusForm.toVuex;
