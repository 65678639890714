<template>
  <card v-if="stats.length">
    <v-row>
      <v-col
        v-for="(stat, i) in _stats"
        :key="i"
        v-bind="stat.colProps || {}"
        cols="6"
        md="auto"
        class="flex-grow-1"
      >
        <div class="text-center">
          <div class="value">
            {{ stat.value }}
          </div>
          <div class="label">
            <cp-explain v-if="stat.explainText">
              <template #activator="{on}">
                <span v-on="on">
                  <v-icon :size="16">mdi-information-outline</v-icon>
                  <span>
                    {{ stat.label }}
                  </span>
                </span>
              </template>
              {{ stat.explainText }}
            </cp-explain>
            <span v-else>
              {{ stat.label }}
            </span>
          </div>
        </div>
      </v-col>
    </v-row>
  </card>
</template>

<script>
import { nonEmpty } from "@cp/utils/itertees";

import digAndFormatStat from "@/lib/digAndFormatStat";

export default {
  props: {
    data: { type: Object },
    stats: { type: Array, default: () => [] },
    basePath: { type: String, default: "" },
  },
  computed: {
    _stats() {
      return this.stats.reduce(
        (
          r,
          [
            label,
            path,
            { basePath = this.basePath, explain = () => {}, ...args } = {},
          ]
        ) => {
          let value = digAndFormatStat(this.data, {
            basePath,
            path,
            ...args,
          });
          if (nonEmpty(value))
            r.push({ label, value, explainText: explain(this.data) });
          return r;
        },
        []
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.value {
  font-size: 2.5rem;
  line-height: 3.5rem;
  font-weight: lighter;
}
.label {
  white-space: nowrap;

  span {
    vertical-align: middle;
  }
}
</style>
