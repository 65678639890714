<template>
  <cp-loading :loading="loading">
    <cp-unhandled-errors
      v-if="!isEmpty(errors)"
      :formConf="{ unhandledErrors: errors }"
    />
    <widget
      v-else
      v-bind="{ data, meta, config }"
      ref="widget"
      @initialized="setFiltersParams"
    />
  </cp-loading>
</template>

<script>
import { Client } from "@cp/lib";
import { mapErrors } from "@cp/utils/jsonapiUtils";
import { isEmpty, get, deepMerge } from "@cp/utils/objectUtils";

import { pageMixin } from "@/widgets/mixins";

const client = new Client({
  baseUrl: `${process.env.VUE_APP_HAYSTACK_API_PATH}/en/v1`,
});

export default {
  mixins: [pageMixin],
  props: {
    url: { type: String, required: true },
    widgetId: { type: String, default: "" },
    config: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      loading: true,
      errors: {},
      data: null,
      meta: {},
    };
  },
  methods: {
    isEmpty,
    setFiltersParams(instance) {
      /* soft set filterParams state
       * why? because if we add filters into config, it gets put on widgetParams
       * and if we set defaultFilters, then the reset button won't work
       * so we have to manually update the filterParams state to match the URL
       */
      const { scope, metric, ...filters } = this.$route.query;
      const filterParams = get(this.$store.state, instance.p.s.filterParams);
      deepMerge(filterParams, filters);
      this.$emit("initialized", instance); // pass it up the DOM!
    },
  },
  async mounted() {
    const params = this.config.params || {};
    try {
      const response = await client.get(this.url, params);
      const { data, meta } = response.data;
      this.data = data;
      if (this.widgetId) {
        meta.widget.id = this.widgetId;
      }
      this.meta = meta;
      await this.$nextTick();
    } catch (errors) {
      this.errors = mapErrors(errors);
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style scoped>
.loading {
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 400px;
}
</style>
