import { isEmpty } from "@cp/utils/objectUtils";

export function roundPrct(value) {
  if (value === undefined) return;
  return Math.round(value) + "%";
}

export function coaxRoundPrct(value) {
  let result = parseFloat(value);
  if (isNaN(result)) return "N/A";
  if (result < 1 && result > 0) result *= 100;
  // no deimals
  // const addDecimal = result < 10;
  // if (addDecimal) result *= 10;
  result = Math.round(result);
  // if (addDecimal) result /= 10;
  return result + "%";
}

// round or dash
export function score(value) {
  if (isEmpty(value)) return "-";
  return Math.round(value);
}

// this is for logic that cannot use defaultCells
export const defaultColumnFormats = {
  agreement_importance: score,
  response_rate: roundPrct,
  likely_to_remain_percentage: roundPrct,
  likely_to_renew_percentage: roundPrct,
};
