var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('date-picker',_vm._b({attrs:{"outlined":"","dense":""},on:{"input":_vm.fields.last_day.input}},'date-picker',_vm.fields.last_day.attrs,false)),_c('v-text-field',_vm._b({attrs:{"outlined":"","dense":""},on:{"input":_vm.fields.personal_email.input}},'v-text-field',_vm.fields.personal_email.attrs,false)),_c('cp-unhandled-errors',{attrs:{"formConf":_vm.formConf}}),(!_vm.success)?_c('cp-form-modal-success-or-buttons',{attrs:{"formConf":_vm.formConf,"modalConf":{ close: () => _vm.$emit('close') },"buttons":_vm.buttons,"buttonConf":{
      confirm: {
        action: 'submit',
        text: 'Confirm',
        icon: 'mdi-account-check-outline',
        props: { color: 'primary' },
      },
      cantComplete: {
        action: 'cantComplete',
        text: 'Can\'t Complete',
        icon: 'mdi-cancel',
        props: { color: 'white' },
      },
    },"noun":"Notice","success-message":"Employee Data Confirmed!"},on:{"submit":_vm.submit,"cantComplete":function($event){return _vm.$emit('update:cant-complete', true)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }