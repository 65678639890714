const widgetFiles = require.context("@/widgets", true, /\.(vue)$/i);
const widgets = widgetFiles.keys().reduce((result, path) => {
  const componentName = path
    // "If you used regex to solve a problem, all you did was create two more." - Michael Craig
    // https://imgs.xkcd.com/comics/perl_problems.png
    // remove starting ./
    .replace(/^\.\//, "")
    // remove ending .vue
    .replace(/\.vue$/, "")
    // remove all other "/" what can go wrong?
    .replace(/\//g, "");
  const component = widgetFiles(path).default;
  result[componentName] = component;
  return result;
}, {});

export default widgets;
