<template>
  <cp-loading :loading="scopeChangeLoading">
    <v-data-table
      v-bind="$attrs"
      v-on="$listeners"
      @click:row="toggleExpandable"
      :expanded.sync="expanded"
      :show-expand="showExpand"
      :headers="headers"
      class="expandable-table"
      :class="{ 'clickable-rows': showExpand }"
      ref="parentTable"
    >
      <slot v-for="(_, name) in $slots" :name="name" :slot="name"></slot>
      <template
        v-for="(_, name) in $scopedSlots"
        :slot="name"
        slot-scope="slotData"
        ><slot :name="name" v-bind="slotData"></slot
      ></template>

      <template #item.data-table-expand="{isExpanded}">
        <v-icon color="primary">
          mdi-{{ isExpanded ? "table-minus" : "table-plus" }}
        </v-icon>
      </template>

      <template #expanded-item="{item}">
        <expanded-item-sub-table
          :itemId="(item.id || item.m_id).toString()"
          :instance="subTableInstances[item.id || item.m_id]"
          :scopeHeaders="scopeHeaders"
          :parentScope="scope"
          @changedSubScope="x => (selectedSubScope = x)"
          :parent-show-select="$attrs.hasOwnProperty('show-select')"
        >
          <slot
            v-for="(_, name) in passed.slots"
            :name="name"
            :slot="name"
          ></slot>
          <template
            v-for="(_, name) in passed.scopedSlots"
            :slot="name"
            slot-scope="slotData"
            ><slot :name="name" v-bind="slotData"></slot
          ></template>
        </expanded-item-sub-table>
      </template>

      <template v-for="(component, key) in defaultCells" v-slot:[key]="data">
        <component
          :is="component"
          :key="`row-${data.index}-${key}`"
          v-bind="data"
        />
      </template>
    </v-data-table>
  </cp-loading>
</template>

<script>
import pluralize from "pluralize";

import { capitalize } from "@cp/utils/stringUtils";
import { get, getFirst, pick, exclude } from "@cp/utils/objectUtils";
import { intersection } from "@cp/utils/arrayUtils";
import { flattenPath } from "@cp/utils/pathUtils";
import { wait } from "@cp/utils/promiseUtils";

import { loadWidget, unregisterWidget } from "@/store/modules/widgets";
import defaultCells from "@/components/tables/cells/defaultCells";

const doNotPassSlots = ["body.prepend"];

export default {
  props: {
    instance: { type: Object, required: true },
    scope: { type: String, required: true },
    scopeHeaders: { type: Object, required: true },
    scopeChangeLoading: { type: Boolean, default: false },
  },
  data() {
    return {
      expanded: [],
      selectedSubScope: "",
      subTableInstances: {},
    };
  },
  computed: {
    subScopes() {
      const path = flattenPath(
        this.instance.modulePath,
        "subScopes",
        this.scope
      );
      return get(this.$store.state, path, []);
    },
    scopeAndFilters() {
      const scope = this.scope;
      const filterKeys = Object.keys(
        this.$store.getters[this.instance.p.g.params].filters || {}
      );
      return [scope, ...filterKeys];
    },
    headers() {
      const headers = get(
        this.scopeHeaders,
        this.scope,
        this.scopeHeaders.default
      );
      // may need to add more args in future for showIf features
      const args = { scope: this.scope };
      return headers.reduce(
        (r, { showIf, hideIfScopeOrFilters, ...header }) => {
          if (showIf && typeof showIf === "function" && !showIf(args)) return r;
          if (
            hideIfScopeOrFilters &&
            intersection(this.scopeAndFilters, hideIfScopeOrFilters).length
          )
            return r;
          r.push(header);
          return r;
        },
        []
      );
    },
    showExpand() {
      return !!this.subScopes.length;
    },
    overriddenSlotKeys() {
      return [...Object.keys(this.$slots), ...Object.keys(this.$scopedSlots)];
    },
    defaultCells() {
      const cells = Object.keys(defaultCells).filter(
        key => !this.overriddenSlotKeys.includes(key)
      );
      return pick(defaultCells, cells);
    },
    passed() {
      return {
        slots: exclude(this.$slots, doNotPassSlots),
        scopedSlots: exclude(this.$scopedSlots, doNotPassSlots),
      };
    },
    filters() {
      return this.$store.getters[this.instance.p.g.filterValues];
    },
  },
  methods: {
    pluralize,
    capitalize,
    async toggleExpandable(item, { expand, isExpanded } = {}) {
      if (!this.showExpand) return;
      const opening = !isExpanded;
      const itemId = getFirst(item, ["id", "m_id"]);
      if (
        !this.selectedSubScope ||
        !this.subScopes.includes(this.selectedSubScope)
      )
        this.selectedSubScope = this.subScopes[0];
      if (opening) {
        const moduleId = `${this.instance.id}SubWidget${itemId}`;
        const subModuleFilters = exclude(this.filters, [this.scope]);
        const instance = await loadWidget({
          parent: this.instance,
          url: this.instance.baseUrl + this.instance.url,
          moduleId,
          query: {
            scope: this.selectedSubScope,
            [this.scope]: [itemId],
            ...subModuleFilters,
          },
        });
        this.subTableInstances[itemId] = instance;
        await wait(10);
        expand(opening);
      } else {
        expand(opening);
        // wait for animation and de-render before de-registering the table
        await wait(300);
        unregisterWidget(this.subTableInstances[itemId]);
        delete this.subTableInstances[itemId];
      }
    },
  },
  watch: {
    scope() {
      this.expanded = [];
      Object.keys(this.subTableInstances).forEach(key => {
        unregisterWidget(this.subTableInstances[key]);
        delete this.subTableInstances[key];
      });
    },
    filters() {
      const subModuleFilters = exclude(this.filters, [this.scope]);
      Object.keys(this.subTableInstances).forEach(key => {
        this.$store.dispatch(
          this.subTableInstances[key].p.a.updateFilter,
          subModuleFilters
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none;
}
</style>

<style lang="scss">
.htmlToCanvas {
  .v-data-table__expanded__content {
    box-shadow: none !important;
  }
  .SubTable:after {
    box-shadow: none !important;
  }
}
</style>
