<template>
  <v-card>
    <v-card-title
      v-if="$slots.title || title || actions.length"
      class="Card__title"
      :class="{
        'pl-3': icon || cpIcon,
        'mb-0': rootSlot,
      }"
    >
      <slot name="icon">
        <span class="Card__icon">
          <v-icon v-if="icon" :color="iconColor">mdi-{{ icon }}</v-icon>
          <cp-icon v-else-if="cpIcon" :icon="cpIcon" :color="iconColor" />
        </span>
      </slot>
      <slot name="title">
        <span class="heading-6">{{ title }}</span>
      </slot>

      <v-spacer />

      <slot name="actions">
        <template v-if="actions.length">
          <!-- todo - action menu from fiver-ui -->
          <v-icon>mdi-dots-horizontal</v-icon>
        </template>
      </slot>
    </v-card-title>
    <slot v-if="rootSlot" />
    <v-card-text v-else>
      <slot />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    icon: { type: String },
    cpIcon: { type: String },
    iconColor: { type: String, default: "currentColor" },
    title: { type: String },
    actions: { type: Array, default: () => [] },
    rootSlot: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
.Card__title {
  border-bottom: 1px solid $gray-2;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-bottom: 15px;
  color: $gray-4;
}
.Card__icon {
  margin-right: 10px;
}
</style>
