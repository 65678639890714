<template>
  <v-card>
    <v-card-text>
      <div class="mc-responses">
        <div
          v-if="!total || total <= 0"
          class="font-weight-bold text-center mt-3"
        >
          No Responses
        </div>

        <template v-else>
          <h5 class="mc-response-header">
            Total Responses: <span class="font-weight-bold">{{ total }}</span>
          </h5>
          <div
            class="mc-response-wrapper"
            v-for="(response, index) in responses"
            :key="index"
          >
            <h5 class="mc-response-text">
              <span class="font-weight-bold mr-2">{{ response.text }}</span>
              {{ response.value }}
            </h5>
            <div
              class="mc-response-bar"
              :style="
                `width: ${percentage(
                  response.value
                )}%; background-color: ${getColor(response)};`
              "
            ></div>
          </div>
        </template>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import intersect from "vuetify/lib/directives/intersect";

import { get } from "@cp/utils/objectUtils";

import { widgetMixin, tableMixin } from "@/widgets/mixins";

export default {
  directives: { intersect },
  mixins: [widgetMixin, tableMixin],
  computed: {
    responses() {
      return get(this.data, [0, "response_summary", "multiple_select"], []);
    },

    total() {
      let values = this.responses.map(x => x.value);
      return values.reduce((partialSum, val) => partialSum + val, 0);
    },

    max() {
      return this.responses[0].value;
    },
  },
  methods: {
    percentage(value) {
      return (value / this.max) * 100;
    },

    getColor(response) {
      let percentage = this.percentage(response.value);
      let amount = 50 * (percentage / 100);
      let final = 240 - Math.round(amount);
      return `rgb(${final}, ${final}, ${final * 1.45})`;
    },
  },
};
</script>

<style lang="scss" scoped>
.mc-responses {
  text-align: left;
  margin-bottom: 12px;

  .mc-response-header {
    position: relative;
    left: 6px;
  }

  .mc-response-wrapper {
    position: relative;
    height: 28px;
    margin-top: 2px;
    margin-bottom: 2px;

    .mc-response-text {
      position: relative;
      top: 6px;
      left: 6px;
      z-index: 2;
    }

    .mc-response-bar {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      height: 100%;
    }
  }
}

.text-h6 {
  background: rgba($gray-3, 0.25);
  margin-bottom: 14px;
  padding: 4px 16px;
}

.commentsInMonth {
  padding: 0 16px;
}

// ::v-deep .v-alert--text {
//   padding-top: 4px;
//   padding-bottom: 0;
// }

.commentQuestion {
  color: $gray-4;
  margin-bottom: 2px;
}

.commentDate {
  color: $gray-4;
  text-align: right;

  margin-top: 8px;
}
</style>
