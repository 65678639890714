import cloneDeep from "clone-deep";
import pluralize from "pluralize";

import {
  mergeMixins,
  FormMixin,
  ItemForm,
  FormModal,
  ApiDataMixin,
  HaystackSearch,
} from "@cp/store/mixins";
import { mapItem } from "@cp/utils/jsonapiUtils";
import { parse, toMDY, toYMD } from "@cp/utils/dateUtils";
import { keyBy } from "@cp/utils/arrayUtils";
import { get } from "@cp/utils/objectUtils";
import { required, requiredIf, email } from "@cp/utils/rules";
import { modalAddon } from "carrot-patch-v2/src/store/addons";
import eventbus from "@cp/lib/CpEventBus";

const fiverEmployeeUrl = `${process.env.VUE_APP_HAYSTACK_API_PATH}/en/v1/user_tasks/employee_view`;

export const completedTypes = [
  {
    id: 1,
    icon: "mdi-checkbox-marked-outline",
    color: "#008a09",
    props: { color: "success" },
  },
  {
    id: 2,
    icon: "mdi-checkbox-blank-outline",
    color: "#9e9e9e",
    props: { color: "grey" },
  },
  {
    id: 3,
    icon: "mdi-checkbox-blank-off-outline",
    color: "#f0b323",
    props: { color: "warning" },
  },
  {
    id: 4,
    icon: "mdi-checkbox-blank-off-outline",
    color: "#0070a8",
    props: { color: "0070a8" },
  },
];
export const completedTypeObj = keyBy(completedTypes, "id");
export const statuses = [
  {
    id: 1,
    icon: "mdi-checkbox-blank-outline",
    title: "Scheduled",
    color: "#0070a8",
    chipProps: {
      color: "#0070a8",
      textColor: "white",
    },
  },
  {
    id: 2,
    icon: "mdi-checkbox-blank-outline",
    title: "Pending",
    color: "#FEDC08",
    chipProps: {
      color: "#FEDC08",
      class: "grey--text text--darken-3",
    },
  },
  {
    id: 3,
    icon: "mdi-checkbox-blank-off-outline",
    title: "Past Due",
    color: "#FBAF57",
    chipProps: {
      color: "#FBAF57",
      class: "grey--text text--darken-3",
    },
  },
  {
    id: 4,
    icon: "mdi-timer-alert-outline",
    title: "Completed",
    color: "#008A09",
    chipProps: {
      color: "#E40000",
      textColor: "white",
    },
  },
];
export const taskStatuses = keyBy(statuses, "title");
export const taskStatusesById = keyBy(statuses, "id");
export const enrollmentStatuses = [
  {
    id: 1,
    text: "Enrolled",
    icon: "mdi-account-check",
  },
  {
    id: 2,
    text: "Unenrolled",
    icon: "mdi-playlist-remove",
  },
  {
    id: 3,
    text: "Canceled",
    icon: "mdi-cancel",
  },
  {
    // this should never show up
    id: 4,
    text: "Purged",
    icon: "mdi-delete-alert-outline",
  },
  {
    id: 5,
    text: "Completed",
    icon: "mdi-check-all",
  },
];
export const enrollmentStatusObj = keyBy(enrollmentStatuses, "id");

export const marigoldEmployee = new ItemForm({
  module: "employee",
  baseUrl: `${process.env.VUE_APP_MARIGOLD_API_PATH}/en/v1/manage_employee`,
  url: "/:id",
  urlTemplate: true,
  compoundId: true,
  defaultHint: ({ rules = [] }) => (rules.includes(required) ? "Required" : ""),
  defaultPersistentHint: ({ rules = [] }, touched) =>
    !touched && rules.includes(required),
  clientConfig: {
    prioritizeTokenOverCookie: true,
  },
  initialValue: {
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    cell_phone: "",
    start_date: "",
    locale: "en",
    last_archived_at: "",
    employee_id: "",
    position: "",
    position_id: "",
    location: "",
    location_id: "",
    avatar: {
      url: "",
      thumb: {
        url: "",
      },
    },
  },
  fields: {
    first_name: { label: "First Name", rules: [required] },
    last_name: { label: "Last Name", rules: [required] },
    location_id: { label: "Location", rules: [required] },
    position_id: { label: "Position", rules: [required] },
    employee_id: {
      label: "Employee ID",
    },
    start_date: {
      initialValue: toYMD(),
      label: "Start Date",
      rules: [required],
    },
    cell_phone: { label: "Cell Phone" },
    email: {
      label: "Work Email",
      rules: [required, email],
    },
    // manager_ids: { initialValue: [], label: "Managers" },
    // personal_email: { label: "Personal Email", rules: [email] },
    locale: {
      initialValue: "en",
      label: "Language Preference",
      items: [
        { value: "en", text: "English" },
        { value: "es", text: "Spanish" },
      ],
    },
    // opt_out: {
    //   initialValue: false,
    //   label: "Opt Out of Surveys",
    //   class: "mt-1",
    // },
  },
  getters: {
    itemActions() {
      return [
        { label: "Archive" },
        { label: "Send Welcome Email" },
        { label: "Assign Manager" },
        { label: "Assign Position" },
        { label: "Assign As Location Manager" },
        { label: "Assign As Location Maintenance Manager" },
      ];
    },
  },
});
marigoldEmployee.add(
  modalAddon({
    modalName: "form",
    open({ dispatch, commit }, { id } = {}) {
      commit(this.keys.reset);
      if (id) dispatch("fetchItem", { id });
    },
    async close({ $store }, { success, created, id }) {
      if (success && created) {
        eventbus.$emit("redirect", {
          name: "teamEmployeeDetails",
          params: { id },
        });
      }
    },
  })
);

export const positionsSearch = new HaystackSearch({
  parent: marigoldEmployee,
  module: "positionsSearch",
  name: "positionsSearch",
  urlKey: "positions",
});

export const locationsSearch = new HaystackSearch({
  parent: marigoldEmployee,
  module: "locationsSearch",
  name: "locationsSearch",
  urlKey: "locations",
  params: { filters: { only_mine: true } },
});

marigoldEmployee.add({
  modules: { positionsSearch, locationsSearch },
});

export const planAvailability = new ApiDataMixin({
  module: "employee",
  name: "planAvailability",
  baseUrl: `${process.env.VUE_APP_HAYSTACK_API_PATH}/en/v1/team/plan_availability_check`,
  url: "/:id",
  urlTemplate: true,
  jsonApiResponse: false,
  initialValue: {
    onboading_plans: [],
    offboarding_plans: [],
    development_plans: [],
  },
});

const terminationTypes = [
  { text: "Involuntary Termination", value: 1 },
  { text: "Downsizing", value: 2 },
  { text: "Disposition of Asset", value: 3 },
  { text: "Elimination of Position", value: 4 },
  { text: "Voluntary Termination", value: 5 },
];
export const terminationTypeObj = keyBy(terminationTypes, "value");

const twoWeeksFromNow = toYMD(parse().add(2, "weeks"));

export const giveNoticeForm = new FormModal({
  module: "employee",
  baseUrl: `${process.env.VUE_APP_MARIGOLD_API_PATH}/en/v1/manage_employee/give-notice`,
  url: "/:id",
  urlTemplate: true,
  name: "giveNotice",
  fields: {
    termination_categories_id: {
      label: "Type of termination",
      items: terminationTypes,
      rules: [required],
    },
    reason: {
      label: "Reason given for leaving",
      rules: [
        requiredIf(({ termination_categories_id: typeId }) => typeId === 5),
      ],
    },
    // location_id: { label: "Location", rules: [required] },
    // position_id: { label: "Position", rules: [required] },
    last_day: {
      initialValue: twoWeeksFromNow,
      label: "Last Day",
      rules: [required],
    },
  },
});

export const acknowledgeNoticeForm = new FormMixin({
  module: "employee",
  name: "acknowledgeNotice",
  baseUrl: `${process.env.VUE_APP_MARIGOLD_API_PATH}/en/v1/manage_employee`,
  url: "/:id",
  urlTemplate: true,
  compoundId: true,
  initialValue: {
    id: "",
    personal_email: "",
    last_day: "",
  },
  fields: {
    personal_email: { label: "Personal Email", rules: [email] },
    last_day: {
      initialValue: "",
      label: "Last Day",
      rules: [required],
    },
  },
});

export const employeeFiverReport = new ApiDataMixin({
  module: "employee",

  baseUrl: fiverEmployeeUrl,
  url: "/:id",
  urlTemplate: true,

  name: "employeeFiverReport",
  jsonApiResponse: false,
  initialValue: {
    employee_details: {},
    enrollments: [],
    tasks: [],
  },
  getters: {
    // I'm doing this here because I want the statuses to be included in employeeEnrollments
    // employeeReportTasks({ employeeFiverReport }) {
    //   const tasks = cloneDeep(employeeFiverReport.tasks);
    //   tasks.forEach(task => {
    //     const dates = {
    //       completedAt: task.completed_at
    //         ? parse(task.completed_at).startOf("day")
    //         : false,
    //       dueDate: parse(task.due_by_date).startOf("day"),
    //       today: parse().startOf("day"),
    //     };
    //     const dueDateDiffBy = task.completed_at
    //       ? dates.completedAt
    //       : dates.today;
    //     const dueDateDiff = dates.dueDate.diff(dueDateDiffBy, "days");

    //     let when = "";
    //     if (task.user_task_progress_status_id === 2) {
    //       // when = `<span style="color:#008A09">on time</span>`;
    //       when = `on time`;
    //     } else if (
    //       task.user_task_progress_status_id === 3 &&
    //       dueDateDiff >= 0
    //     ) {
    //       const dueInDays = dates.dueDate.diff(dates.today, "days");
    //       const days = dueInDays
    //         ? `in ${pluralize("day", dueInDays, true)}`
    //         : "today";
    //       when = `<span>Due ${days}</span>`;
    //     } else if (dueDateDiff < 0) {
    //       const daysLate = pluralize("day", Math.abs(dueDateDiff), true);
    //       when = `<span style="color:#E40000">${daysLate} late</span>`;
    //     }
    //     // Going to have to come back and redue this... order of operation is no longer going to work
    //     const completedByName = (task.completed_by_name || "").trim();
    //     const by = completedByName
    //       ? `by <strong>${completedByName}</strong>`
    //       : "by admin";

    //     if (task.completed_at) {
    //       task.completion = 1;
    //       task.explanation = `Completed ${when} ${by} on ${toMDY(
    //         task.completed_at
    //       )}`;
    //     } else if (task.user_task_progress_status_id === 4) {
    //       // If the has status "completed", but doesn't have a completed_at date, show this:
    //       task.completion = 1;
    //       task.explanation = "Completed by admin";
    //     } else if (task.user_task_progress_status_id === 2) {
    //       task.completion = 2;
    //       task.explanation = when;
    //     } else if (task.user_task_progress_status_id === 3) {
    //       task.completion = 3;
    //       // task.explanation = `<span style="color:#FBAF57">Can't Complete</span> by ${
    //       task.explanation = `Can't complete ${by} on ${toMDY(
    //         task.updated_at
    //       )}`;
    //     } else if (task.user_task_progress_status_id === 4) {
    //       task.completion = 2;
    //       task.explanation = when;
    //     } else if (task.user_task_progress_status_id === 5) {
    //       task.completion = 3;
    //       // task.explanation = `<span style="color:#8B5DCB">Abandoned</span> automatically on ${toMDY(
    //       task.explanation = `Abandoned automatically on ${toMDY(
    //         task.abandon_date
    //       )}`;
    //     } else if (task.user_task_progress_status_id === 6) {
    //       task.completion = 3;
    //       task.explanation = `Canceled ${by} on ${toMDY(task.updated_at)}`;
    //     }
    //   });
    //   return tasks;
    // },
    employeeEnrollments({ employeeFiverReport }) {
      return employeeFiverReport.enrollments.map(x => {
        const tasks = employeeFiverReport.tasks.filter(task => {
          const p_ids = task.plans.map(y => y.id);
          return p_ids.includes(x.plan.id);
        });

        return { ...x, tasks };
      });
    },
  },
});
employeeFiverReport.mapData = response => {
  if (!response.data) throw { message: "NullReport" };
  const {
    enrollments: enrollmentsData = [],
    tasks: tasksData,
    ...data
  } = response.data;
  const enrollments = enrollmentsData.map(mapItem);
  const tasks = tasksData.map(mapItem);
  return { ...data, enrollments, tasks };
};

export default mergeMixins(
  marigoldEmployee,
  planAvailability,
  giveNoticeForm,
  acknowledgeNoticeForm,
  employeeFiverReport
);
