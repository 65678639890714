<script>
import { Pie } from "vue-chartjs/legacy";
import { Pie as ChartJSPie } from "chart.js/auto";

import { deepMerge } from "@cp/utils/objectUtils";

export default {
  extends: Pie,

  props: {
    chartData: { type: Object, default: () => {} },
    options: { type: Object, default: () => {} },
  },

  methods: {
    render() {
      const defaultOptions = {
        responsive: true,
        maintainAspectRatio: false,
      };

      const options = deepMerge({}, defaultOptions, this.options);
      this.renderChart(this.chartData, options);
    },
  },

  watch: {
    data() {
      this.render();
    },
  },

  mounted() {
    this.render();
  },
};
</script>
