// DANGEROUS.. let's see
import Vue from "vue";
import store from "@cp/store/appStore";

import { ApiDataWithMeta, HaystackSearch, Subquery } from "@cp/store/mixins";
import { wait } from "@cp/utils/promiseUtils";
import { deepMerge, get, getFirst } from "@cp/utils/objectUtils";
import { properize, snakeToCamelCase } from "@cp/utils/stringUtils";
import { nonEmpty } from "@cp/utils/itertees";

const DEFAULT_API_META = {
  additional_meta: {},
  filter_options: { general: [] },
  pagination: { current: 1, records: 0, last: 0, next: 0 },
};
const DEFAULT_PARAMS = {
  page: {
    number: 1,
    size: 15,
  },
};

export class Calculator extends ApiDataWithMeta {
  constructor({
    initialMeta: initialMetaArg = {},
    params: paramsArg = {},
    urlArgs = {},
    ...config
  }) {
    const initialMeta = deepMerge(DEFAULT_API_META, initialMetaArg);
    const params = deepMerge(DEFAULT_PARAMS, paramsArg);
    const initialValue = { data: [], meta: {}, addons: {} };
    super({ ...config, initialMeta, initialValue, params });
    this.instantiate({ urlArgs });

    const keys = {
      filters: `${this.keys.stateKey}Filters`,
      structures: `${this.keys.stateKey}Structures`,
      sorts: `${this.keys.stateKey}Sorts`,
      payload: `${this.keys.stateKey}Payload`,
      reset: `reset${this.keys.nameCaps}`,

      currentPage: `${this.keys.stateKey}CurrentPage`,
      pageCount: `${this.keys.stateKey}PageCount`,
    };

    this.add({
      keys,
      state: {
        [keys.filters]: [],
        [keys.structures]: [],
        [keys.sorts]: [],
      },
      getters: {
        [keys.getStructures]({ [this.keys.meta]: meta }) {
          return get(meta, "structures", false);
        },
        [keys.payload]({ [this.keys.params]: params }) {
          return params;
        },

        // coppied from Table.js
        [keys.currentPage]({ [this.keys.params]: params }) {
          return params.page.number;
        },
        [keys.pageCount](
          { [this.keys.meta]: meta },
          { [this.keys.currentPage]: currentPage }
        ) {
          const pageCount = getFirst(meta, [
            "pagination.last",
            "pagination.total_pages",
          ]);
          return pageCount || currentPage || 1;
        },
      },
      actions: {
        [keys.reset]: this.resetAction,
      },
    });
  }

  mapData(response) {
    return response.data;
  }

  async resetAction({ state, commit, dispatch }) {
    state[this.keys.loading] = true;
    for (const [key, value] of Object.entries(this.subModuleRefs)) {
      store.unregisterModule(value.modulePath);
      delete this.subModuleRefs[key];
    }
    state[this.keys.filters].splice(0, state[this.keys.filters].length);
    state[this.keys.structures].splice(0, state[this.keys.structures].length);
    state[this.keys.sorts].splice(0, state[this.keys.sorts].length);
    await wait(1000);
    commit(this.keys.reset);
    dispatch(this.keys.fetch);
  }

  dynamicAdd(submodule) {
    store.registerModule(submodule.modulePath, submodule.toVuex);
  }

  async fetch(ctx, args) {
    const requestConfig = { ...this.urlArgs, ...args };
    const { data, response } = await super.fetch(ctx, requestConfig);
    const {
      [this.keys.meta]: meta,
      [this.keys.filters]: filters,
      [this.keys.structures]: structures,
      [this.keys.sorts]: sorts,
      [this.keys.params]: params,
    } = ctx.state;

    const mSorts = get(meta, "additional_meta.sorts");
    if (mSorts) {
      if (!params.sort_by) Vue.set(params, "sort_by", "");
      if (!params.sort_desc) Vue.set(params, "sort_desc", false);
      const sortOptions = Object.entries(mSorts).map(([label, value]) => ({
        text: properize(label),
        value,
      }));
      sorts.splice(0, sorts.length, ...sortOptions);
    }

    if (meta.filters && nonEmpty(meta.filters)) {
      if (!params.filters) Vue.set(params, "filters", {});

      for (const filter of meta.filters || []) {
        // sometimes the BE will send snake case keys, so...
        // I'm doing snakeToCamel, to keep our keys consistent

        if (filter.sub_query) {
          const subqueryKey = snakeToCamelCase(
            [this.keys.stateKey, filter.key, "subquery"].join("_")
          );
          if (!this.subModuleRefs[subqueryKey]) {
            const submodule = new Subquery({
              parent: this,
              module: subqueryKey,
              key: filter.sub_query,
              name: snakeToCamelCase(filter.search),
              requestConfig: args,
            });
            this.dynamicAdd(submodule);
            filters.push({
              key: filter.key,
              type: filter.type,
              attrs: {
                items: get(store.state, submodule.p.s.stateKey),
                loading: get(store.state, submodule.p.s.loading),
                label: filter.label,
                clearable: true,
              },
              input: v => {
                if (nonEmpty(v)) {
                  params.filters = {
                    ...params.filters,
                    ...{ [filter.key]: v },
                  };
                } else {
                  Vue.delete(params.filters, filter.key);
                }
                store.dispatch(this.p.a.fetch);
              },
              search: v => store.dispatch(submodule.p.a.search, v),
              focus: () => store.dispatch(submodule.p.a.fetch),
            });
          }
        } else if (filter.search) {
          const searchKey = snakeToCamelCase(
            [this.keys.stateKey, filter.key, "search"].join("_")
          );
          if (!this.subModuleRefs[searchKey]) {
            const submodule = new HaystackSearch({
              parent: this,
              module: searchKey,
              urlKey: filter.search,
              name: snakeToCamelCase(filter.search),
              requestConfig: args,
              loadMore: true,
            });
            this.dynamicAdd(submodule);
            filters.push({
              key: filter.key,
              type: filter.type,
              attrs: {
                items: get(store.state, submodule.p.s.stateKey),
                loading: get(store.state, submodule.p.s.loading),
                value: params[filter.key],
                label: filter.label,
                itemText: "label",
                clearable: true,
                searchInput: null,
              },
              input: v => {
                if (nonEmpty(v)) {
                  params.filters = {
                    ...params.filters,
                    ...{ [filter.key]: v },
                  };
                } else {
                  Vue.delete(params.filters, filter.key);
                }
                store.dispatch(this.p.a.fetch);
              },
              search: q_text =>
                store.dispatch(submodule.p.a.updateParams, { q_text }),
              focus: () => store.dispatch(submodule.p.a.fetch),
            });
          }
        } else {
          const found = filters.find(x => x.key === filter.key);
          if (found) {
            // update in case the BE sent different values
            if (filter.values.length) found.attrs.items = filter.values;
            found.attrs.label = filter.label;
          } else {
            filters.push({
              key: filter.key,
              type: filter.type,
              attrs: {
                items: filter.values,
                value: params.filters[filter.key],
                label: filter.label,
                multiple: filter.multiple,
                itemText: "label",
                clearable: true,
              },
              input: v => {
                if (nonEmpty(v)) {
                  params.filters = {
                    ...params.filters,
                    ...{ [filter.key]: v },
                  };
                } else {
                  Vue.delete(params.filters, filter.key);
                }
                store.dispatch(this.p.a.fetch);
              },
            });
          }
        }
      }
    }

    if (meta.structures && !params.structures) {
      Vue.set(params, "structures", {});
    }

    const metaStructures = Array.isArray(meta.structures)
      ? meta.structures
      : [];
    for (const structure of metaStructures) {
      const found = structures.find(x => x.key === structure.key);
      if (found) {
        // update in case the BE sent different values
        found.attrs.items = structure.values;
        found.attrs.label = structure.label;
        found.multiple = structure.type === "multi";
      } else {
        structures.push({
          key: structure.key,
          type: structure.type,
          attrs: {
            items: structure,
            value: params.structures[structure.key],
            label: structure.label,
            itemText: "label",
            clearable: true,
            multiple: structure.type === "multi",
          },
          input: v => {
            if (nonEmpty(v)) {
              params.structures = {
                ...params.structures,
                ...{ [structure.key]: v },
              };
            } else {
              Vue.delete(params.structures, structure.key);
            }
            store.dispatch(this.p.a.fetch);
          },
        });
      }
    }

    return { data, response };
  }

  get methods() {
    const $calculator = this;
    if (!$calculator.modulePath.length) {
      console.log(this);
      throw "Calculator needs modulePath in order to build its methods";
    }
    return {
      fetch(args) {
        return store.dispatch($calculator.p.a.fetch, args);
      },
      reset() {
        return store.dispatch($calculator.p.a.reset);
      },
      changeDataOptions({ sortBy, sortDesc, page, itemsPerPage }) {
        return store.dispatch($calculator.p.a.updateParams, {
          sort_by: sortBy,
          sort_desc: sortDesc,
          page: {
            number: page,
            size: itemsPerPage,
          },
        });
      },
    };
  }
}
