<template>
  <card icon="account-voice" title="Employee Engagement" root-slot>
    <line-chart v-bind="props" />
  </card>
</template>

<script>
import { parse } from "@cp/utils/dateUtils";
import widgetMixin from "@/widgets/mixin";
import { colors, transparentColors } from "@/lib/colorsAndIcons";

export default {
  mixins: [widgetMixin],
  computed: {
    props() {
      const { timelines, labels, yMin: minYValue, yMax: maxYValue } = this.data;
      const ySpan = maxYValue - minYValue;
      const yPad = ySpan * 0.2; // pad by 20%
      const yMin = Math.min(Math.round(parseFloat(minYValue) - yPad), 50);
      const yMax = Math.min(Math.round(parseFloat(maxYValue) + yPad), 100);

      const mapCoords = (y, x) => ({ x, y: parseFloat(y) });
      const filterCoords = ({ y }) => y;

      const datasets = [
        {
          fill: true,
          borderColor: colors.property,
          backgroundColor: transparentColors.property,
          pointBackgroundColor: colors.property,
          borderWidth: 2,
          lineTension: 0.3,
          order: 2,
          data: timelines.result_timeline.map(mapCoords).filter(filterCoords),
          label: this.parent.name,
        },
        {
          fill: true,
          borderColor: colors.region,
          pointBackgroundColor: colors.region,
          borderWidth: 2,
          lineTension: 0.3,
          order: 2,
          data: timelines.region_timeline.map(mapCoords).filter(filterCoords),
          label: this.parent.region_name,
        },
        {
          fill: false,
          borderColor: colors.client,
          pointBackgroundColor: colors.client,
          borderWidth: 2,
          lineTension: 0.3,
          order: 2,
          data: timelines.client_timeline.map(mapCoords).filter(filterCoords),
          label: "Company",
        },
        {
          fill: false,
          borderColor: colors.sbx,
          pointBackgroundColor: colors.sbx,
          borderWidth: 2,
          lineTension: 0.3,
          order: 2,
          data: timelines.sbx_timeline.map(mapCoords).filter(filterCoords),
          label: "SB Index",
        },
      ];

      const exactDayLabels = labels.map(x => {
        const [discard, start, end] = x.split(",");
        const startF = parse(start).format("D MMM 'YY");
        const endF = parse(end).format("D MMM 'YY");
        return `${startF} - ${endF}`;
      });

      return {
        id: "satisfaction-line",
        height: 400,
        showDetails: true,
        responsive: true,
        yMin,
        yMax,
        chartData: {
          labels: exactDayLabels,
          datasets,
          yMin,
          yMax,
        },
        options: {
          plugins: {
            legend: {
              display: true,
              labels: { usePointStyle: true, pointStyle: "line" },
              position: "bottom",
            },
          },
        },
      };
    },
  },
};
</script>
