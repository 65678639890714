<template>
  <cp-card titleBorderClass="mx-4" cardTitleClass="pb-1">
    <template #title>
      <v-icon large class="mr-2">mdi-check-decagram</v-icon>
      {{ title }}
    </template>
    <template
      #persistentActions
      v-if="module.showFutureDateToggle || module.showCurrentHistoryToggle"
    >
      <div class="d-flex align-center">
        <template v-if="module.showFutureDateToggle">
          <span class="mx-2">
            Next week
          </span>
          <v-switch v-model="showNextMonth" />
          <span class="mx-2">
            Next month
          </span>
        </template>

        <v-divider
          vertical
          v-if="module.showFutureDateToggle && module.showCurrentHistoryToggle"
          class="mx-4"
        />

        <template v-if="module.showCurrentHistoryToggle">
          <span class="mx-2">
            Current
          </span>
          <v-switch v-model="showHistorical" />
          <span class="mx-2">
            History
          </span>
        </template>
      </div>
    </template>

    <v-row v-if="loading">
      <v-col
        v-for="i in [1, 2, 3]"
        :key="`task-card-loading-${i}`"
        v-bind="colProps"
      >
        <v-skeleton-loader type="card" height="96px" />
      </v-col>
    </v-row>
    <v-sheet v-else-if="caughtUp">
      <div class="d-flex justify-center pa-10">
        <v-alert color="success" text>
          <v-icon x-large color="success" class="mr-3">mdi-check</v-icon>
          {{ module.caughtUpMessage }}
        </v-alert>
      </div>
    </v-sheet>
    <v-row v-else>
      <v-col
        v-for="(task, i) in tasks"
        :key="`task-card-${i}-${task.id}`"
        v-bind="colProps"
      >
        <TaskCard :task="task" />
      </v-col>
    </v-row>
  </cp-card>
</template>

<script>
import { mapState } from "vuex";

import { onCpEvent } from "@cp/mixins";

export default {
  name: "TaskList",
  mixins: [
    onCpEvent("tasks/itemFormModalClosed", function({ success } = {}) {
      if (success) this.fetch();
    }),
  ],
  props: {
    module: { type: Object, required: true },
    title: { type: String, default: "Task List" },
    colProps: {
      type: Object,
      default: () => ({
        cols: 12,
      }),
    },
  },
  computed: {
    ...mapState("tasks", {
      taskData: state => state,
    }),
    loading() {
      return this.taskData[this.module.keys.loading];
    },
    tasks() {
      return this.taskData[this.module.keys.stateKey];
    },
    caughtUp() {
      return !this.tasks.length;
    },
    showHistorical: {
      get() {
        return this.taskData[this.module.keys.showHistorical];
      },
      set(value) {
        return this.$store.dispatch(
          `tasks/${this.module.keys.setShowHistorical}`,
          value
        );
      },
    },
    showNextMonth: {
      get() {
        return this.taskData[this.module.keys.showNextMonth];
      },
      set(value) {
        return this.$store.dispatch(
          `tasks/${this.module.keys.setShowNextMonth}`,
          value
        );
      },
    },
  },
  methods: {
    fetch() {
      this.$store.dispatch(`tasks/${this.module.keys.fetch}`);
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>

<style scoped lang="scss">
:deep(.v-card__title) {
  display: flex;
  align-items: center;
  color: newColor(gray-6);
  .v-icon {
    color: newColor(gray-6);
  }
}

:deep(.v-input--switch) {
  &,
  .v-input__slot {
    margin: 0;
    padding: 0;
  }
  .v-messages {
    display: none;
  }
}
</style>
