<template>
  <v-menu v-if="data" bottom open-on-hover offset-y>
    <template #activator="{on}">
      <div class="bun-score-bar" v-on="on">
        <div
          v-for="(value, i) in data"
          :key="`bar-${i}`"
          class="bun-score-bar__value"
          :style="barStyle(i)"
        ></div>
      </div>
    </template>
    <v-list dense>
      <v-list-item v-for="(value, i) in data" :key="`bar-${i}`">
        <v-list-item-icon
          ><v-icon :color="colors[i]">mdi-circle</v-icon></v-list-item-icon
        >
        <v-list-item-title>{{ data[i] }} {{ labels[i] }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { get } from "@cp/utils/objectUtils";
export default {
  props: {
    values: { type: Object, required: true }
  },
  computed: {
    labels() {
      return this.values.labels;
    },
    data() {
      return get(this.values, ["datasets", 0, "data"]);
    },
    colors() {
      return get(this.values, ["datasets", 0, "backgroundColor"]);
    },
    total() {
      return this.data.reduce((r, i) => {
        r += i;
        return r;
      }, 0);
    }
  },
  methods: {
    barStyle(i) {
      return {
        width: Math.round((this.data[i] / this.total) * 10000) / 100 + "%",
        backgroundColor: this.colors[i]
      };
    }
  }
};
</script>

<style lang="scss" scoped>
$tooltip-background-color: #fff;
.bun-score-bar {
  width: 100%;
  height: 12px;
  display: flex;
  border-radius: 20px;
  overflow: hidden;

  .bun-score-bar__value {
    height: 100%;
    // border-right: 2px solid #ffffff;

    &:nth-child(1) {
      background-color: #e40000;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    &:nth-child(2) {
      background-color: #e86826;
    }

    &:nth-child(3) {
      background-color: #f0b323;
    }

    &:nth-child(4) {
      background-color: #6cc24a;
    }

    &:nth-child(5) {
      background-color: #008a09;
      border-right: none;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
}
</style>
