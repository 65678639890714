import { mergeMixins, ItemsTable } from "@cp/store/mixins";

const baseUrl = `${process.env.VUE_APP_HAYSTACK_API_PATH}/en/v1/residents`;

export const residentsTable = new ItemsTable({
  module: "residents",
  baseUrl,
  noun: "Residents",
  params: {
    sort_by: "residents.first_name",
    structures: {
      addon_options: ["summary", "include_intouch_stats"],
    },
  },
  tableOptions: {
    sort_by: "residents.first_name",
  },
  headers: [
    { text: "", value: "alert", sortable: false },
    { text: "Resident", value: "residents.first_name" },
    { text: "Location", value: "office_name", sortable: false },
    { text: "Last response", value: "last_response_date", sortable: false },
    { text: "Manager", value: "manager", sortable: false },
    { text: "Renewal", value: "renewal", sortable: false },
  ],
  initialMeta: {
    summary_object: {
      general: {
        resident_count: 0,
        lease_count: 0,
        location_count: 0,
        all_alerts: 0,
        unread_alerts: 0,
      },
      satisfaction: {
        overall: 0,
        satisfaction: 0,
        move_in: 0,
        maintenance: 0,
        followup: 0,
      },
      reponse: { response_rate: 0, sent: 0, responded: 0, missed: 0 },
      renewal: {
        likey_percentage: 0,
        likey: 0,
        unsure: 0,
        unlikely: 0,
      },
    },
  },
  filters: [
    "q_text",
    {
      type: "radio",
      key: "general",
      items: ["All", "Future", "Active", "Historical"],
      initialValue: "Active",
    },
    {
      type: "select",
      key: "survey_options",
      label: "Survey Status",
      multiple: false,
      items: [
        "Surveys Sent",
        "No Surveys Sent",
        "Surveys Taken",
        "Unlikely to Renew",
        "Likey to Renew",
        "Left Comments",
        "Had Maintenance Issues",
        "Has Recent Maintenance Issues",
      ],
      // initialValue: "Surveys Taken",
    },
    {
      label: "Surveys Sent",
      type: "sub_query",
      key: "resident_surveys",
      urlKey: "resident_surveys",
      multiple: true,
    },
    {
      label: "Region",
      type: "sub_query",
      key: "regions",
      multiple: true,
    },
    {
      label: "Locations",
      type: "sub_query",
      key: "properties",
      multiple: true,
    },
    {
      label: "Reporting Groups",
      type: "sub_query",
      key: "reporting_groups_by_locations",
      multiple: true,
    },
    {
      label: "Unit",
      type: "sub_query",
      key: "units",
      multiple: true,
    },
    {
      label: "Responsed To Question",
      type: "sub_query",
      key: "resident_survey_questions",
      urlKey: "resident_survey_questions",
      multiple: true,
    },
  ],
});

export default mergeMixins(residentsTable);
