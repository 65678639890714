<template>
  <span>{{ text }}</span>
</template>

<script>
import { get } from "@cp/utils/objectUtils";
import { coaxRoundPrct } from "@/lib/formats";

export default {
  props: ["item", "header"],
  computed: {
    text() {
      const text = get(this.item, this.header.value);
      return text ? coaxRoundPrct(text) : "";
    },
  },
};
</script>
