<template>
  <div>
    <date-picker
      v-bind="fields.last_day.attrs"
      @input="fields.last_day.input"
      outlined
      dense
    />

    <v-text-field
      v-bind="fields.personal_email.attrs"
      @input="fields.personal_email.input"
      outlined
      dense
    />

    <cp-unhandled-errors :formConf="formConf" />

    <cp-form-modal-success-or-buttons
      v-if="!success"
      :formConf="formConf"
      :modalConf="{ close: () => $emit('close') }"
      :buttons="buttons"
      :buttonConf="{
        confirm: {
          action: 'submit',
          text: 'Confirm',
          icon: 'mdi-account-check-outline',
          props: { color: 'primary' },
        },
        cantComplete: {
          action: 'cantComplete',
          text: 'Can\'t Complete',
          icon: 'mdi-cancel',
          props: { color: 'white' },
        },
      }"
      @submit="submit"
      @cantComplete="$emit('update:cant-complete', true)"
      noun="Notice"
      success-message="Employee Data Confirmed!"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

import { acknowledgeNoticeForm } from "@/store/modules/employee";

export default {
  mixins: [acknowledgeNoticeForm.mixin],
  props: {
    cantComplete: { type: Boolean, default: false },
  },
  computed: {
    ...mapState("employee", ["acknowledgeNotice", "acknowledgeNoticeTouched"]),
    buttons() {
      const hasTouched = this.acknowledgeNoticeTouched.length;
      return ["cantComplete", hasTouched ? "update" : "confirm"];
    },
  },
  methods: {
    async submit() {
      const config = { method: "POST", id: this.acknowledgeNotice.id };
      const response = await this.acknowledgeNoticeSubmit(config);
      if (this.success) {
        this.$emit("success");
      }
    },
  },
};
</script>
