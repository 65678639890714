<script>
import { Line } from "vue-chartjs/legacy";
import { Line as ChartJSLine } from "chart.js/auto";

import { deepMerge } from "@cp/utils/objectUtils";

export default {
  extends: Line,

  props: {
    chartData: { type: Object, default: () => {} },
    options: { type: Object, default: () => {} },
    showDetails: { type: Boolean, default: false },
    dateInterval: { type: String, default: null },
    yMin: { type: [String, Number], default: 0 },
    yMax: { type: [String, Number], default: 100 },
  },

  methods: {
    render() {
      const $this = this;

      const defaultOptions = {
        elements: {
          line: {
            spanGaps: false,
          },
          point: {
            spanGaps: false,
            radius: this.showDetails ? 2 : 0,
            hoverRadius: this.showDetails ? 2 : 0,
            borderWidth: this.showDetails ? 2 : 0,
          },
        },
        animation: {
          duration: 0,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            min: 0,
            max: this.chartData.labels ? this.chartData.labels.length - 1 : 1,
            display: this.showDetails,
            type: "linear",
            offset: true,
            ticks: {
              fontSize: 16,
              stepSize: 1,
              callback: function(value, index, ticks) {
                return $this.chartData.labels
                  ? $this.chartData.labels[index]
                  : [];
              },
            },
          },
          y: {
            min: this.yMin,
            max: this.yMax,
            type: "linear",
            display: this.showDetails,
            ticks: {
              min: this.yMin,
              max: this.yMax,
            },
          },
        },
        plugins: {
          tooltip: {
            enabled: this.showDetails,
            mode: "index",
            intersect: false,
            titleFontSize: 16,
            bodyFontSize: 16,
            usePointStyle: true,
            boxPadding: 4,
            itemSort: function(a, b) {
              return a.datasetIndex - b.datasetIndex;
            },
            callbacks: {
              title: function(tooltipItems) {
                return $this.chartData.labels
                  ? $this.chartData.labels[tooltipItems[0].dataIndex]
                  : "";
              },
            },
          },
          legend: {
            display: false,
          },
        },
      };

      const options = deepMerge({}, defaultOptions, this.options);
      this.renderChart(this.chartData, options);
    },
  },

  watch: {
    chartData() {
      this.render();
    },
  },

  mounted() {
    this.render();
    if (this.$listeners.click) {
      this.$refs.canvas.addEventListener("click", this.$listeners.click);
    }
  },
  beforeDestroy() {
    if (this.$listeners.click) {
      this.$refs.canvas.removeEventListener("click", this.$listeners.click);
    }
  },
};
</script>
