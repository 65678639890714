import { mapObject } from "@cp/utils/objectUtils";

const keys = {
  sbx: {
    cpIcon: "swift_bunny_logo",
    label: "SB Index",
    color: "#0070a8",
    transparentColor: "rgba(0,112,168,.1)",
  },
  client: {
    icon: "office-building",
    label: "Company",
    color: "#685478",
    transparentColor: "rgba(104,84,120,.1)",
  },
  district: {
    icon: "map-marker-radius",
    label: "District",
    color: "#f00",
    transparentColor: "rgba(255,0,0,.1)",
  },
  region: {
    icon: "map-marker-radius",
    label: "Region",
    color: "#009A44",
    transparentColor: "rgba(0,154,68,.1)",
  },
  property: {
    icon: "home-group",
    label: "Property",
    color: "#E67E22",
    transparentColor: "rgba(230,126,34,.1)",
  },
};

export default keys;

export const icons = mapObject(keys, ({ icon }) => icon);
export const labels = mapObject(keys, ({ label }) => label);
export const colors = mapObject(keys, ({ color }) => color);
export const transparentColors = mapObject(
  keys,
  ({ transparentColor }) => transparentColor
);
