<template>
  <div>
    <div class="pa-2 d-flex justify-end">
      <cp-filters-menu :module="table" hide-filters />
    </div>

    <v-data-table
      v-bind="tableProps"
      v-on="tableListeners"
      class="clickable-rows"
      @click:row="item => openModal({ id: item.id })"
      hide-default-footer
    >
      <template #header.user_task_progress_status_id="{header}">
        <cp-table-header-subqueryable
          :header="header"
          :table="table"
          submodule-ref-key="historicalTasksTaskStatuses"
          no-search-box
        />
      </template>

      <template #header.on_behalf_of="{header}">
        <cp-table-header-subqueryable
          :header="header"
          :table="table"
          submodule-ref-key="historicalTasksEmployees"
        />
      </template>

      <template #item.user_task_progress_status_id="{item}">
        <my-task-status-cell v-bind="{ item }" />
      </template>

      <template #item.on_behalf_of="{value}">
        <cp-profile-chip :profile="value" />
      </template>

      <template #item.completed_at="{value}">
        {{ toMDY(value) }}
      </template>
    </v-data-table>

    <div class="my-10 d-flex justify-center" v-if="showAllCaughtUp">
      <v-alert color="secondary" text class="mx-auto my-0">
        <v-icon color="secondary" xLarge class="mr-4">mdi-cancel</v-icon>
        Nothing assigned to you yet
      </v-alert>
    </div>

    <v-pagination
      class="mt-2 text-center"
      v-bind="paginationProps"
      v-on="paginationListeners"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import pluralize from "pluralize";

import { onCpEvent } from "@cp/mixins";
import {
  myHistoricalTasksTable,
  progress_statuses,
  compliance_statuses,
} from "@/store/modules/tasks";
import { taskStatusForm } from "@/store/modules/taskStatusForm";
import { parse, toMDY } from "@cp/utils/dateUtils";
import { isEmpty, filterObject } from "@cp/utils/objectUtils";
import { capitalize } from "@cp/utils/stringUtils";

export default {
  mixins: [
    myHistoricalTasksTable.mixin,
    taskStatusForm.modalMixin,
    onCpEvent(taskStatusForm.keys.modalClosedEvent, function({ success } = {}) {
      if (success) this.refreshTable();
    }),
  ],
  data() {
    return { progress_statuses, compliance_statuses };
  },
  computed: {
    ...mapState("haystackUser", ["hasToken"]),
    ...mapGetters("haystackUser", ["userId"]),
    title() {
      const service = this.$route.params.service;
      if (service) return `My ${capitalize(service)} Tasks`;
      return "My Tasks";
    },
    showAllCaughtUp() {
      const hasNoFilters = isEmpty(
        filterObject(this.tableFilterValues, x => x.length)
      );
      return hasNoFilters && this.noData;
    },
  },
  methods: {
    toMDY,
    isEmpty,
    daysLate(due_by_date) {
      const now = parse();
      const due = parse(due_by_date);
      const daysLate = now.diff(due, "days");
      return daysLate > 0 ? `${pluralize("day", daysLate, 1)} late` : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.search-field {
  max-width: 350px;
  margin: 10px auto;
}

.v-data-table::v-deep tbody tr > td:first-child {
  box-shadow: inset 8px 0 0 -4px $error;
}
</style>
