<template>
  <div v-if="loading" class="loading">
    <v-progress-circular :size="180" color="#0070a8" indeterminate>
      <cp-icon icon="swift_bunny_logo" color="#0070a8" xLarge />
    </v-progress-circular>
  </div>
  <cp-unhandled-errors
    v-else-if="!isEmpty(errors)"
    :formConf="{ unhandledErrors: errors }"
  />
  <div v-else>
    <widget
      v-for="widgetConf in widgets"
      :key="widgetConf.id"
      v-bind="widgetConf"
      ref="widgets"
    />
  </div>
</template>

<script>
import { Client } from "@cp/lib";
import { mapErrors } from "@cp/utils/jsonapiUtils";
import { isEmpty } from "@cp/utils/objectUtils";

import { pageMixin } from "@/widgets/mixins";

const client = new Client({
  baseUrl: `${process.env.VUE_APP_HAYSTACK_API_PATH}/en/v1`,
});

export default {
  mixins: [pageMixin],
  props: {
    url: { type: String, required: true },
  },
  data() {
    return {
      loading: true,
      errors: {},
      widgets: [],
    };
  },
  methods: { isEmpty },
  created() {
    const { url } = this.$props;
    try {
      client
        .get(url)
        .then(({ data }) => {
          this.widgets = data.widgets.filter(x => x);
        })
        .catch(response => {
          this.errors = mapErrors(response);
        })
        .finally(() => (this.loading = false));
    } catch (errors) {
      this.errors = mapErrors(errors);
    }
  },
};
</script>

<style scoped>
::v-deep .widget + .widget {
  margin-top: 15px;
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 400px;
}
</style>
