import { mergeMixins, ItemFormModal, Table } from "@cp/store/mixins";
import { requiredIf } from "@cp/utils/rules";
import { keyBy } from "@cp/utils/arrayUtils";

const scheduled = {
  id: 1,
  icon: "mdi-calendar-edit-outline",
  title: "Scheduled",
  datePrefix: "Scheduled for",
  dateAttr: "due_by_date",
  color: "#0070a8",
  chipProps: {
    color: "#0070a8",
    class: "white--text",
  },
  alertType: "success",
};

const pending = {
  id: 2,
  icon: "mdi-checkbox-blank-outline",
  title: "Pending",
  datePrefix: "Due on",
  dateAttr: "due_by_date",
  color: "#008A09",
  chipProps: {
    color: "#008A09",
    class: "white--text",
  },
  alertType: "warning",
};

const pastDue = {
  id: 3,
  icon: "mdi-alert-box-outline",
  title: "Past Due",
  datePrefix: "Was due",
  dateAttr: "due_by_date",
  color: "#FBAF57",
  chipProps: {
    color: "#FBAF57",
    class: "grey--text text--darken-3",
  },
};

export const completed = {
  id: 4,
  icon: "mdi-checkbox-outline",
  title: "Completed",
  datePrefix: "Updated on",
  dateAttr: "updated_at",
  color: "#008A09",
  chipProps: {
    color: "#008A09",
    class: "white--text",
  },
  alertType: "success",
};

export const progressStatuses = {
  scheduled,
  pending,
  pastDue,
  completed,
};

export const progressStatusesOrder = [scheduled, pending, pastDue, completed];

export const progressStatusesById = keyBy(
  Object.values(progressStatuses),
  "id"
);

export const progress_statuses = {
  1: scheduled,
  2: pending,
  3: pastDue,
  4: completed,
};

export const onTime = {
  id: 1,
  icon: "mdi-checkbox-outline",
  title: "On Time",
  datePrefix: "Completed on ",
  dateAttr: "completed_at",
  color: "#008A09",
  chipProps: {
    color: "#008A09",
    class: "white--text",
  },
  alertType: "success",
};

const late = {
  id: 2,
  icon: "mdi-timer-alert-outline",
  title: "Completed Late",
  datePrefix: "Was completed late on",
  dateAttr: "completed_at",
  color: "#FBAF57",
  chipProps: {
    color: "#FBAF57",
    class: "grey--text text--darken-3",
  },
};

export const cantComplete = {
  id: 3,
  icon: "mdi-checkbox-blank-off-outline",
  title: "Can't Complete",
  datePrefix: "Updated on",
  dateAttr: "updated_at",
  color: "#8B5DCB",
  chipProps: {
    color: "#8B5DCB",
    class: "white--text",
  },
  alertType: "warning",
  verb: "Marked",
};

export const abandoned = {
  id: 4,
  icon: "mdi-alert",
  title: "Abandoned",
  datePrefix: "Updated on",
  dateAttr: "updated_at",
  color: "#E40000",
  chipProps: {
    color: "#E40000",
    class: "white--text",
  },
  verb: "Marked",
};

const canceled = {
  id: 5,
  icon: "mdi-checkbox-blank-off-outline",
  title: "Canceled",
  datePrefix: "Canceled on",
  dateAttr: "updated_at",
  color: "#999",
  chipProps: {
    color: "#999",
    class: "black--text",
  },
};

export const complianceStatuses = {
  onTime,
  late,
  cantComplete,
  abandoned,
  canceled,
};

export const complianceStatusesOrder = [
  onTime,
  late,
  cantComplete,
  abandoned,
  canceled,
];

export const complianceStatusesById = keyBy(
  Object.values(complianceStatuses),
  "id"
);

export const compliance_statuses = {
  1: onTime,
  2: late,
  3: cantComplete,
  4: abandoned,
  5: canceled,
};

const defaultTableOptions = {
  sort_desc: false,
  sort_by: "",
  page: {
    number: 1,
    size: 15,
  },
  filters: [],
  q_text: "",
};

const haystack = `${process.env.VUE_APP_HAYSTACK_API_PATH}/en/v1`;

const clientConfig = {
  prioritizeTokenOverCookie: true,
};

// CRUFT?!?! is this being used?
export const taskItemFormModal = new ItemFormModal({
  module: "tasks",
  baseUrl: `${haystack}/user_tasks`,
  clientConfig,
  url: ":id",
  urlTemplate: true,
  initialValue: {
    completed_at: "",
    task: {},
    on_behalf_of: {},
    assignees: [],
    plans: [],
    due_by_date: "",
    abandon_date: "",
    escalate_date: "",
    user_task_progress_status_id: 1,
    task_archetype: { value: 2 },
  },
  fields: {
    note: {
      rules: [
        requiredIf(
          ({ user_task_compliance_status_id }) =>
            user_task_compliance_status_id === 3
        ),
      ],
    },
    user_task_progress_status_id: {
      initialValue: 1,
      alsoTouch: "note",
    },
  },
});

export const myCurrentTasksTable = new Table({
  module: "tasks",
  baseUrl: `${haystack}/tasks`,
  clientConfig,
  url: "/current",
  name: "currentTasks",
  tableOptions: { ...defaultTableOptions, sort_by: "due_by_date" },
  headers: [
    {
      text: "Status",
      value: "user_task_progress_status_id",
      sortable: false,
      filterable: true,
    },
    {
      text: "Task",
      value: "task_title",
      sortable: true,
    },
    {
      text: "For Employee",
      value: "on_behalf_of",
      sortable: false,
      filterable: true,
    },
    {
      text: "Due",
      value: "due_by_date",
      sortable: true,
    },
  ],
  filters: [
    "q_text",
    {
      label: "Progress Status",
      key: "task_progress_statuses",
      type: "sub_query",
    },
    { label: "For Employee", key: "employees", type: "sub_query" },
  ],
});

export const myHistoricalTasksTable = new Table({
  module: "tasks",
  baseUrl: `${haystack}/tasks`,
  clientConfig,
  url: "/historical",
  name: "historicalTasks",
  tableOptions: {
    ...defaultTableOptions,
    sort_by: "completed_at",
    sort_desc: true,
  },
  headers: [
    {
      text: "Status",
      value: "user_task_progress_status_id",
      sortable: false,
      filterable: true,
      cellClass: "text-no-wrap",
    },
    {
      text: "Task",
      value: "task_title",
    },
    {
      text: "For Employee",
      value: "on_behalf_of",
      sortable: false,
      filterable: true,
    },
    {
      text: "Completed Date",
      value: "completed_at",
    },
  ],
  filters: [
    "q_text",
    { label: "Status", key: "task_statuses", type: "sub_query" },
    { label: "For Employee", key: "employees", type: "sub_query" },
  ],
});

export default mergeMixins(
  taskItemFormModal,
  myCurrentTasksTable,
  myHistoricalTasksTable
);
